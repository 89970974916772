import React, { useContext, useEffect, PropsWithChildren } from 'react';
import { UserRole } from '../config/Config';
import { SessionContext } from '../App';
import { ISessionContext } from "../interfaces/ISessionContext";
import NotAllowed from '../components/NotAllowed';

interface RestrictedPageProps {
  allowedRoles: UserRole[];
}

const RestrictedPage: React.FC<RestrictedPageProps> = (props: PropsWithChildren<RestrictedPageProps>) => {
  /* Consume the context to decide if page can be viewed by logged in user */
  const session = useContext<ISessionContext>(SessionContext);

  const [allowed, setAllowed] = React.useState(false);

  useEffect(() => {
    if (session.roles) {
      for (let role of session.roles) {
        if (props.allowedRoles.includes(role)) {
          setAllowed(true);
        }
      }
    }
  }, [session.roles, props.allowedRoles]);

  return (
        <> {(allowed && props.children) || <NotAllowed /> } </>
    )
}

export default RestrictedPage;