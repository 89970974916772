import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { SessionContext } from '../App';
import { UserRole } from '../config/Config';
import { ISessionContext } from '../interfaces/ISessionContext';

export default function useForbiddenState(allowedRoles: UserRole[], requireExactRoleMatch: boolean, allowedInSelfHosting: boolean) {
  /* Consume the context to decide if page can be viewed by logged in user */
  const session = useContext<ISessionContext>(SessionContext);

  const [allowed, setAllowed] = useState(false);

  useEffect(() => {
    if (requireExactRoleMatch) {
      setAllowed(_.isEqual(_.sortBy(session.roles), _.sortBy(allowedRoles)) && (allowedInSelfHosting ? true : !session.selfHosted));
    } else {
      setAllowed(((session.roles?.filter(role => _.includes(allowedRoles, role))?.length || 0) > 0) && (allowedInSelfHosting ? true : !session.selfHosted));
    }
  }, [session.roles, allowedRoles, session.selfHosted, allowedInSelfHosting, requireExactRoleMatch]);

  return allowed;
}