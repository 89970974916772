import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import ILicenseConfiguration from '../../interfaces/ILicenseConfiguration';
import { VersionRange } from '../../utils/Version';

interface IMyAccountPageLicenseProps {
  currentConfiguration: ILicenseConfiguration;
}

export const MyAccountPageLicense: React.FC<IMyAccountPageLicenseProps> = (props) => {

  let { currentConfiguration } = props;

  return (
    <>
      <Grid container direction='column' style={{ "width": "100%" }}>
        <Grid item>
          <div>The license assigned to your account is:</div>
          <Typography variant='body1'>{currentConfiguration.name}</Typography>
        </Grid>
        <Grid item style={{ "marginTop": "10px" }}>
          <div>Your support ends:</div>
          <Typography variant='body1'>{currentConfiguration.endOfSupport || 'never'}</Typography>
        </Grid>
        <Grid item style={{ "marginTop": "10px" }}>
          <div>Your valid versions are:</div>
          <Typography variant='body1'>{currentConfiguration.validVersions.map(version => VersionRange.parse(version)?.toReadableString()).join(' ,')}</Typography>
        </Grid>
        {/* <Grid item style={{ "marginTop": "10px" }}>
          <div>You're eligible to use the following features:</div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Feature
            </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentConfiguration.features.map(feature =>
                <TableRow key={feature}>
                  <TableCell>
                    {feature}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid> */}
      </Grid>
    </>
  );
};

export default MyAccountPageLicense;