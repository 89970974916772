/* API calls */
export const USERS = '/backend/users';
export const COMPANIES = '/backend/companies';
export const COMPANYDETAILS = '/backend/companyDetails';
export const LICENSES = '/backend/licenses';
export const LICENSES_CUSTOM = '/backend/licenses/custom';
export const COMPANYLICENSES = '/backend/companyLicenses';
export const LSCONFIG = '/backend/lsconfig';
export const LICENSE_STATUS = '/backend/licenseStatus';
export const LICENSE_CONFIGURATIONS = '/backend/configurations';
export const FEATURE_SETS = '/backend/featureSets';

/* API statistic calls */
export const CHECKOUTS = '/backend/stats/checkouts';
export const CLIENTS = '/backend/stats/clients';
export const ACTIVE_LICENSES = '/backend/stats/activeLicenses';
export const ACTIVE_LICENSES_PER_VERSION = '/backend/stats/activeLicenses/versions';

/* Backend calls */
export const INFO = '/backend/info';
export const LOGIN = '/backend/login';
export const LOGOUT = '/backend/logout';
export const IS_READY = '/backend/isReady';
export const SETUP_LICENSE_SERVERS = '/backend/setup/licenseServers';
export const SETUP_ADMIN_USER = '/backend/setup/adminUser';
export const FINISH_SETUP = '/backend/setup/finish';
export const LSCONFIG_UPDATE = '/backend/lsConfigUpdate';
export const BUILD_ARTTEST_ZIP = '/backend/download';
export const GENERATE_LICENSE_FILE = '/backend/licenseFile';
export const DOWNLOAD_ACTIVATION = '/backend/activationFile';
export const DOWNLOAD_ARTTEST = '/backend/downloadArttest';
export const UPLOAD_ARTTEST = '/backend/uploadArttest';
export const RESOLVE_CONFLICT_USER = '/backend/resolveConflict/user';
export const RESOLVE_CONFLICT_COMPANY = '/backend/resolveConflict/company';
export const UPGRADE_COMPANY_LICENSE = '/backend/upgradeCompanyLicense';

/* Branding */
export const PRODUCT_NAME = 'Arttest';

/* Layout */
export const ADMINPANEL_DRAWER_WIDTH = 240;

/* Theme */
export const ERROR_COLOR = '#F44336';
export const SUCCESS_COLOR = '#4CAF50';

/* Color Palette */
export const PALETTE = [
  '#ff0029', '#377eb8', '#66a61e', '#984ea3', '#00d2d5', '#ff7f00', '#af8d00',
  '#7f80cd', '#b3e900', '#c42e60', '#a65628', '#f781bf', '#8dd3c7', '#bebada',
  '#fb8072', '#80b1d3', '#fdb462', '#fccde5', '#bc80bd', '#ffed6f', '#c4eaff',
  '#cf8c00', '#1b9e77', '#d95f02', '#e7298a', '#e6ab02', '#a6761d', '#0097ff',
  '#00d067', '#000000', '#252525', '#525252', '#737373', '#969696', '#bdbdbd',
  '#f43600', '#4ba93b', '#5779bb', '#927acc', '#97ee3f', '#bf3947', '#9f5b00',
  '#f48758', '#8caed6', '#f2b94f', '#eff26e', '#e43872', '#d9b100', '#9d7a00',
  '#698cff', '#d9d9d9', '#00d27e', '#d06800', '#009f82', '#c49200', '#cbe8ff',
  '#fecddf', '#c27eb6', '#8cd2ce', '#c4b8d9', '#f883b0', '#a49100', '#f48800',
  '#27d0df', '#a04a9b'
];

/* Roles */
export enum UserRole {
  USER = "ROLE_USER",
  COMPANY_ADMIN = "ROLE_COMPANY_ADMIN",
  ADMIN = "ROLE_ADMIN"
}