import { Button, Checkbox, createStyles, ExpansionPanelDetails, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, makeStyles, MenuItem, Paper, Radio, RadioGroup, Select, Theme, Typography, withStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import clsx from 'clsx';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import React, { useCallback, useContext, useEffect, useGlobal, useMemo, useState } from 'reactn';

import { SessionContext } from '../App';
import { BUILD_ARTTEST_ZIP, COMPANYLICENSES, GENERATE_LICENSE_FILE, UserRole } from '../config/Config';
import DownloadArttestDialog from '../dialogs/DownloadArttestDialog';
import useForceUpdate from '../hooks/ForceUpdateHook';
import useLicenseServers from '../hooks/LicenseServersHook';
import useLoginDialogRedirect from '../hooks/LoginDialogHook';
import ICompanyLicense from '../interfaces/ICompanyLicense';
import IMultiServerResponse from '../interfaces/IMultiServerResponse';
import { ISessionContext } from '../interfaces/ISessionContext';
import MarkdownStyle from '../theme/MarkdownStyle';
import { Utils } from '../utils/Utils';

const ExpansionPanel = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    '&$expanded': {
      '&:before': { opacity: 1 },
      margin: 'auto'
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topTextGrid: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    otherTextGrid: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      width: '100%'
    },
    download: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    radios: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    dropdown: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
    error: { color: red[500] },
    hidden: { display: 'none' },
    fade: { transitionProperty: 'opacity' },
    invisible: { opacity: 0 },
    changelogs: { marginTop: theme.spacing(2), },
    changelog: { paddingTop: 0 },
    markdown: MarkdownStyle
  })
);

interface ISelectedCompanyLicenses {
  [serverUrl: string]: number[];
};

enum ELicenseServerAccessStrategy {
  sequential = 'sequential',
  random = 'random'
}

interface IArttestVersion {
  version: string,
  buildIdentifier: string,
  changelog: string;
}

const Download: React.FC = () => {
  const classes = useStyles();

  /* Use the provided context to read the access token */
  const session = useContext<ISessionContext>(SessionContext);
  const [productName,] = useGlobal('productName');
  const [licenseServers,] = useLicenseServers();

  const [error, setError] = useState('');

  const [companyLicenses, setCompanyLicenses] = useState<IMultiServerResponse<ICompanyLicense>>();

  const [selectedCompanyLicenses, setSelectedCompanyLicenses] = useState<ISelectedCompanyLicenses>({});
  const [licenseServerAccessStrategy, setLicenseServerAccessStrategy] = useState<ELicenseServerAccessStrategy>(ELicenseServerAccessStrategy.sequential);

  const [arttestVersions, setArttestVersions] = useState<IArttestVersion[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<string>();
  const [selectedBuildIdentifier, setSelectedBuildIdentifier] = useState<string>();

  const [reloadVersionsDependency, reloadVersions] = useForceUpdate();

  const loginDialogRedirect = useLoginDialogRedirect();

  const [downloadArttestDialogVisible, setDownloadArttestDialogVisible] = useState(false);

  const uniqueArttestVersions = useMemo(() => Array.from(new Set(arttestVersions.map(v => v.version))), [arttestVersions]);
  const buildIdentifiersForSelectedVersion = useMemo(() => Array.from(new Set(arttestVersions.filter(v => v.version === selectedVersion).map(v => v.buildIdentifier))), [arttestVersions, selectedVersion]);

  useEffect(() => {
    if (!session.isLoggedIn) { return; }

    /* Fetch company licenses */
    fetch(COMPANYLICENSES + '?rowsPerPage=100', {
      method: 'GET',
      credentials: 'same-origin'
    })
      .then(loginDialogRedirect)
      .then(async response => {
        if (!response.ok) {
          throw Error('Error retrieving company licenses. ' + (await Utils.messageFromResponse(response)));
        } else {
          return response.json();
        }
      }).then((licenses: IMultiServerResponse<ICompanyLicense>) => {
        /* Set the local state */
        setCompanyLicenses(licenses);

        let tmp: ISelectedCompanyLicenses = {};

        Object.keys(licenses).filter(server => Utils.isOK(licenses[server].status)).forEach(server => {
          if (session.selfHosted || !session.roles?.includes(UserRole.ADMIN)) {
            let firstValidCompanyLicense = licenses[server].response?.results?.filter(l => !!l.licenseConfiguration)[0]?.id;
            tmp[server] = firstValidCompanyLicense === undefined ? [] : [firstValidCompanyLicense];
          } else {
            tmp[server] = [];
          }
        });

        setSelectedCompanyLicenses(tmp);
      }).catch(error => {
        setError(error.message);
      });
  }, [session.isLoggedIn, session.selfHosted, session.roles, loginDialogRedirect]);

  useEffect(() => {
    if (!session.isLoggedIn) { return; }

    fetch('/backend/downloads', {
      method: 'GET',
      credentials: 'same-origin'
    })
      .then(loginDialogRedirect)
      .then(async response => {
        if (!response.ok) { throw new Error(await (Utils.messageFromResponse(response))); }
        return response.json();
      }).then(data => {
        setArttestVersions(data || []);
        if (data && data.length) {
          setSelectedVersion(data[0].version);
        } else {
          setSelectedVersion(undefined);
        }
      }).catch(error => {
        setError(error.message);
      });
  }, [reloadVersionsDependency, session.isLoggedIn, loginDialogRedirect]);

  useEffect(() => {
    if (!selectedBuildIdentifier || !buildIdentifiersForSelectedVersion.includes(selectedBuildIdentifier)) {
      if (session?.company?.buildIdentifier && buildIdentifiersForSelectedVersion.includes(session.company.buildIdentifier)) {
        setSelectedBuildIdentifier(session.company.buildIdentifier);
      } else {
        setSelectedBuildIdentifier(buildIdentifiersForSelectedVersion[0]);
      }
    }
  }, [session, selectedVersion, selectedBuildIdentifier, buildIdentifiersForSelectedVersion]);

  function getDownloadLink(arch: 'win32' | 'win64' | 'license'): string {
    if (!selectedCompanyLicenses || !companyLicenses || !licenseServerAccessStrategy) {
      return '';
    }

    let companyLicenseSelected = (selectedCompanyLicenses && Object.values(selectedCompanyLicenses).filter(ids => ids.length !== 0).length > 0);

    return (arch === 'license' ? GENERATE_LICENSE_FILE : BUILD_ARTTEST_ZIP)
      + '?'
      + (selectedVersion ? ('&version=' + encodeURIComponent(selectedVersion)) : '')
      + (session.roles?.includes(UserRole.ADMIN) && selectedBuildIdentifier ? ('&buildIdentifier=' + encodeURIComponent(selectedBuildIdentifier)) : '')
      + ((arch !== 'license') ? '&arch=' + encodeURIComponent(arch) : '')
      + (companyLicenseSelected
        ? ('&companyLicenses=' + encodeURIComponent(JSON.stringify(selectedCompanyLicenses))
          + '&licenseServerAccessStrategy=' + encodeURIComponent(licenseServerAccessStrategy))
        : '');
  }

  const totalNumberOfValidCompanyLicenses = Object.keys(companyLicenses ?? {})
    .map(serverUrl => companyLicenses?.[serverUrl]?.response?.results?.filter(l => !!l.licenseConfiguration).length ?? 0)
    .reduce((a, b) => a + b, 0);

  function shoudButtonBeEnabled(isLicenseButton: boolean): boolean {
    let companyLicenseSelected = (selectedCompanyLicenses && Object.values(selectedCompanyLicenses).filter(ids => ids.length !== 0).length > 0);
    return isLicenseButton
      ? (companyLicenseSelected && !!licenseServerAccessStrategy) /* only enabled if a license is selected and the access strategy is valid */
      : (!!selectedVersion); /* only enabled if a version is selected */
  }

  function setCompanyLicenseSelected(serverUrl: string, companyLicense: ICompanyLicense, selected: boolean) {
    setSelectedCompanyLicenses(oldValue => {
      let newValue = Object.assign({}, oldValue);
      let indexOfCompanyId = newValue[serverUrl].indexOf(companyLicense.id);
      if (selected && indexOfCompanyId === -1) {
        newValue[serverUrl].push(companyLicense.id);
      } else if (!selected) {
        newValue[serverUrl] = _.filter(newValue[serverUrl], id => (id !== companyLicense.id));
      }
      return newValue;
    });
  }

  function getLicenseServerName(serverUrl: string) {
    return licenseServers?.filter(server => server.url === serverUrl)[0]?.name ?? serverUrl;
  }

  function hasDynamicLicenses(companyLicenses: IMultiServerResponse<ICompanyLicense> | undefined) {
    /* Check if you're a user without privileges. In that case only the associated company license shall get respected */
    if (!session.roles?.includes(UserRole.ADMIN) && !session.roles?.includes(UserRole.COMPANY_ADMIN)) {
      return session.companyLicense && session.companyLicense.type === "dynamic";
    } else {
      /* Here all company licenses on all license servers are checked for dynamic ones */
      return companyLicenses && Object.keys(companyLicenses).filter(server => (companyLicenses[server].response?.results.filter(companyLicense => companyLicense.type === "dynamic").length ?? 0) > 0).length > 0;
    }
  }

  let closeDownloadArttestDialog = useCallback((e: {}, reason: string) => {
    setDownloadArttestDialogVisible(false);
    if (reason === 'done') { reloadVersions(); }
  }, [reloadVersions]);

  return (
    <Grid container direction='row' alignItems='stretch' spacing={2}>
      <DownloadArttestDialog open={downloadArttestDialogVisible} onClose={closeDownloadArttestDialog} />
      <Grid item md={6} sm={12}>
        <Grid container component={Paper} direction='column' justify='flex-start' alignItems='stretch'>
          <Grid item container direction='row' className={classes.topTextGrid}>
            <Typography variant='h3' style={{ flexGrow: 1 }}>Download {productName}</Typography>
            {session.roles && session.roles.includes(UserRole.ADMIN) &&
              <Grid item><Button variant='outlined' onClick={() => setDownloadArttestDialogVisible(true)}><AddIcon /> Add Release</Button></Grid>}
          </Grid>
          <Grid item className={classes.otherTextGrid}>
            <Grid container direction='row' spacing={2} alignItems='flex-start' style={{ marginTop: '-1em' }}>
              <Grid item xs={12} md={8} component={FormControl} className={classes.dropdown}>
                <InputLabel></InputLabel>
                <Select
                  value={selectedVersion || ''}
                  disabled={!arttestVersions}
                  onChange={(event) => setSelectedVersion(event.target.value as string)}
                >
                  {uniqueArttestVersions.map(version =>
                    <MenuItem key={'version_' + version} value={version}>Version {version}</MenuItem>
                  )}
                  {(!arttestVersions || !arttestVersions.length) && <MenuItem key='version_empty' value=''>No binaries found</MenuItem>}
                </Select>
              </Grid>
              {session.roles?.includes(UserRole.ADMIN) && <Grid item xs={12} md={4} component={FormControl} className={clsx(classes.dropdown, classes.fade, ((buildIdentifiersForSelectedVersion?.length || 0) === 0) && classes.invisible)}>
                <InputLabel></InputLabel>
                <Select
                  value={selectedBuildIdentifier || ''}
                  onChange={(event) => setSelectedBuildIdentifier(event.target.value as string)}
                >
                  {buildIdentifiersForSelectedVersion?.map(buildIdentifier =>
                    <MenuItem key={'buildIdentifier_' + buildIdentifier} value={buildIdentifier}>{buildIdentifier}</MenuItem>
                  )}
                </Select>
              </Grid>}
            </Grid>
            {((session.selfHosted || !session.roles?.includes(UserRole.ADMIN)) && hasDynamicLicenses(companyLicenses)) && <Typography color='textSecondary' variant='caption'>
              Licensed to {session.company && session.company.name}
            </Typography>}
            <Typography variant='body1' className={clsx(!error && classes.hidden, classes.error)}>
              {error}
            </Typography>
          </Grid>
          <Grid item className={classes.otherTextGrid}>
            {(totalNumberOfValidCompanyLicenses > 1 || (session.roles?.includes(UserRole.COMPANY_ADMIN) && hasDynamicLicenses(companyLicenses)) || session.roles?.includes(UserRole.ADMIN)) && <div>
              <Typography variant='h6' style={{ paddingTop: '15px' }}>
                Pre-Configure {productName} Licenses
              </Typography>
              <Grid container direction='row'>
                {companyLicenses && Object.keys(companyLicenses).filter(serverUrl => (companyLicenses[serverUrl]?.response?.results?.length ?? 0) > 0).map(serverUrl =>
                  <FormControl key={"license_" + serverUrl} component='fieldset' className={classes.radios}>
                    <FormLabel component='legend'>{getLicenseServerName(serverUrl)}</FormLabel>
                    <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                      <Grid container direction='column'>
                        {companyLicenses?.[serverUrl]?.response?.results?.filter(e => e.type !== "node-locked").map(companyLicense =>
                          (companyLicense && companyLicense.licenseConfiguration) && <FormControlLabel
                            style={{ marginTop: '1em' }}
                            key={"companyLicense_" + serverUrl + "_" + companyLicense.id}
                            control={
                              <Checkbox
                                color='primary'
                                checked={_.includes(selectedCompanyLicenses[serverUrl], companyLicense.id)}
                                onChange={event => setCompanyLicenseSelected(serverUrl, companyLicense, event.target.checked)}
                              />
                            }
                            label={
                              <div>
                                <div>
                                  <Typography variant='body1' display="inline">{companyLicense.name || ''}</Typography>
                                  {companyLicense.licenseConfiguration?.name === companyLicense.name
                                    ? ''
                                    : (!companyLicense.name ? (companyLicense.licenseConfiguration?.name ?? '') : <Typography display='inline' color='textSecondary'>{` (${companyLicense.licenseConfiguration?.name ?? 'Not assigned correctly'})`}</Typography>)
                                  }
                                </div>
                                <Typography variant='body2' color='textSecondary'>
                                  {companyLicense.maxAmount} {companyLicense.maxAmount === 1 ? 'license' : 'licenses'}
                                  {(session.selfHosted || !session.roles?.includes(UserRole.ADMIN)) ? '' : ': ' + companyLicense.company.name}
                                </Typography>
                              </div>
                            }
                          />
                        ) ?? <FormControlLabel key={'companyLicenseNoLicense_' + serverUrl} control={<Checkbox disabled color='primary' />} label='No license available' />}
                      </Grid>
                    </div>
                  </FormControl>
                )}
              </Grid>
              {totalNumberOfValidCompanyLicenses > 1 && session.roles?.includes(UserRole.ADMIN) && <FormControl component='fieldset'>
                <FormLabel component='legend'>License Access Strategy</FormLabel>
                <RadioGroup name='licenseServerAccessStrategy' value={licenseServerAccessStrategy} onChange={(event) => { setLicenseServerAccessStrategy(ELicenseServerAccessStrategy[event.target.value as keyof typeof ELicenseServerAccessStrategy]); }} row>
                  <FormControlLabel value={ELicenseServerAccessStrategy.sequential} control={<Radio color='primary' />} label='Sequential' />
                  <FormControlLabel value={ELicenseServerAccessStrategy.random} control={<Radio color='primary' />} label='Random' />
                </RadioGroup>
              </FormControl>}
            </div>}
            <Grid container spacing={2} className={classes.download} direction='row' alignItems='center'>
              <Grid item>
                <Button variant='contained' color='primary' href={getDownloadLink('win32')} disabled={!shoudButtonBeEnabled(false)}>
                  Windows (32-Bit)
              </Button>
              </Grid>
              <Grid item style={{ marginRight: '2em' }}>
                <Button variant='contained' color='primary' href={getDownloadLink('win64')} disabled={!shoudButtonBeEnabled(false)}>
                  Windows (64-Bit)
                </Button>
              </Grid>
              <Grid item>
                <Button variant='contained' color='primary' href={getDownloadLink('license')} disabled={!shoudButtonBeEnabled(true)}>
                  <VpnKeyIcon style={{ 'marginRight': '0.3em' }} /> License File
            </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sm={12}>
        <Grid container component={Paper} direction='column' justify='flex-start' alignItems='stretch' style={{ height: '100%' }}>
          <Grid item className={classes.topTextGrid}>
            <Typography variant='h3'>
              Changelog
            </Typography>
          </Grid>
          <Grid item className={classes.changelogs}>
            {selectedBuildIdentifier && arttestVersions?.filter(v => (v.buildIdentifier === selectedBuildIdentifier)).map((version, i) =>
              <ExpansionPanel elevation={0} key={'changelog_' + version.version} defaultExpanded={i === 0}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography variant='h6'>Version {version.version}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.changelog}>
                  <div className={classes.markdown}>
                    <ReactMarkdown source={version.changelog} />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default Download;