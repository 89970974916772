import { Grid } from '@material-ui/core';
import React, { useContext, useGlobal } from 'reactn';

import { SessionContext } from '../App';
import Graph from '../components/Graph';
import RestrictedElement from '../components/RestrictedElement';
import RestrictedPage from '../components/RestrictedPage';
import { ACTIVE_LICENSES, ACTIVE_LICENSES_PER_VERSION, CHECKOUTS, CLIENTS, UserRole } from '../config/Config';

const LIMIT: { [key: string]: number; } = {
	year: 5,
	month: 12,
	day: 60
};

const General: React.FC = () => {
	const session = useContext(SessionContext);
	const [productName,] = useGlobal('productName');

	function compare(a: string, b: string): number {
		let partsA = a.split(/[\d]/);
		let partsB = b.split(/[\d]/);

		if (partsA[0] === partsB[0]) {
			if (partsA.length === 1 || partsB.length === 1) { return 0; }
			return compare(partsA.slice(1).join('-'), partsB.slice(1).join('-'));
		} else {
			return partsA[0] < partsB[0] ? -1 : 1;
		}
	}

	return (
		<RestrictedPage allowedRoles={[UserRole.COMPANY_ADMIN, UserRole.ADMIN]}>
			<Grid container direction='row' alignItems='stretch' spacing={2}>
				<Grid item md={6} sm={12}>
					<Graph
						title='Checkouts'
						description='Count of all license checkouts in the past months (also floating licenses).'
						chartJSType='line'
						views={[{ id: "day", displayName: "Days" }, { id: "month", displayName: "Months" }, { id: "year", displayName: "Years" }]}
						fetchUrl={viewId => `${CHECKOUTS}?groupBy=${viewId}&limit=${LIMIT[viewId]}`}
						transformToLabels={(data: any) => { return Object.keys(data).sort(compare).map(o => o); }}
						transformToData={(data: any) => { return Object.keys(data).sort(compare).map(o => data[o]); }}
					/>
				</Grid>
				<Grid item md={6} sm={12}>
					<Graph
						title='Clients'
						description={`Count of all clients using ${productName}`}
						chartJSType='line'
						views={[{ id: "day", displayName: "Days" }, { id: "month", displayName: "Months" }, { id: "year", displayName: "Years" }]}
						fetchUrl={viewId => `${CLIENTS}?groupBy=${viewId}&limit=${LIMIT[viewId]}`}
						transformToLabels={(data: any) => { return Object.keys(data).sort(compare).map(o => o); }}
						transformToData={(data: any) => { return Object.keys(data).sort(compare).map(o => data[o]); }}
					/>
				</Grid>
				<RestrictedElement allowedRoles={[UserRole.ADMIN]}>
					<Grid item md={6} sm={12}>
						<Graph
							title='Companies'
							description={`Shows the current distribution of companies using ${productName}`}
							chartJSType='doughnut'
							fetchUrl={ACTIVE_LICENSES}
							transformToLabels={(data: any) => { return Object.keys(data); }}
							transformToData={(data: any) => { return Object.values(data); }}
						/>
					</Grid>
					<Grid item md={6} sm={12}>
						<Graph
							title='Versions'
							description={`Shows the current distribution of used versions`}
							chartJSType='doughnut'
							fetchUrl={ACTIVE_LICENSES_PER_VERSION}
							transformToLabels={(data: any) => { return Object.keys(data); }}
							transformToData={(data: any) => { return Object.values(data); }}
						/>
					</Grid>
				</RestrictedElement>
			</Grid>
		</RestrictedPage>
	);
};

export default General;
