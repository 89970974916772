import React from 'react';
import { Typography } from "@material-ui/core";

const NotAllowed: React.FC = () => {
  return (
    <div>
      <Typography variant='h3'>
        Access Denied
      </Typography>
      <br/>
      <Typography variant='body1'>
        You're not allowed to view this page.
      </Typography>
    </div>
  )
}

export default NotAllowed;