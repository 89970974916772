import { useEffect, useState } from 'react';

import { COMPANIES } from '../config/Config';
import useForceUpdate from '../hooks/ForceUpdateHook';
import { IMergedCompany } from '../interfaces/ICompany';
import { Utils } from '../utils/Utils';


export function useMergedCompanies(setError?: (error?: string) => void): [IMergedCompany[], () => void] {
  const [companyList, setCompanyList] = useState<IMergedCompany[]>([]);
  const [update, forceUpdate] = useForceUpdate();

  useEffect(() => {
    fetch(COMPANIES + '?merged=true', {
      method: 'GET',
      credentials: 'same-origin'
    }).then(async response => {
      if (!response.ok) {
        throw Error('Error retrieving company data. ' + (await Utils.messageFromResponse(response)));
      } else {
        return response.json();
      }
    }).then(data => {
      let companyList = data.result as IMergedCompany[];
      setCompanyList(companyList);
    }).catch(error => {
      setCompanyList([]);
      setError?.(error.message);
    });
  }, [update, setError]);

  return [companyList, forceUpdate];
}