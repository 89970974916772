import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField, Typography } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React, { useContext, useEffect, useState } from 'react';

import { SessionContext } from '../App';
import ProgressButton from '../components/ProgressButton';
import { COMPANIES } from '../config/Config';
import { IMergedCompany } from '../interfaces/ICompany';

interface IAddOrEditCompanyProps extends DialogProps {
  company?: IMergedCompany,
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'done'): void;
  }['bivarianceHack'];
}

const AddOrEditCompanyDialog: React.FC<IAddOrEditCompanyProps> = (props) => {
  let { company, ...dialogProps } = props;

  const session = useContext(SessionContext);

  const [buildId, setBuildId] = useState('Default');
  const [companyName, setCompanyName] = useState('');
  const [companyEnabled, setCompanyEnabled] = useState(true);

  useEffect(() => {
    if (!dialogProps.open) { return; }
    setCompanyName(company?.name ?? '');
    setBuildId(company?.buildIdentifier ?? 'Default');
    setCompanyEnabled(company?.enabled ?? true);
  }, [company, dialogProps.open]);

  const [saveRunning, setSaveRunning] = useState(false);
  const [error, setError] = useState(false);

  const nameError = companyName.trim() === '';

  function save() {
    if (nameError) {
      return;
    }

    setError(false);

    /* Begin circular progress */
    setSaveRunning(true);

    /* Create new company in DB */
    fetch(COMPANIES, {
      method: !company ? 'POST' : 'PATCH',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body:
        'name=' + encodeURIComponent(companyName)
        + '&buildId=' + encodeURIComponent(buildId)
        + (!company ? '' : '&companyIds=' + encodeURIComponent(JSON.stringify(company.idMapping)))
        + '&enabled=' + encodeURIComponent(companyEnabled)
    }).then(response => {
      if (!response.ok) {
        setError(true);
      } else {
        props.onClose?.({}, 'done');
      }
    }).finally(() => setSaveRunning(false));
  }

  function keyPressed(event: any) {
    if (event.charCode === 13) {
      save();
    }
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{!company ? 'Add new company' : 'Edit ' + company.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Typography color='error'>{error}</Typography>}
          {!company ? 'Here you can add a new company.' : 'Here you can edit the company.'}
        </DialogContentText>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Company Name'
          type='name'
          fullWidth
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          error={nameError}
          onKeyPress={keyPressed}
        />
        <TextField
          margin='dense'
          id='build'
          label='Build ID'
          type='name'
          fullWidth
          value={buildId}
          onChange={(e) => setBuildId(e.target.value)}
          onKeyPress={keyPressed}
          error={buildId === ''}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={company?.name === session.company?.name}
              checked={companyEnabled}
              color='primary'
              onChange={event => setCompanyEnabled(event.target.checked as boolean)}
            />
          }
          label={'Enabled'}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose?.({}, 'escapeKeyDown')} color='primary' disabled={saveRunning}>Cancel</Button>
        <ProgressButton showProgress={saveRunning} disabled={nameError} variant='outlined' color='primary' onClick={save}>{company ? 'Save' : 'Add'}</ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrEditCompanyDialog;