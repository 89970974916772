import { Button, CircularProgress, Collapse, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Theme, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { DialogProps } from '@material-ui/core/Dialog';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { Utils } from '../utils/Utils';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    critical: {
      color: red[500],
      borderColor: red[500],
      '&:hover': {
        borderColor: red[500],
        backgroundColor: red[50]
      }
    },
    circluarProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-20px',
      marginLeft: '-20px'
    },
    fade: { transition: 'opacity 300ms ease-in-out' },
    invisible: { opacity: 0 },
  })
);

interface ConfirmationDialogProps extends DialogProps {
  triggerUpdate: (update: boolean) => void,
  critical: boolean,
  text: string,
  okText: string,
  action: () => Promise<any>,
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'cancel' | 'done'): void;
  }['bivarianceHack'];
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const classes = useStyles();

  const [running, setRunning] = useState(false);
  const [error, setError] = useState();

  const { triggerUpdate, critical, text, okText, action, ...dialogProps } = props;
  const { open, onClose } = props;

  useEffect(() => { if (open) { setError(undefined); } }, [open]);

  async function executeAction() {
    setError(undefined);

    /* Execute the action */
    setRunning(true);
    try {
      props.action().then(async response => {
        if (!response.ok) {
          throw new Error(await (Utils.messageFromResponse(response)));
        }
        onClose?.({}, "done");
        triggerUpdate(true);
      }).catch((error) => {
        setError(error.message ? error.message : error);
      });
    } finally {
      setRunning(false);
    }
  }

  return (
    <Dialog {...dialogProps} scroll={'paper'}>
      <DialogTitle>Are you sure?</DialogTitle>
      <CircularProgress className={clsx(classes.fade, classes.circluarProgress, !running && classes.invisible)} />
      <DialogContent className={clsx(classes.fade, running && classes.invisible)}>
        <DialogContentText>
          {props.text}
        </DialogContentText>
        <Collapse in={!!error}><Typography color='error'>{error}{'\u00a0'}</Typography></Collapse>
        <DialogActions className={clsx(classes.fade, running && classes.invisible)}>
          <Button onClick={() => { if (props.onClose) { props.onClose({}, 'cancel'); } }}>Cancel</Button>
          <Button variant={props.critical ? 'outlined' : 'text'} onClick={executeAction} className={clsx(props.critical && classes.critical)}>
            {props.okText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;