import { Theme, Tooltip, withStyles } from '@material-ui/core';

/* Taken from https://material-ui.com/components/tooltips/ */
const HTMLTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
  },
  tooltipArrow: {
    color: '#f5f5f9',
    border: '1px solid #dadde9'
  }
}))(Tooltip);

export default HTMLTooltip;
