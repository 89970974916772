import { ICompanyShort } from './ICompany';
import { ICompanyLicenseShort } from './ICompanyLicense';
import { IUserShort } from './IUser';

export default interface ILicense {
  id: number,
  licenseType: ILicenseType,
  licenseId: string,
  productVersion: string,
  isRevoked: boolean,
  creation: number,
  expiration: number,
  sessionStart: number,
  hardwareId: string,
  user: IUserShort,
  company: ICompanyShort,
  companyLicense: ICompanyLicenseShort;
}

export interface ILicenseShort {
  id: number,
  licenseType: ILicenseType,
  licenseId: string;
}

export enum ILicenseType {
  FLOAT = "floating",
  BORROW = "borrowed",
  NODELOCKED = "node-locked"
}