import { Divider, ListItemSecondaryAction, Tooltip } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import OkIcon from '@material-ui/icons/CheckCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import HomeIcon from '@material-ui/icons/Home';
import LockIcon from '@material-ui/icons/LockRounded';
import LicenseIcon from '@material-ui/icons/Receipt';
import SettingsIcon from '@material-ui/icons/Settings';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WarningIcon from '@material-ui/icons/WarningRounded';
import React, { ReactElement, ReactFragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import { SessionContext } from '../App';
import { ADMINPANEL_DRAWER_WIDTH, UserRole } from '../config/Config';
import useLicenseServers from '../hooks/LicenseServersHook';
import useLicenseStatus from '../hooks/LicenseStatusHook';
import ILicenseServer from '../interfaces/ILicenseServer';
import { ISessionContext } from '../interfaces/ISessionContext';
import { ReactComponent as Logo } from '../resources/Arttest-Logo-Single.svg';
import HTMLTooltip from './HTMLTooltip';
import RestrictedElement from './RestrictedElement';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        widht: ADMINPANEL_DRAWER_WIDTH,
        flexShrink: 0
      }
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    },
    drawerPaper: {
      width: ADMINPANEL_DRAWER_WIDTH,
    },
    drawerListItem: {
      minWidth: '35px'
    }
  })
);

export interface IAdminPanelDrawerProps {
  handleDrawerToggle: () => void;
}

const AdminPanelDrawer: React.FC<IAdminPanelDrawerProps> = (props) => {
  const classes = useStyles();

  /* Use the provided context to read the access token */
  const session = useContext<ISessionContext>(SessionContext);

  const [licenseServers,] = useLicenseServers();
  const [licenseStatus,] = useLicenseStatus();

  let hasInvalidLicenses = licenseStatus && Object.values(licenseStatus).map(s => s.response).filter(status => status && status.licenseValid === false).length > 0;
  let hasLicenseIncompatibilities = licenseStatus && Object.values(licenseStatus).map(s => s.response).filter(status => status && status.licenseCompatibilityStatus === false).length > 0;

  function getServerInfo(server: ILicenseServer): { tooltip?: ReactFragment, icon: ReactElement; } {
    if (!server.available) {
      return { tooltip: 'Connection to this server cannot be established', icon: <CloudOffIcon style={{ color: red[500] }} /> };
    } else if (server.status >= 400 && server.status < 500) {
      return { tooltip: 'Authentication on this server failed', icon: <LockIcon style={{ color: red[500] }} /> };
    } else {
      let serverStatus = licenseStatus?.[server.url]?.response;
      if (!!serverStatus && (!serverStatus.licenseValid || !serverStatus.licenseCompatibilityStatus)) {
        return { tooltip: `This server's license is not configured correctly`, icon: <WarningIcon style={{ color: orange[500] }} /> };
      }
      return { icon: <OkIcon style={{ color: green[500] }} /> };
    }
  }

  const drawer = (
    <>
      <Divider />
      <List>
        <RestrictedElement allowedRoles={[UserRole.COMPANY_ADMIN, UserRole.ADMIN]} allowedInSelfHosting>
          <ListItem button key='General' component={Link} to='/'>
            <ListItemIcon className={classes.drawerListItem}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary='General' />
          </ListItem>
        </RestrictedElement>
        <RestrictedElement allowedRoles={[UserRole.COMPANY_ADMIN, UserRole.ADMIN]} allowedInSelfHosting>
          <ListItem button key='CompanyLicenses' component={Link} to='/companyLicenses'>
            <ListItemIcon className={classes.drawerListItem}>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText primary='Company Licenses' />
            {hasLicenseIncompatibilities && <HTMLTooltip title='One or more company licenses are not compatible with your license'>
              <ListItemSecondaryAction><WarningIcon style={{ color: orange[500] }} /></ListItemSecondaryAction>
            </HTMLTooltip>}
          </ListItem>
        </RestrictedElement>
        <RestrictedElement allowedRoles={[UserRole.COMPANY_ADMIN, UserRole.ADMIN]} allowedInSelfHosting>
          <ListItem button key='ActiveLicenses' component={Link} to='/licenses'>
            <ListItemIcon className={classes.drawerListItem}>
              <LicenseIcon />
            </ListItemIcon>
            <ListItemText primary='Active Licenses' />
          </ListItem>
        </RestrictedElement>
        <ListItem button key='Download' component={Link} to='/download'>
          <ListItemIcon className={classes.drawerListItem}>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText primary='Download' />
        </ListItem>
      </List>
      <Divider />
      <RestrictedElement allowedRoles={[UserRole.ADMIN, UserRole.COMPANY_ADMIN]} allowedInSelfHosting>
        <List>
          <ListItem button key='Administration' component={Link} to='/admin'>
            <ListItemIcon className={classes.drawerListItem}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary='Administration' />
            {hasInvalidLicenses && <HTMLTooltip title="One or more license servers don't have a valid license configured">
              <ListItemSecondaryAction><WarningIcon style={{ color: orange[500] }} /></ListItemSecondaryAction>
            </HTMLTooltip>}
          </ListItem>
        </List>
        <Divider />
        <div style={{ flexGrow: 1 }} />
        <Divider style={{ marginTop: '-1px' }} />
        <List style={{ overflowX: 'auto', flexShrink: 0 }}>
          {licenseServers?.map(server => {
            let serverInfo = getServerInfo(server);
            let listItem = <ListItem key={server.url} style={{ width: 'max-content' }}>
              <ListItemIcon className={classes.drawerListItem}>{serverInfo.icon}</ListItemIcon>
              <ListItemText style={{ textOverflow: 'ellipsis' }} primary={server.name} secondary={server.url} />
            </ListItem>;
            return serverInfo.tooltip ? <Tooltip key={server.url} title={serverInfo.tooltip} placement='top' arrow>{listItem}</Tooltip> : listItem;
          })}
        </List>
      </RestrictedElement>
    </>
  );

  return (
    <nav className={classes.root}>
      <Hidden smUp>
        <Drawer
          variant='temporary'
          anchor='left'
          open={session.drawerOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <div className={classes.drawerHeader}>
            <Logo />
            <IconButton onClick={props.handleDrawerToggle}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          variant='permanent'
          classes={{
            paper: classes.drawerPaper
          }}
          open
        >
          <div className={classes.drawerHeader}>
            <Logo />
          </div>
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default AdminPanelDrawer;