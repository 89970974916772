import { CircularProgress, Collapse, createStyles, Dialog, DialogContent, DialogContentText, DialogProps, DialogTitle, IconButton, Link, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import React, { useState } from 'react';

import { RESOLVE_CONFLICT_USER } from '../config/Config';
import { IMergedObject } from '../interfaces/IMergedMultiServerResponse';
import IUser from '../interfaces/IUser';

const useStyles = makeStyles(() =>
  createStyles({
    circluarProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-20px',
      marginLeft: '-20px'
    },
    fade: {
      transition: 'opacity 300ms ease-in-out'
    },
    invisible: {
      opacity: 0
    },
    error: {
      color: 'red'
    },
    hidden: {
      display: 'none'
    }
  })
);

interface ResolveUserConflictDialogProps extends DialogProps {
  type?: string,
  conflictedData: IMergedObject<IUser> | undefined,
  ids: IMergedObject<number> | undefined,
  triggerUpdate: () => void,
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'done'): void;
  }['bivarianceHack'];
}

const ResolveUserConflictDialog: React.FC<ResolveUserConflictDialogProps> = (props) => {
  const classes = useStyles();

  const [saveRunning, setSaveRunning] = useState(false);
  const [error, setError] = useState('');

  let { type, conflictedData, ids, triggerUpdate, ...dialogProps } = props;
  let { onClose } = props;

  function transferToOtherLicenseServers(serverUrl: string, user: IUser | undefined) {
    setError('');

    if (!user || saveRunning) {
      return;
    }

    setSaveRunning(true);

    try {
      fetch(RESOLVE_CONFLICT_USER, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: 'serverUrl=' + encodeURIComponent(serverUrl)
          + '&id=' + encodeURIComponent(user.id)
      }).then(async response => {
        if (response.ok) {
          return response.json();
        } else {
          let message = ""; try { message = ": " + (await response.json()).message; } catch { }
          throw new Error('Error resolving conflicts (' + response.status + ')' + message);
        }
      }).then(data => {
        if (data.success) {
          triggerUpdate();
          if (onClose) { onClose({}, 'done'); };
        } else {
          throw new Error("Error: " + data.message);
        }
      }).catch(reason => {
        setError((reason ? (reason.message ? reason.message : reason) : ''));
      });
    } finally {
      setSaveRunning(false);
    }

    triggerUpdate();
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Resolve Conflicts</DialogTitle>
      <CircularProgress className={clsx(classes.fade, classes.circluarProgress, !saveRunning && classes.invisible)} />
      <DialogContent className={clsx(classes.fade, saveRunning && classes.invisible)}>
        <Collapse in={!!error}>
          <Typography variant='body1' className={classes.error}>{error}{'\u00a0'}</Typography>
        </Collapse>
        <DialogContentText>
          Please select the entry which you would like to transfer to all license servers
        </DialogContentText>
      </DialogContent >
      <Table className={clsx(classes.fade, saveRunning && classes.invisible)}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '3em' }}></TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>E-Mail</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {conflictedData && Object.keys(conflictedData).map((server, i) => <TableRow hover key={'User_' + i}>
            <TableCell><IconButton size="small" onClick={() => { transferToOtherLicenseServers(server, conflictedData ? conflictedData[server] : undefined); }} ><ArrowForward /></IconButton></TableCell>
            <TableCell align='left'>{conflictedData?.[server]?.company?.name}</TableCell>
            <TableCell align='left'>{conflictedData?.[server]?.username}</TableCell>
            <TableCell align='left'>{conflictedData?.[server]?.firstName}</TableCell>
            <TableCell align='left'>{conflictedData?.[server]?.lastName}</TableCell>
            <TableCell align='left'><Link href={"mailto:" + conflictedData?.[server].email}>{conflictedData?.[server].email}</Link></TableCell>
          </TableRow>
          )}
        </TableBody>
      </Table>
    </Dialog >
  );
};

export default ResolveUserConflictDialog;