import { CircularProgress, Collapse, createStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { __RouterContext } from 'react-router';

import { SessionContext } from '../App';
import { LOGIN, UserRole } from '../config/Config';
import useLicenseServers from '../hooks/LicenseServersHook';
import IBackendLoginResponse from '../interfaces/IBackendLoginResponse';
import { ISessionContext } from '../interfaces/ISessionContext';
import { Utils } from '../utils/Utils';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circluarProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-20px',
      marginLeft: '-20px'
    },
    fade: { transition: 'opacity 300ms ease-in-out' },
    invisible: { opacity: 0 },
    "@keyframes shake": {
      "10%, 90%": {
        transform: "translate3d(-2px, 0, 0)"
      },
      "20%, 80%": {
        transform: "translate3d(4px, 0, 0)"
      },
      "30%, 50%, 70%": {
        transform: "translate3d(-8px, 0, 0)"
      },
      "40%, 60%": {
        transform: "translate3d(8px, 0, 0)"
      }
    },
    shake: {
      '& [role="dialog"]': {
        animation: '$shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
        transform: 'translate3d(0, 0, 0)',
        backfaceVisibility: 'hidden',
        perspective: '1000px'
      }
    }
  })
);

interface LoginDialogProps extends DialogProps {
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'login'): void;
  }['bivarianceHack'];
}
const LoginDialog: React.FC<LoginDialogProps> = (props) => {
  const classes = useStyles();

  /* Get the different contexts */
  const session = useContext<ISessionContext>(SessionContext);

  const [, setLicenseServers] = useLicenseServers();

  const routerContext = useContext(__RouterContext);

  /* Local state */
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [loginRunning, setLoginRunning] = useState(false);
  const [loginError, setLoginError] = useState('');

  let { onClose } = props;
  let { setLoggedIn, setUserIds, setRoles, setCompany, setCompanyLicense, setSelfHosted } = session;
  let setSessionUsername = session.setUsername;
  let setSessionFirstname = session.setFirstname;
  let setSessionLastname = session.setLastname;
  let setSessionEmail = session.setEmail;

  let handleLogin = useCallback(async (username?: string, password?: string) => {
    let autoLogin = (!username && !password);

    if (!autoLogin && (!username || !password)) {
      return;
    }

    /* Set the state to display the circular process */
    setLoginRunning(true);
    try {
      setLoginError('');

      let loginResponse = await fetch(LOGIN, {
        method: 'POST',
        credentials: 'same-origin',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: 'username=' + encodeURIComponent(username || '') + '&password=' + encodeURIComponent(password || '')
      });

      if (!loginResponse.ok) {
        setLoginRunning(false);
        if (!autoLogin) {
          try {
            let loginJsonResponse = await loginResponse.json();
            if (!loginJsonResponse.message) { throw new Error(); }
            setLoginError(loginJsonResponse.message);
          } catch {
            /* Handle this different if this was the auto login attempt */
            if (loginResponse.status === 400) {
              setLoginError('Invalid credentials');
            } else if (loginResponse.status === 503) {
              setLoginError('License server not available');
            } else {
              setLoginError(await (Utils.messageFromResponse(loginResponse)));
            }
          }
        }
        return;
      }

      let loginResponseJson = (await loginResponse.json()) as IBackendLoginResponse;

      if (!loginResponseJson.userDetails) {
        setLoginError('User does not exist');
        return;
      }

      setLoggedIn(true);
      setLicenseServers(loginResponseJson.licenseServerList);
      setUserIds(loginResponseJson.userDetails.userIds);
      setSessionUsername(loginResponseJson.tokenDetails.username);
      setSessionFirstname(loginResponseJson.userDetails.firstname);
      setSessionLastname(loginResponseJson.userDetails.lastname);
      setSessionEmail(loginResponseJson.userDetails.email);
      setRoles(loginResponseJson.tokenDetails.roles.map(role => UserRole[role as keyof typeof UserRole]));
      setCompany(loginResponseJson.userDetails.company);
      setCompanyLicense(loginResponseJson.userDetails.companyLicense);
      setSelfHosted(loginResponseJson.selfHosted);

      if (loginResponseJson.tokenDetails.redirectTo) {
        routerContext.history.push(loginResponseJson.tokenDetails.redirectTo);
      }

    } catch (e) {
      setLoginError(e.message);
    } finally {
      setLoginRunning(false);
    }
    /* Finally close the dialog */
    if (onClose) { onClose({}, 'login'); }
  }, [routerContext.history, onClose, setLoggedIn, setLicenseServers, setRoles, setCompany, setCompanyLicense, setSelfHosted, setUserIds, setSessionUsername, setSessionFirstname, setSessionLastname, setSessionEmail]);

  useEffect(() => { handleLogin(); }, [handleLogin]);

  function handleKeyPressed(event: any) {
    if (event.charCode === 13) {
      handleLogin(username, password);
    }
  }

  return (
    <Dialog {...props} className={clsx(!!loginError && classes.shake)} disableBackdropClick={true} disableEscapeKeyDown={true}>
      <DialogTitle>Login</DialogTitle>
      <CircularProgress className={clsx(classes.fade, classes.circluarProgress, !loginRunning && classes.invisible)} />
      <DialogContent className={clsx(classes.fade, loginRunning && classes.invisible)}>
        <Collapse in={!loginError}><DialogContentText>Please login</DialogContentText></Collapse>
        <Collapse in={!!loginError}><DialogContentText color='error'>{loginError}{'\u00a0'}</DialogContentText></Collapse>
        <form>
          <TextField
            autoFocus
            margin='dense'
            id='usernamefield'
            label='Username'
            autoComplete='username'
            type='text'
            fullWidth
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            onKeyPress={handleKeyPressed}
          />
          <TextField
            margin='dense'
            id='passwordfield'
            label='Password'
            type='password'
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={handleKeyPressed}
          /></form>
      </DialogContent>
      <DialogActions className={clsx(classes.fade, loginRunning && classes.invisible)}>
        <Button variant='outlined' color='primary' disabled={loginRunning || !username || !password} onClick={() => handleLogin(username, password)}>
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginDialog;