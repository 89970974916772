import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import useRestrictedMode from '../../hooks/RestrictedModeHook';
import SettingsPageLicenseServers from './SettingsPageLicenseServers';
import SettingsPageRequestLicense from './SettingsPageRequestLicense';

const useStyles = makeStyles(theme => createStyles({
  tabsWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '450px',
    padding: 0
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '200px'
  },
  tabContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  tabPanel: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    padding: theme.spacing(2)
  },
  hidden: {
    display: 'none'
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const classes = useStyles();

  const { children, value, index } = props;

  return (
    <Grid container direction='column' justify='flex-start' alignItems='flex-start' className={clsx(classes.tabPanel, value !== index && classes.hidden)} >
      {children}
    </Grid>
  );
}

const SettingsDialog: React.FC<DialogProps> = (props) => {
  const classes = useStyles();

  const restrictedMode = useRestrictedMode();

  const [selectedTab, setSelectedTab] = useState(restrictedMode ? -1 : 0);

  useEffect(() => {
    if (props.open) {
      setSelectedTab(restrictedMode ? -1 : 0);
    }
  }, [props.open, restrictedMode]);

  return (
    <Dialog {...props} fullWidth maxWidth='sm'>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent className={classes.tabsWrapper}>
        <Tabs
          orientation="vertical"
          value={selectedTab}
          onChange={(e, tab) => setSelectedTab(tab)}
          className={classes.tabs}
        >
          <Tab style={{ display: 'none' }} label="Restricted" value={-1}></Tab>
          <Tab label="Setup License Servers" value={0} />
          <Tab label="Request Server Configuration" value={1} />
        </Tabs>
        <div className={classes.tabContent}>
          <TabPanel value={selectedTab} index={-1}><Typography color="error">Connection to one or more servers is unavailable.</Typography></TabPanel>
          <TabPanel value={selectedTab} index={0}><SettingsPageLicenseServers /></TabPanel>
          <TabPanel value={selectedTab} index={1}><SettingsPageRequestLicense /></TabPanel>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialog;