export class Version {
    major: number;
    minor: number;
    micro: number;

    constructor(major: number, minor: number, micro: number) { this.major = major; this.minor = minor; this.micro = micro; }

    toString(): string { return this.major + "." + this.minor + "." + this.micro; }

    compareTo(other: Version): number {
        let result = this.major - other.major;
        if (result !== 0) { return result; }
        result = this.minor - other.minor;
        if (result !== 0) { return result; }
        return this.micro - other.micro;
    }

    static parse(versionString: string): Version | null {
        let matchResult = versionString.match(/^(?<major>\d+)(?:\.(?<minor>\d+)(?:\.(?<micro>\d+))?)?$/);
        if (!matchResult || !matchResult.groups) { return null; }
        return new Version(
            parseInt(matchResult.groups.major),
            parseInt(matchResult.groups.minor) || 0,
            parseInt(matchResult.groups.micro) || 0
        );
    }
}

export class VersionRange {
    minVersion: Version | null;
    maxVersion: Version | null;
    includeMinVersion: boolean;
    includeMaxVersion: boolean;

    constructor(minVersion: Version | null, maxVersion: Version | null, includeMinVersion: boolean, includeMaxVersion: boolean) {
        this.minVersion = minVersion;
        this.maxVersion = maxVersion;
        this.includeMinVersion = includeMinVersion;
        this.includeMaxVersion = includeMaxVersion;
    }

    toReadableString(): string {
        if ( (this.minVersion == null) && (this.maxVersion == null) ) {
            return "all versions";
        } else if ( (this.minVersion != null) && (this.maxVersion != null) && (this.minVersion.compareTo( this.maxVersion ) >= (this.includeMinVersion || this.includeMaxVersion ? 1 : 0)) ) {
            return "no versions";
        } else if ( this.maxVersion == null ) {
            return "from " + (this.includeMinVersion ? "" : ">") + (this.minVersion as Version).toString();
        } else if ( this.minVersion == null ) {
            return "up to " + (this.includeMaxVersion ? "" : "<") + this.maxVersion.toString();
        } else if ( this.minVersion.compareTo( this.maxVersion ) === 0 ) {
            return this.minVersion.toString();
        } else {
            return "from " + (this.includeMinVersion ? "" : ">") + this.minVersion.toString()
                    + " up to " + (this.includeMaxVersion ? "" : "<") + this.maxVersion.toString();
        }
    }

    static parse(versionRangeString: string): VersionRange | null {
        let matchResult = versionRangeString.match(/^(?<range>(?<includeMin>\[|\()(?<min>(?<min_major>\d+)(?:\.(?<min_minor>\d+)(?:\.(?<min_micro>\d+))?)?)?,(?<max>(?<max_major>\d+)(?:\.(?<max_minor>\d+)(?:\.(?<max_micro>\d+))?)?)?(?<includeMax>\]|\)))|(?<fixed>(?<fixed_major>\d+)(?:\.(?<fixed_minor>\d+)(?:\.(?<fixed_micro>\d+))?)?)$/);
        if (!matchResult || !matchResult.groups) { return null; }
    
        if (!!matchResult.groups.range) {
            return new VersionRange(
                !matchResult.groups.min ? null : new Version(
                    parseInt(matchResult.groups.min_major),
                    parseInt(matchResult.groups.min_minor) || 0,
                    parseInt(matchResult.groups.min_micro) || 0
                ),
                !matchResult.groups.max ? null : new Version(
                    parseInt(matchResult.groups.max_major),
                    parseInt(matchResult.groups.max_minor) || 0,
                    parseInt(matchResult.groups.max_micro) || 0
                ),
                matchResult.groups.includeMin === '[',
                matchResult.groups.includeMax === ']'
            );
        } else {
            let version = new Version(
                parseInt(matchResult.groups.fixed_major),
                parseInt(matchResult.groups.fixed_minor) || 0,
                parseInt(matchResult.groups.fixed_micro) || 0
            );
    
            return new VersionRange(version, version, true, true);
        }
    }
}
