import { useEffect, useState } from 'react';

import { Utils } from '../utils/Utils';
import useLicenseServers from './LicenseServersHook';


export default function useRestrictedMode(): boolean {
  const [restrictedMode, setRestrictedMode] = useState(true);
  const [licenseServers,] = useLicenseServers();

  useEffect(() => {
    if (!licenseServers) {
      setRestrictedMode(true);
      return;
    }

    for (let server of licenseServers) {
      if (!server.available || !Utils.isOK(server.status)) {
        setRestrictedMode(true);
        return;
      }
    }

    setRestrictedMode(false);
  }, [licenseServers]);

  return restrictedMode;
}