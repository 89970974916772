import { Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'reactn';

import { COMPANYLICENSES } from '../../config/Config';
import useLoginDialogRedirect from '../../hooks/LoginDialogHook';
import ICompanyLicense from '../../interfaces/ICompanyLicense';
import ILicenseConfiguration from '../../interfaces/ILicenseConfiguration';
import { Utils } from '../../utils/Utils';

interface IMyAccountPageHistoryProps {
  licenseConfigurations: ILicenseConfiguration[];
}

export const MyAccountPageHistory: React.FC<IMyAccountPageHistoryProps> = (props) => {
  let { licenseConfigurations } = props;

  const [companyLicense, setCompanyLicense] = useState<ICompanyLicense>();
  const [error, setError] = useState();

  const loginDialogRedirect = useLoginDialogRedirect();

  useEffect(() => {
    setError(undefined);
    fetch(COMPANYLICENSES, {
      'method': 'GET',
      'credentials': 'same-origin'
    })
      .then(loginDialogRedirect)
      .then(async response => {
        if (!response.ok) {
          throw Error('Error retrieving company license. ' + (await Utils.messageFromResponse(response)));
        } else {
          return response.json();
        }
      })
      .then(data => {
        Object.keys(data).forEach(server => {
          setCompanyLicense(data[server].response.results[0]);
        });
      })
      .catch(error => setError(error.message));
  }, [loginDialogRedirect]);

  return (
    <>
      <div>In the following you can see the history of the company license assigned to your account:</div>
      <Collapse in={!!error} style={{ 'marginBottom': '10px' }}>
        <Typography variant='body1' color='error'>{error}{'\u00a0'}</Typography>
      </Collapse>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Arttest Version</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(companyLicense?.updateHistory || []).map(updateTime =>
            <TableRow key={updateTime}>
              <TableCell>{updateTime}</TableCell>
              <TableCell>{licenseConfigurations?.filter(config => config.id === companyLicense?.updateHistory[updateTime]).map(res => res.name)}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default MyAccountPageHistory;