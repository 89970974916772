import { useCallback, useContext } from 'react';

import { SessionContext } from '../App';

export default function useLoginDialogRedirect() {
  const session = useContext(SessionContext);

  const { setLoggedIn } = session;
  return useCallback((res: any) => {
    if (res.status === 401) {
      setLoggedIn(false);
      throw new Error("Unauthorized");
    }
    return res;
  }, [setLoggedIn]);
}