import { Button, Checkbox, ClickAwayListener, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Fade, FormControl, FormControlLabel, Grid, Grow, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, MenuList, Paper, Popover, Popper, Select, TextField, Theme, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { SessionContext } from '../App';
import ProgressButton from '../components/ProgressButton';
import { COMPANYLICENSES, UserRole } from '../config/Config';
import useLicenseServers from '../hooks/LicenseServersHook';
import { IMergedCompany } from '../interfaces/ICompany';
import ICompanyLicense from '../interfaces/ICompanyLicense';
import IFeatureSet, { IFeatureSetShort } from '../interfaces/IFeatureSet';
import ILicenseConfiguration from '../interfaces/ILicenseConfiguration';
import ILicenseServerConfiguration from '../interfaces/ILicenseServerConfiguration';
import IMultiServerResponse from '../interfaces/IMultiServerResponse';
import { ISessionContext } from '../interfaces/ISessionContext';
import { Utils } from '../utils/Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRow: {
      '& td': {
        borderBottom: '0px',
        verticalAlign: 'center'
      }
    },
    error: {
      marginTop: theme.spacing(2),
      color: 'red'
    },
    hidden: {
      display: 'none'
    }
  })
);

interface IAddCompanyLicenseDialog extends DialogProps {
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'done'): void;
  }['bivarianceHack'],
  company: IMergedCompany,
  featureSets: IMultiServerResponse<IFeatureSet>,
  lsConfigs: IMultiServerResponse<ILicenseServerConfiguration>,
  onGeneratedCompanyLicense: (serverUrl: string, companyLicense: ICompanyLicense) => void;
}

const daysToDate = (days: number) => {
  let d = new Date();
  d.setDate(d.getDate() + days);
  return d.toISOString().substr(0, 10);
};
const days = [7, 30, 90, 365, 2 * 365];

const AddCompanyLicenseDialog: React.FC<IAddCompanyLicenseDialog> = (props) => {
  let { company, lsConfigs, featureSets: allServerFeatureSets, onGeneratedCompanyLicense, ...dialogProps } = props;

  let { onClose, open } = props;

  const classes = useStyles();

  const session = useContext<ISessionContext>(SessionContext);

  const [licenseServers,] = useLicenseServers();

  const [error, setError] = useState('');
  const [selectionError, setSelectionError] = useState(false);

  const [selectedServerUrl, setSelectedServerUrl] = useState('');

  const [creationRunning, setCreationRunning] = useState(false);

  const [name, setName] = useState('');

  const [nodeLocked, setNodeLocked] = useState(false);

  const [selectedLicenseConfiguration, setSelectedLicenseConfiguration] = useState<ILicenseConfiguration>();
  const [numLicenses, setNumLicenses] = useState(1);
  const [additionalFeatureSets, setAdditionalFeatureSets] = useState<IFeatureSetShort[]>([]);
  const [additionalFeatureSetsAnchorEl, setAdditionalFeatureSetsAnchorEl] = React.useState<any>(null);

  const [restrictVersionChecked, setRestrictVersionChecked] = useState(true);
  const [versionRange, setVersionRange] = useState('[,3.0.0)');

  const [expires, setExpires] = useState(false);
  const anchorRef = useRef<any>(null);

  const [expiration, setExpiration] = useState(daysToDate(days[0]));
  const [expirationPopperOpen, setExpirationPopperOpen] = useState(false);

  const [endOfSupportChecked, setEndOfSupportChecked] = useState(true);
  const [endOfSupport, setEndOfSupport] = useState(daysToDate(days[0]));
  const [endOfSupportPopperOpen, setEndOfSupportPopperOpen] = useState(false);

  const lsConfig = useMemo(() => selectedServerUrl ? lsConfigs[selectedServerUrl!]?.response?.results[0] : undefined, [lsConfigs, selectedServerUrl]);
  const serverFeatureSets = useMemo(() => selectedServerUrl ? (allServerFeatureSets[selectedServerUrl]?.response?.results ?? []) : [], [allServerFeatureSets, selectedServerUrl]);

  /* Reset form on open */
  useEffect(() => {
    if (!open) { return; }

    setError('');
    setName('');
    setExpires(false);
    setExpiration(daysToDate(days[0]));
    setEndOfSupportChecked(true);
    setEndOfSupport(daysToDate(days[3]));
    setNumLicenses(1);
    setAdditionalFeatureSets([]);
    setRestrictVersionChecked(true);
    setVersionRange("[,3.0.0)");

    /* Select first license server by default */
    setSelectedServerUrl(licenseServers[0]?.url);
    setSelectedLicenseConfiguration(undefined);
  }, [open, licenseServers]);

  /* Select first license configuration by default */
  useEffect(() => {
    if (open && !selectedLicenseConfiguration) { setSelectedLicenseConfiguration(lsConfig?.licenseConfigurations?.[0]); }
  }, [open, selectedLicenseConfiguration, lsConfig]);

  async function generateCompanyKey() {
    /* Begin progress circle */
    setCreationRunning(true);
    try {
      if (!selectedLicenseConfiguration || !selectedServerUrl) {
        setSelectionError(false);
        return;
      }

      if (restrictVersionChecked && versionRange?.match(Utils.VERSION_RANGE_REGEX)?.[0] !== versionRange) {
        return;
      }

      if (expires && (expiration?.match(/^\d\d\d\d-\d\d-\d\d$/)?.[0] !== expiration || !new Date(expiration).getTime())) {
        return;
      }

      /* Trigger company license generation */
      let response = await fetch(COMPANYLICENSES, {
        method: 'POST',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: 'serverUrl=' + encodeURIComponent(selectedServerUrl)
          + '&companyId=' + encodeURIComponent(company.idMapping[selectedServerUrl])
          + '&name=' + encodeURIComponent(name)
          + '&licenseConfigurationId=' + selectedLicenseConfiguration.id
          + '&maxAmount=' + encodeURIComponent(numLicenses)
          + ((session.selfHosted || !session.roles?.includes(UserRole.ADMIN)) ? '&type=dynamic' :
            (
              '&type=' + (nodeLocked ? 'node-locked' : 'dynamic')
              + (session.selfHosted ? '' : ('&additionalFeatureSets=' + (encodeURIComponent(additionalFeatureSets.map(fs => fs.id).join(",")))))
              + (session.selfHosted ? '' : ('&expiration=' + (expires ? encodeURIComponent(expiration ?? '') : '')))
              + (session.selfHosted ? '' : ('&endOfSupport=' + (endOfSupportChecked ? encodeURIComponent(endOfSupport ?? '') : '')))
              + (session.selfHosted ? '' : ('&validVersions=' + encodeURIComponent(restrictVersionChecked ? (versionRange ?? '[,]') : '[,]')))
            ))
      });

      if (!response.ok) {
        setError('Error creating a new company license! ' + await (Utils.messageFromResponse(response)));
        return;
      }

      if (nodeLocked) {
        let companyLicense = (await response.json()) as ICompanyLicense;
        onGeneratedCompanyLicense(selectedServerUrl, companyLicense);
      }

      /* When finished close the dialog and refresh the company licenses */
      onClose?.({}, 'done');

    } finally {
      setCreationRunning(false);
    }
  }

  function handleKeyPressed(event: any) {
    if (event.charCode === 13) {
      generateCompanyKey();
    }
  }

  function addOrRemoveFeatureSet(featureSet: IFeatureSetShort, add: boolean) {
    let newFeatureSets = [...additionalFeatureSets];
    let index = newFeatureSets.findIndex(fs => fs.id === featureSet.id);
    if (index !== -1) {
      newFeatureSets.splice(index, 1);
    }
    if (add) {
      newFeatureSets.push(featureSet);
    }
    setAdditionalFeatureSets(newFeatureSets);
  }


  const availableLicenseServers = licenseServers?.filter(s => s.available && Utils.isOK(s.status)) ?? [];
  const additionalFeatureSetsPopoverOpen = Boolean(additionalFeatureSetsAnchorEl);

  return (
    <Dialog scroll={'paper'} fullWidth {...dialogProps}>
      <DialogTitle>Add Company License</DialogTitle>
      <DialogContent style={{ paddingBottom: '1em' }}> {/*I don't know why exactly, but this fixes the ugly scroll bar in the dialog */}
        <DialogContentText>
          {(session.roles?.includes(UserRole.ADMIN) &&
            <>Generate a new company license.</>) ||
            <>Please choose the Arttest version you want to buy. Keep in mind that changes done here will apply instantly. For billing we use the information you provided when first buying Arttest.</>
          }
        </DialogContentText>
        <Typography variant='body1' className={clsx(error && classes.error)}>
          {error}
        </Typography>
        {(!session.selfHosted) && session.roles?.includes(UserRole.ADMIN) &&
          <FormControl fullWidth>
            <InputLabel>Company</InputLabel>
            <Select value={'company'} disabled>
              <MenuItem value={'company'}>{company.name}</MenuItem>
            </Select>
          </FormControl>
        }
        {availableLicenseServers.length > 1 &&
          <FormControl style={{ marginTop: '1em' }} fullWidth error={selectionError}>
            <InputLabel>Server</InputLabel>
            <Select
              value={selectedServerUrl ?? ''}
              onChange={e => { setSelectedServerUrl(e.target.value as string); setSelectedLicenseConfiguration(undefined); }}
            >
              {availableLicenseServers.map(server =>
                <MenuItem key={server.url} value={server.url}>{server.name} ({server.url})
                </MenuItem>
              )}
            </Select>
          </FormControl>
        }
        <TextField
          style={{ marginTop: '1em' }}
          label='Custom Name'
          fullWidth
          value={name}
          onChange={(event) => { setName(event.target.value as string); }}
          onKeyPress={(event) => { handleKeyPressed(event); }}
          type="text"
        />
        <Grid container style={{ marginTop: '1em' }} alignItems='baseline' spacing={3}>
          <Grid item xs={12} sm={7}>
            <FormControl fullWidth error={selectionError}>
              <InputLabel>License Configuration</InputLabel>
              <Select
                error={!selectedLicenseConfiguration}
                value={selectedLicenseConfiguration?.id ?? ''}
                onChange={(event: any) => { setSelectedLicenseConfiguration(lsConfig?.licenseConfigurations.filter(lic => lic.id === event.target.value)[0]); }}
                onKeyPress={(event) => { handleKeyPressed(event); }}
              >
                {lsConfig?.licenseConfigurations?.map(licenseConfiguration =>
                  <MenuItem key={licenseConfiguration.id} value={licenseConfiguration.id}>
                    <Typography component='div' display="inline" style={{ flexGrow: 1, lineHeight: 1 }}>{licenseConfiguration.name}</Typography>
                    {session.roles?.includes(UserRole.ADMIN) && <Typography component='div' display="inline" variant="body2" style={{ float: "right" }} color="textSecondary">{licenseConfiguration.numberOfLicenses ?? 'Infinite'} licenses</Typography>}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              id="standard-number"
              label={'Allocated Licenses' + (selectedLicenseConfiguration?.numberOfLicenses !== undefined ? ` (max. ${selectedLicenseConfiguration.numberOfLicenses})` : '')}
              fullWidth
              value={numLicenses}
              onChange={(event) => { setNumLicenses(parseInt(event.target.value)); }}
              onKeyPress={(event) => { handleKeyPressed(event); }}
              type="number"
              inputProps={{ min: 0, max: selectedLicenseConfiguration?.numberOfLicenses }}
            />
          </Grid>
        </Grid>
        {!session.selfHosted && session.roles?.includes(UserRole.ADMIN) &&
          <>
            {(additionalFeatureSets.length > 0 || serverFeatureSets.length > 0) &&
              <Grid container style={{ marginTop: '1em' }} alignItems='flex-start' spacing={3}>
                <Grid item xs={12} sm={9}>
                  <Typography variant='body1'>Additional Feature Sets</Typography>
                  <Typography variant='body1' color='textSecondary'>{additionalFeatureSets.map(fs => fs.name).join(", ") || 'None'}</Typography>
                </Grid>
                <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                  {serverFeatureSets.length > 0 && <>
                    <Button variant='outlined' onClick={e => setAdditionalFeatureSetsAnchorEl(e.currentTarget)}>Edit</Button>
                    <Popover
                      id={additionalFeatureSetsPopoverOpen ? 'feature-sets-popover' : undefined}
                      open={additionalFeatureSetsPopoverOpen}
                      anchorEl={additionalFeatureSetsAnchorEl}
                      onClose={() => setAdditionalFeatureSetsAnchorEl(null)}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <Grid container direction='column' style={{ padding: "10px 20px" }}>
                        {serverFeatureSets.map(fs => <Grid item>
                          <FormControlLabel
                            key={'fs_' + fs.id}
                            control={
                              <Checkbox
                                checked={additionalFeatureSets.find(x => x.id === fs.id) !== undefined}
                                color='primary'
                                onChange={event => { addOrRemoveFeatureSet(fs, event.target.checked as boolean); }}
                              />
                            }
                            label={fs.name}
                          /></Grid>
                        )}
                      </Grid>
                    </Popover>
                  </>
                  }
                </Grid>
              </Grid>
            }
            <Grid container style={{ marginTop: '1em' }} alignItems='center' spacing={3}>
              <Grid item xs={12} sm={6} className={clsx(session.selfHosted && classes.hidden)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={restrictVersionChecked}
                      color='primary'
                      onChange={event => setRestrictVersionChecked(event.target.checked as boolean)}
                    />
                  }
                  label={'Restrict Version'}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={clsx(session.selfHosted && classes.hidden)}>
                <Fade in={restrictVersionChecked}>
                  <TextField
                    variant='outlined'
                    error={restrictVersionChecked && versionRange?.match(Utils.VERSION_RANGE_REGEX)?.[0] !== versionRange}
                    value={versionRange ?? ''}
                    onChange={e => { setVersionRange(e.target.value as string); }}
                    label="Version Range"
                    onKeyPress={(event) => { handleKeyPressed(event); }}
                    fullWidth
                  />
                </Fade>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '1em' }} alignItems='center' spacing={3}>
              <Grid item xs={12} sm={6} className={clsx(session.selfHosted && classes.hidden)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={endOfSupportChecked}
                      color='primary'
                      onChange={event => setEndOfSupportChecked(event.target.checked as boolean)}
                    />
                  }
                  label={'End of Support'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Fade in={endOfSupportChecked}>
                  <TextField
                    disabled={!endOfSupportChecked}
                    variant='outlined'
                    error={!endOfSupport && (endOfSupport?.match(/^\d\d\d\d-\d\d-\d\d$/)?.[0] !== endOfSupport || !new Date(endOfSupport).getTime())}
                    value={endOfSupport ?? ''}
                    onChange={e => { setEndOfSupport(e.target.value as string); }}
                    label="End of Support"
                    onKeyPress={(event) => { handleKeyPressed(event); }}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          size="small"
                          ref={anchorRef}
                          onClick={() => setEndOfSupportPopperOpen(prevOpen => !prevOpen)}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                </Fade>
                <Popper open={endOfSupportPopperOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', }}>
                      <Paper>
                        <ClickAwayListener onClickAway={() => setEndOfSupportPopperOpen(false)}>
                          <MenuList id="split-button-menu">
                            {days.map(days => {
                              let date = daysToDate(days);
                              return <MenuItem
                                key={date}
                                onClick={e => { setEndOfSupport(date); setEndOfSupportPopperOpen(false); }}
                              >
                                {days} Days
                              </MenuItem>;
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '1em' }} alignItems='center' spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={expires}
                      color='primary'
                      onChange={event => setExpires(event.target.checked as boolean)}
                    />
                  }
                  label={'Expires'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Fade in={expires}>
                  <TextField
                    disabled={!expires}
                    variant='outlined'
                    error={expires && (expiration?.match(/^\d\d\d\d-\d\d-\d\d$/)?.[0] !== expiration || !new Date(expiration).getTime())}
                    value={expiration ?? ''}
                    onChange={e => { setExpiration(e.target.value as string); }}
                    label="Expiration Date"
                    onKeyPress={(event) => { handleKeyPressed(event); }}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          size="small"
                          ref={anchorRef}
                          onClick={() => setExpirationPopperOpen(prevOpen => !prevOpen)}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                </Fade>
                <Popper open={expirationPopperOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', }}>
                      <Paper>
                        <ClickAwayListener onClickAway={() => setExpirationPopperOpen(false)}>
                          <MenuList id="split-button-menu">
                            {days.map(days => {
                              let date = daysToDate(days);
                              return <MenuItem
                                key={date}
                                onClick={e => { setExpiration(date); setExpirationPopperOpen(false); }}
                              >
                                {days} Days
                              </MenuItem>;
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '1em' }} alignItems='center' spacing={3}>
              <Grid item xs={12} sm={6} className={clsx(session.selfHosted && classes.hidden)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nodeLocked}
                      color='primary'
                      onChange={event => setNodeLocked(event.target.checked as boolean)}
                    />
                  }
                  label={'Node-Locked'}
                />
              </Grid>
            </Grid>
          </>
        }
      </DialogContent>
      <DialogActions>
        <ProgressButton showProgress={creationRunning} variant='outlined' onClick={() => generateCompanyKey()} color='primary'>
          {session.roles?.includes(UserRole.ADMIN) ? 'Save' : 'Buy'}
        </ProgressButton>
      </DialogActions>
    </Dialog >
  );
};

export default AddCompanyLicenseDialog;