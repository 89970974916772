import { Collapse, createStyles, Grid, makeStyles, TextField, Typography, Zoom } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';

import { SessionContext } from '../../App';
import ProgressButton from '../../components/ProgressButton';
import { USERS } from '../../config/Config';
import { ISessionContext } from '../../interfaces/ISessionContext';

const useStyles = makeStyles(theme => createStyles({
  saveButton: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1)
  },
  error: {
    color: red[500]
  },
  fade: { transition: 'opacity 300ms ease-in-out' },
  invisible: { opacity: 0 },
  doneIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-42px',
    marginLeft: '-42px',
    fontSize: '6em',
    color: green[500],
    backgroundColor: theme.palette.background.paper
  }
}));

export const MyAccountPageChangeProfile: React.FC = () => {
  const classes = useStyles();

  const session = useContext<ISessionContext>(SessionContext);

  /* New profile information */
  const [newUsername, setNewUsername] = useState('');
  const [newFirstname, setNewFirstname] = useState('');
  const [newLastname, setNewLastname] = useState('');
  const [newEMail, setNewEMail] = useState('');

  /* Password for confirmation */
  const [confirmPassword, setConfirmPassword] = useState('');

  const [saveRunning, setSaveRunning] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();

  let { username, firstname, lastname, email } = session;

  useEffect(() => {
    setNewUsername(username!);
    setNewFirstname(firstname!);
    setNewLastname(lastname!);
    setNewEMail(email!);
  }, [username, firstname, lastname, email]);

  async function save() {
    setError('');

    setSaveRunning(true);
    try {
      if (newUsername === '') {
        setError('Please don\'t leave the username empty.');
        return;
      }

      if (confirmPassword === '') {
        setError('Please fill in your current password for confirmation.');
        return;
      }

      try {
        let profileUpdateResponse = await fetch(USERS, {
          method: 'PATCH',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded'
          }),
          body: 'userIds=' + encodeURIComponent(JSON.stringify(session.userIds))
            + '&username=' + encodeURIComponent(newUsername)
            + '&firstname=' + encodeURIComponent(newFirstname)
            + '&lastname=' + encodeURIComponent(newLastname)
            + '&email=' + encodeURIComponent(newEMail)
            + '&oldPassword=' + encodeURIComponent(confirmPassword)
        });

        if (profileUpdateResponse.status < 200 || profileUpdateResponse.status >= 300) {
          throw new Error("Could not update profile");
        }

        let profileUpdateResponseJson = await profileUpdateResponse.json();

        if (profileUpdateResponseJson.success) {
          session.setUsername(newUsername);
          session.setFirstname(newFirstname);
          session.setLastname(newLastname);
          session.setEmail(newEMail);
        } else {
          throw new Error(profileUpdateResponseJson.message ? profileUpdateResponseJson.message : '(' + profileUpdateResponseJson.status + '): ' + profileUpdateResponseJson.statusText);
        }
      } catch (error) {
        setError(error.message);
        return;
      }
    } finally {
      setSaveRunning(false);
    }

    setSuccess(true);
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSaveRunning(false);
        setError(undefined);
        setConfirmPassword('');
      }, 500);

      setTimeout(() => setSuccess(false), 2000);
    }
  }, [success]);

  const enterCallback = (event: any) => { if (event.charCode === 13) { save(); } };

  return <><Zoom in={success}><CheckCircleIcon className={classes.doneIcon} /></Zoom>
    <Grid container direction='column' justify='flex-start' style={{ flexGrow: 1 }} className={clsx(classes.fade, success && classes.invisible)}>
      <Collapse in={!!error} className={classes.error}>{error}{"\u00a0"}</Collapse>
      <TextField
        margin='dense'
        id='name'
        label='Username'
        type='name'
        fullWidth
        value={newUsername}
        onChange={(event) => setNewUsername(event.target.value)}
        onKeyPress={enterCallback}
      //error={newUsername === ''}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin='dense'
            id='name'
            label='First Name'
            type='firstname'
            fullWidth
            value={newFirstname}
            onChange={(event) => setNewFirstname(event.target.value)}
            onKeyPress={enterCallback}
          //error={newFirstname === ''}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin='dense'
            id='name'
            label='Last Name'
            type='lastName'
            fullWidth
            value={newLastname}
            onChange={(event) => setNewLastname(event.target.value)}
            onKeyPress={enterCallback}
          //error={newLastname === ''}
          />
        </Grid>
      </Grid>
      <TextField
        margin='dense'
        id='name'
        label='E-Mail'
        type='email'
        fullWidth
        value={newEMail}
        onChange={(event) => setNewEMail(event.target.value)}
        onKeyPress={enterCallback}
      //error={newEMail === ''}
      />
      <Grid item style={{ flexGrow: 1 }} />
      <Typography variant='body1'>
        For confirmation please provide your password:
      </Typography>
      <TextField
        margin='dense'
        id='password'
        label='Current Password'
        type='password'
        fullWidth
        value={confirmPassword}
        onChange={(event) => setConfirmPassword(event.target.value)}
        onKeyPress={enterCallback}
      //error={confirmPassword === ''}
      />
      <ProgressButton variant='outlined' color='primary' onClick={save} showProgress={saveRunning} className={clsx(classes.saveButton)}>
        Save
  </ProgressButton>
    </Grid>
  </>;
};

export default MyAccountPageChangeProfile;
