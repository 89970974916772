import { Button, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormLabel, Grid, makeStyles, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { DialogProps } from '@material-ui/core/Dialog';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';

import { SessionContext } from '../App';
import ProgressButton from '../components/ProgressButton';
import RestrictedElement from '../components/RestrictedElement';
import { UserRole, USERS } from '../config/Config';
import useLicenseServers from '../hooks/LicenseServersHook';
import ICompanyLicense from '../interfaces/ICompanyLicense';
import { IMergedObject } from '../interfaces/IMergedMultiServerResponse';
import IMultiServerResponse from '../interfaces/IMultiServerResponse';
import { ISessionContext } from '../interfaces/ISessionContext';
import IUser from '../interfaces/IUser';
import { Utils } from '../utils/Utils';

const useStyles = makeStyles(() =>
  createStyles({
    error: {
      color: 'red'
    },
    hidden: {
      display: 'none'
    }
  })
);

interface EditUserProps extends DialogProps {
  user: IMergedObject<IUser>,
  triggerUpdate: () => void,
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'done'): void;
  }['bivarianceHack'];
}

const EditUserDialog: React.FC<EditUserProps> = (props) => {
  const classes = useStyles();

  const session = useContext<ISessionContext>(SessionContext);

  const [licenseServers,] = useLicenseServers();

  const [error, setError] = useState('');

  const [companyLicenseList, setCompanyLicenseList] = useState<IMultiServerResponse<ICompanyLicense>>();

  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);


  const [userEnabled, setUserEnabled] = useState(true);
  const [userRole, setUserRole] = useState('');

  const [saveRunning, setSaveRunning] = useState(false);

  const [selectedLicenseIds, setSelectedLicenseIds] = useState<IMergedObject<string>>({});

  const { user, triggerUpdate, ...dialogProps } = props;

  function createDataArray<T>(dataset: IMergedObject<IUser>, mapping: (user: IUser) => (T | undefined)): IMergedObject<T> {
    let res: IMergedObject<T> = {};
    Object.keys(dataset).forEach(server => {
      let entry = mapping(dataset[server]);
      if (entry !== undefined) {
        res[server] = entry;
      }
    });
    return res;
  }

  useEffect(() => {
    /* Avoid update when component is not ready */
    if (!user) {
      return;
    }

    /* Clear error messages */
    setError('');

    let companyIdMapping: IMergedObject<number> = {};
    Object.keys(user).filter(server => user[server].company).forEach(server => { companyIdMapping[server] = user[server].company!.id; });

    /* Fetch company licenses for the user */
    fetch('/backend/companies/companyLicenses?companyIdMapping=' + encodeURIComponent(JSON.stringify(companyIdMapping)), {
      method: 'GET',
    }).then(async response => {
      if (!response.ok) {
        throw Error('Error retrieving company licenses. ' + (await Utils.messageFromResponse(response)));
      } else {
        return response.json();
      }
    }).then(data => {
      let companyLicenseList = data as IMultiServerResponse<ICompanyLicense>;
      Object.keys(companyLicenseList).forEach(serverUrl => {
        companyLicenseList[serverUrl].response.results = companyLicenseList[serverUrl].response.results.filter(companyLicense => user[serverUrl].company?.id === companyLicense.company.id);
      });
      setCompanyLicenseList(companyLicenseList);
    }).catch(error => {
      setError(error.message);
    });

    let firstUser = user[Object.keys(user)[0]];

    /* Fill in user information */
    setUsername(firstUser.username);
    setFirstName(firstUser.firstName);
    setLastName(firstUser.lastName);
    setEmail(firstUser.email);
    setUserEnabled(firstUser.enabled);

    /* Convert roles list to role */
    let role = Utils.rolesToRole(firstUser.roles);
    setUserRole(role);

    /* Check which company licenses are assigned */
    setSelectedLicenseIds(createDataArray<string>(user, u => '' + (u.defaultCompanyLicense?.id ?? '')));
  }, [user, setUserRole]);

  function handleClose() {
    if (!!props.onClose) { props.onClose({}, "done"); }
  }

  function handleSave() {
    if (!user || !selectedLicenseIds) {
      return;
    }

    /* First reset error state */
    let hasErrors = false;
    setUsernameError(false);
    setPasswordError(false);
    setEmailError(false);

    if (username === '') {
      hasErrors = true;
      setUsernameError(true);
    }

    if (!!email.trim() && !email.match(Utils.EMAIL_REGEX)) {
      hasErrors = true;
      setEmailError(true);
    }

    if (hasErrors) {
      return;
    }

    /* Begin circular progress */
    setSaveRunning(true);

    /* Update the user in DB */
    fetch(USERS, {
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      body: 'username=' + encodeURIComponent(username || '')
        + '&password=' + encodeURIComponent(password || '')
        + '&firstname=' + encodeURIComponent(firstName || '')
        + '&lastname=' + encodeURIComponent(lastName || '')
        + '&email=' + encodeURIComponent(email || '')
        + '&enabled=' + encodeURIComponent(userEnabled)
        + '&role=' + encodeURIComponent(userRole || '')
        + '&licenseConfigurationIdMapping=' + encodeURIComponent(JSON.stringify(selectedLicenseIds))
        + '&userIds=' + encodeURIComponent(JSON.stringify(createDataArray(user, u => u.id)))
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Error updating user information (' + response.status + ')');
      }
    }).then(data => {
      if (!data.success) {
        throw new Error("Error: " + data.message);
      } else {
        triggerUpdate();
        handleClose();

        setUsername('');
        setFirstName('');
        setLastName('');
        setPassword('');
        setEmail('');
        setUserRole('user');
      }
    }).catch(reason => {
      setError((reason ? (reason.message ? reason.message : reason) : ''));
    }).finally(() => {
      setSaveRunning(false);
    });
  }

  function handleKeyPressed(event: any) {
    if (event.charCode === 13) {
      handleSave();
    }
  }

  const isMe = (user[Object.keys(user)[0]]?.username ?? '') === session.username;

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        {error && <Typography variant='body1' className={clsx(classes.error)}>{error}</Typography>}
        <TextField
          margin='dense'
          id='name'
          label='Username'
          type='name'
          fullWidth
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          onKeyPress={handleKeyPressed}
          error={usernameError}
        />
        <TextField
          margin='dense'
          id='password'
          label='New Password'
          type='password'
          autoComplete="new-password"
          fullWidth
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          onKeyPress={handleKeyPressed}
          error={passwordError}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin='dense'
              id='name'
              label='First Name'
              type='firstname'
              fullWidth
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              onKeyPress={handleKeyPressed}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin='dense'
              id='name'
              label='Last Name'
              type='lastName'
              fullWidth
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              onKeyPress={handleKeyPressed}
            />
          </Grid>
        </Grid>
        <TextField
          margin='dense'
          id='name'
          label='E-Mail'
          type='email'
          fullWidth
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          onKeyPress={handleKeyPressed}
          error={emailError}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={isMe}
              checked={userEnabled}
              color='primary'
              onChange={event => setUserEnabled(event.target.checked as boolean)}
            />
          }
          label={'User Enabled'}
        />
        <FormLabel component="legend" style={{ marginTop: '1em' }}>Role</FormLabel>
        <RadioGroup value={userRole} onChange={(e) => { setUserRole((e.target as HTMLInputElement).value); }}>
          <Grid container direction="row">
            <FormControlLabel
              disabled={isMe}
              control={<Radio value='user' color='primary' />}
              label={'User'}
            />
            <FormControlLabel
              disabled={session.roles?.includes(UserRole.ADMIN) && isMe}
              control={<Radio value='companyAdmin' color='primary' />}
              label={session.selfHosted ? 'Supervisor' : 'Company Admin'}
            />
            <RestrictedElement allowedRoles={[UserRole.ADMIN]} allowedInSelfHosting>
              <FormControlLabel
                control={<Radio value='admin' color='primary' style={session.selfHosted ? {} : { color: red[500] }} />}
                style={session.selfHosted ? {} : { color: red[500] }}
                label={session.selfHosted ? 'Administrator' : 'Mindmotiv Admin'}
              />
            </RestrictedElement>
          </Grid>
        </RadioGroup>
        {licenseServers && companyLicenseList &&
          <>
            <Typography style={{ marginTop: '1em' }}>In the following you can assign dynamic licenses (if available). For node-locked licenses you don't need to select anything.</Typography>
            <FormLabel component="legend" style={{ marginTop: '1em' }}>License</FormLabel>
            <Table padding='none' size='small'>
              <TableBody>
                {licenseServers.map(server =>
                  <TableRow key={"license_" + server.url}>
                    <TableCell style={{ paddingRight: '1em' }}>{server.name}</TableCell>
                    <TableCell>
                      <Select
                        fullWidth
                        value={selectedLicenseIds[server.url] || "none"}
                        onChange={(event: any) => {
                          setSelectedLicenseIds(oldValue => { return { ...oldValue, [server.url]: event.target.value === "none" ? '' : event.target.value }; });
                        }}
                      >
                        <MenuItem value={"none"}><Typography style={{ lineHeight: 'inherit' }} color='textSecondary'>- None -</Typography></MenuItem>
                        {companyLicenseList?.[server.url]?.response?.results?.filter(companyLicense => companyLicense.type === "dynamic").map(companyLicense =>
                          <MenuItem key={companyLicense.id} value={companyLicense.id}>
                            <Typography component='div' display="inline" style={{ flexGrow: 1 }}>
                              {companyLicense?.name || ''}
                              {companyLicense.licenseConfiguration?.name === companyLicense.name
                                ? ''
                                : (!companyLicense.name ? (companyLicense.licenseConfiguration?.name ?? '') : <Typography display="inline" color="textSecondary">{` (${companyLicense.licenseConfiguration?.name ?? 'Not assigned correctly'})`}</Typography>)
                              }
                            </Typography>
                            {'\u00a0'}
                            <Typography component='div' display="inline" style={{ float: "right" }} color="textSecondary">{companyLicense.maxAmount + ' ' + (companyLicense.maxAmount === 1 ? 'license' : 'licenses')}</Typography>
                          </MenuItem>
                        )}
                      </Select>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary' disabled={saveRunning}>Cancel</Button>
        <ProgressButton showProgress={saveRunning} variant='outlined' color='primary' onClick={handleSave}>Save</ProgressButton>
      </DialogActions>
    </Dialog >
  );
};

export default EditUserDialog;