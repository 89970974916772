import { Collapse, createStyles, DialogContentText, Grid, InputBase, Step, StepContent, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import React, { Fragment, useEffect, useGlobal, useState } from 'reactn';

import DownloadProgressBar from '../components/DownloadProgressBar';
import ProgressButton from '../components/ProgressButton';
import { DOWNLOAD_ARTTEST, FINISH_SETUP, LOGIN, SETUP_ADMIN_USER, SETUP_LICENSE_SERVERS } from '../config/Config';
import useDownloadState from '../hooks/DownloadStateHook';
import IBackendResponse from '../interfaces/IBackendResponse';
import IConfigJson from '../interfaces/IConfigJson';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(1)
    },
    hidden: {
      display: 'none'
    },
    error: {
      color: 'red'
    },
    stepButtons: {
      display: 'flex',
      alignItems: 'center',
    },
    stepButton: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      position: 'relative'
    }
  })
);


interface ILicenseServerSetupConfiguration {
  [id: string]: {
    name: string,
    url: string;
  };
}

interface ISetupWizardProps {
  onDone: () => void,
}

const SetupWizard: React.FC<ISetupWizardProps> = (props) => {
  const classes = useStyles();

  const [productName,] = useGlobal('productName');

  const [dialogOpen, setDialogOpen] = useState(true);
  const [setupRunning, setSetupRunning] = useState(false);
  const [error, setError] = useState('');

  /* Stepper */
  const steps = ['License Servers', 'Administration Access', `Download ${productName}`, 'Finish'];
  const [activeStep, setActiveStep] = useState(0);
  const [configuredLicenseServers, setConfiguredLicenseServers] = useState<ILicenseServerSetupConfiguration>({ '0': { name: '', url: '' } });

  /* Create admin user */
  const [companyName, setCompanyName] = useState('');
  const [adminUsername, setAdminUsername] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [confirmAdminPassword, setConfirmAdminPassword] = useState('');
  const [adminFirstName, setAdminFirstName] = useState('');
  const [adminLastName, setAdminLastName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');

  /* Existing admin user */
  const [adminUserExisting, setAdminUserExisting] = useState(false);
  const [adminUserLoggedIn, setAdminUserLoggedIn] = useState(false);

  /* Download Arttest */
  const [downloadLink, setDownloadLink] = useState('');

  /* Custom hook */
  const [downloadState, dispatchDownloadState] = useDownloadState('/backend/downloadProgress');

  let { onDone } = props;

  /* Download State */
  useEffect(() => {
    if (!downloadState.downloadRunning) { setSetupRunning(false); }
    if (downloadState.error) { setError(downloadState.error); }
    if (downloadState.done) { nextStep(); }
  }, [downloadState]);

  /* Fetch existing config.json */
  useEffect(() => {
    fetch(SETUP_LICENSE_SERVERS, { method: 'GET', credentials: 'same-origin' })
      .then(response => {
        return response.json();
      }).then(data => {
        let backendResponse = data as IBackendResponse<IConfigJson>;
        if (backendResponse.success) {
          if (backendResponse.payload) {
            let configJson = backendResponse.payload;

            let tmp: ILicenseServerSetupConfiguration = {};
            configJson.licenseServers.forEach((licenseServer, index) => {
              tmp[index + ''] = {
                name: licenseServer.name,
                url: licenseServer.url
              };
            });

            tmp[configJson.licenseServers.length] = {
              name: '',
              url: ''
            };

            setConfiguredLicenseServers(tmp);
          }
        }
        // else {
        //   if (backendResponse.message) {
        //     setError(backendResponse.message);
        //   } else {
        //     setError('Could not get the current config.json.')
        //   }
        // }
      }).catch(error => {
        setError(error.message);
      });
  }, []);

  async function generateConfigJson() {
    if (Object.values(configuredLicenseServers).filter(s => s.name.trim() || s.url.trim()).length === 0) {
      setError('Please add at least one license server');
      return;
    }

    setError('');
    setSetupRunning(true);

    /* Setup the config.json in the backend */
    let setupResponseJson = await fetch(SETUP_LICENSE_SERVERS, {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      body: 'inSetupPhase=true&configuredLicenseServers=' + encodeURIComponent(JSON.stringify(configuredLicenseServers))
    }).then(response => response.json()) as IBackendResponse<IConfigJson>;

    setSetupRunning(false);

    if (setupResponseJson.success) {
      /* Additional check if servers are initialized or not */
      await fetch(SETUP_ADMIN_USER, { method: 'GET', credentials: 'same-origin' })
        .then(async response => {
          let json = await response.json();

          if (response.status === 409) {
            throw new Error(json.message ? json.message : 'Error fetching the license servers initialization state');
          }

          return json;
        }).then(data => {
          setAdminUserExisting(!data.success);
        }).catch(error => {
          setError(error.message);
        }).finally(() => {
          setAdminUserLoggedIn(false);
        });

      nextStep();
    } else {
      setError(setupResponseJson.message ? setupResponseJson.message : 'Creation of the configuration file failed');
    }
  };

  async function createAdminUser() {
    setError('');

    if (adminPassword !== confirmAdminPassword) {
      setError("The passwords don't match.");
      return;
    }

    setSetupRunning(true);

    try {
      let response = await fetch(SETUP_ADMIN_USER, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'companyName=' + encodeURIComponent(companyName)
          + '&adminUsername=' + encodeURIComponent(adminUsername)
          + '&adminPassword=' + encodeURIComponent(adminPassword)
          + '&adminFirstName=' + encodeURIComponent(adminFirstName)
          + '&adminLastName=' + encodeURIComponent(adminLastName)
          + '&adminEmail=' + encodeURIComponent(adminEmail)
      }).then(r => r.json());

      if (!response.success) {
        throw new Error(response.message ? response.message : 'Error creating the admin user');
      } else {
        nextStep();
      }
    } catch (err) {
      setError(err);
    }

    setSetupRunning(false);
  }

  async function loginAdminUser() {
    setError('');

    setSetupRunning(true);

    await fetch(LOGIN, {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      body: 'username=' + encodeURIComponent(adminUsername) + '&password=' + encodeURIComponent(adminPassword)
    }).then(response => {
      if (response.ok) {
        setAdminUserLoggedIn(true);
        nextStep();
      } else {
        if (response.status === 400) {
          setError('Invalid credentials');
        } else if (response.status === 503) {
          setError('License server unavailable');
        } else {
          setError(response.status + ' ' + response.statusText);
        }
        setAdminUserLoggedIn(false);
      }
    });

    setSetupRunning(false);
  }

  async function downloadArttest() {
    setError('');

    if (!downloadLink) {
      /* Skip download */
      nextStep();
      return;
    }

    setSetupRunning(true);

    fetch(DOWNLOAD_ARTTEST, {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      body: 'url=' + encodeURIComponent(downloadLink)
    })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          setSetupRunning(false);
          setError(data.message ? data.message : 'Could not start the download process.');
        }
      })
      .catch(error => {
        setSetupRunning(false);
        setError(error.message);
        dispatchDownloadState({ type: 'error', error: error.message });
      });
  }

  async function cancelArttestDownload() {
    await fetch(DOWNLOAD_ARTTEST, { method: 'DELETE', credentials: 'same-origin' });
    setSetupRunning(false);
  }

  async function finish() {
    setSetupRunning(true);
    try {
      let response = await fetch(FINISH_SETUP, { method: 'POST', credentials: 'same-origin' }).then(r => r.json());
      if (!response.success) {
        setError(response.message || 'Error finishing up');
        return;
      }

      setSetupRunning(false);
      setDialogOpen(false);
      onDone();
    } catch (err) {
      setError('' + err);
    } finally {
      setSetupRunning(false);
    }
  }

  async function handleKeyPressed(event: any, step: number) {
    if (event.charCode === 13) {
      executeStep(step);
    }
  }

  function updateName(tmp: string, id: string) {
    setConfiguredLicenseServers(prev => { return { ...prev, [id]: { name: tmp, url: prev[id].url } }; });

    if (configuredLicenseServers[id].name && configuredLicenseServers[id].url) {
      addRow(id);
    }
  }

  function updateUrl(tmp: string, id: string) {
    setConfiguredLicenseServers(prev => { return { ...prev, [id]: { name: prev[id].name, url: tmp } }; });
    if (configuredLicenseServers[id].name && configuredLicenseServers[id].url) {
      addRow(id);
    }
  }

  function addRow(currentId: string) {
    if (!configuredLicenseServers[parseInt(currentId) + 1]) {
      setConfiguredLicenseServers(prev => { return { ...prev, [parseInt(currentId) + 1]: { name: '', url: '' } }; });
    }
  }

  function getButtonLabel(step: number) {
    switch (step) {
      case 0:
        return 'Next';
      case 1:
        return !!adminUserExisting ? (!!adminUserLoggedIn ? 'Skip' : 'Login') : 'Create';
      case 2:
        return !!downloadLink ? 'Download' : 'Skip';
      case 3:
        return 'Finish';
    }
  }

  function nextStep() {
    setSetupRunning(false);
    setError('');
    setActiveStep(currStep => currStep + 1);
  }

  function executeStep(step: number) {
    switch (step) {
      case 0:
        generateConfigJson();
        break;

      case 1:
        /* In case this form isn't required just skip it */
        if (adminUserExisting) {
          if (adminUserLoggedIn) {
            nextStep();
          } else {
            loginAdminUser();
          }
        } else {
          createAdminUser();
        }
        break;

      case 2:
        downloadArttest();
        break;

      case 3:
        finish();
        break;

      default:
        break;
    }
  }

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <Fragment key={'frag_' + step}>
            <Typography variant='body1'>
              Please configure the license servers you want to use.
            </Typography>
            <Table className={classes.table} padding='none' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>URL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {configuredLicenseServers && Object.keys(configuredLicenseServers).map(serverConfigurationId =>
                  <TableRow key={serverConfigurationId}>
                    <TableCell style={{ paddingRight: '1em' }}>
                      <InputBase
                        fullWidth
                        placeholder='Name'
                        value={configuredLicenseServers[serverConfigurationId].name}
                        onChange={event => { updateName(event.target.value, serverConfigurationId); }}
                        onKeyPress={(event) => { handleKeyPressed(event, step); }}
                      />
                    </TableCell>
                    <TableCell>
                      <InputBase
                        fullWidth
                        placeholder='URL'
                        value={configuredLicenseServers[serverConfigurationId].url}
                        onChange={event => { updateUrl(event.target.value, serverConfigurationId); }}
                        onKeyPress={event => { handleKeyPressed(event, step); }}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Fragment>);

      case 1:
        const createNewUserForm = <Fragment key={'frag_' + step}>
          <TextField
            fullWidth
            autoFocus
            label='Company Name'
            disabled={setupRunning}
            value={companyName}
            onChange={(event) => { setCompanyName(event.target.value); }}
            onKeyPress={(event) => { if (event.charCode === 13) { executeStep(step); } }}
          />
          <TextField
            fullWidth
            label='Username'
            disabled={setupRunning}
            value={adminUsername}
            onChange={(event) => { setAdminUsername(event.target.value); }}
            onKeyPress={(event) => { if (event.charCode === 13) { executeStep(step); } }}
          />
          <TextField
            fullWidth
            label='Password'
            type='password'
            autoComplete="new-password"
            disabled={setupRunning}
            value={adminPassword}
            onChange={(event) => { setAdminPassword(event.target.value); }}
            onKeyPress={(event) => { if (event.charCode === 13) { executeStep(step); } }}
          />
          <TextField
            fullWidth
            label='Confirm Password'
            type='password'
            autoComplete="new-password"
            disabled={setupRunning}
            value={confirmAdminPassword}
            onChange={(event) => { setConfirmAdminPassword(event.target.value); }}
            onKeyPress={(event) => { if (event.charCode === 13) { executeStep(step); } }}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='First Name'
                disabled={setupRunning}
                value={adminFirstName}
                onChange={(event) => setAdminFirstName(event.target.value)}
                onKeyPress={(event) => { if (event.charCode === 13) { executeStep(step); } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='Last Name'
                disabled={setupRunning}
                value={adminLastName}
                onChange={(event) => setAdminLastName(event.target.value)}
                onKeyPress={(event) => { if (event.charCode === 13) { executeStep(step); } }}
              />
            </Grid>
          </Grid>
          <TextField
            fullWidth
            label='E-Mail'
            disabled={setupRunning}
            value={adminEmail}
            onChange={(event) => setAdminEmail(event.target.value)}
            onKeyPress={(event) => { if (event.charCode === 13) { executeStep(step); } }}
            style={{ marginBottom: '10px' }}
          />
        </Fragment>;

        const existingUserForm = <Fragment key={'frag_' + step + '_sub'}>
          <Typography variant='body1'>
            The license serves are already initialized. Please provide the credentials to these servers.
          </Typography>
          <TextField
            fullWidth
            autoFocus
            label='Username'
            disabled={setupRunning}
            value={adminUsername}
            onChange={(event) => { setAdminUsername(event.target.value); }}
            onKeyPress={(event) => { if (event.charCode === 13) { executeStep(step); } }}
          />
          <TextField
            fullWidth
            label='Password'
            type='password'
            disabled={setupRunning}
            value={adminPassword}
            onChange={(event) => { setAdminPassword(event.target.value); }}
            onKeyPress={(event) => { if (event.charCode === 13) { executeStep(step); } }}
          />
        </Fragment>;

        const alreadyLoggedInForm = <Fragment key={'frag_' + step + '_sub_sub'}>
          You're already logged in. Do you want to logout?
          <Button>Logout</Button>
        </Fragment>;

        return adminUserExisting ? (adminUserLoggedIn ? alreadyLoggedInForm : existingUserForm) : createNewUserForm;

      case 2:
        return (
          <Fragment key={'frag_' + step}>
            <Typography variant='body1'>
              Please fill in the download link we provided for your {productName} release.
          </Typography>
            <TextField
              fullWidth
              autoFocus
              placeholder={`${productName} Download Link`}
              disabled={downloadState.downloadRunning}
              value={downloadLink}
              onChange={(event) => { setDownloadLink(event.target.value); }}
              onKeyPress={(event) => { handleKeyPressed(event, step); }}
            />
            <DownloadProgressBar downloadState={downloadState} onCancel={() => cancelArttestDownload()} />
          </Fragment>);

      case 3:
        return (
          <Fragment key={'frag_' + step}>
            <Typography variant='body1'>
              You're done! Everything is configured and you may login now.
          </Typography>
          </Fragment>);

      default:
        return (
          <Fragment key={'frag_' + step}>
            This is an unknown step.
        </Fragment>);
    }
  }

  return (
    <Dialog open={dialogOpen} fullWidth maxWidth='lg' disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>Setup Wizard</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The Setup Wizard will guide you through the initial setup of the license service.
        </DialogContentText>
        <Collapse in={!!error} className={classes.error}>
          {error}{"\u00a0"}
        </Collapse>
        <Stepper activeStep={activeStep} orientation='vertical'>
          {steps.map((label, index) =>
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {getStepContent(index)}
                <div className={classes.stepButtons}>
                  <Button className={classes.stepButton} variant='outlined' color='primary' disabled={(activeStep === 0) || setupRunning} onClick={() => { activeStep === 0 ? setActiveStep(activeStep) : setActiveStep(activeStep - 1); }}>
                    Back
                  </Button>
                  <ProgressButton className={classes.stepButton} showProgress={setupRunning} variant='outlined' color='primary' onClick={() => { executeStep(index); }}>
                    {getButtonLabel(index)}
                  </ProgressButton>
                </div>
              </StepContent>
            </Step>
          )}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
};

export default SetupWizard;