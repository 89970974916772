import { CircularProgress, Collapse, createStyles, Dialog, DialogContent, DialogContentText, DialogProps, DialogTitle, IconButton, makeStyles, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import React, { useState } from 'react';

import { RESOLVE_CONFLICT_COMPANY } from '../config/Config';
import { IMergedCompany } from '../interfaces/ICompany';

const useStyles = makeStyles(() =>
  createStyles({
    circluarProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-20px',
      marginLeft: '-20px'
    },
    fade: {
      transition: 'opacity 300ms ease-in-out'
    },
    invisible: {
      opacity: 0
    },
    error: {
      color: 'red'
    },
    hidden: {
      display: 'none'
    }
  })
);

interface ResolveCompanyConflictDialogProps extends DialogProps {
  conflictedData: IMergedCompany,
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'done'): void;
  }['bivarianceHack'];
}

const ResolveCompanyConflictDialog: React.FC<ResolveCompanyConflictDialogProps> = (props) => {
  const classes = useStyles();

  const [saveRunning, setSaveRunning] = useState(false);
  const [error, setError] = useState('');

  let { conflictedData, ...dialogProps } = props;
  let { onClose } = props;

  function transferToOtherLicenseServers(serverUrl: string) {
    setError('');

    if (saveRunning) {
      return;
    }

    setSaveRunning(true);

    try {
      fetch(RESOLVE_CONFLICT_COMPANY, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: 'serverUrl=' + encodeURIComponent(serverUrl)
          + '&id=' + encodeURIComponent(conflictedData.idMapping[serverUrl])
      }).then(async response => {
        if (response.ok) {
          return response.json();
        } else {
          let message = ""; try { message = ": " + (await response.json()).message; } catch { }
          throw new Error('Error resolving conflicts (' + response.status + ')' + message);
        }
      }).then(data => {
        if (data.success) {
          onClose?.({}, 'done');
        } else {
          throw new Error("Error: " + data.message);
        }
      }).catch(reason => {
        setError((reason ? (reason.message ? reason.message : reason) : ''));
      });
    } finally {
      setSaveRunning(false);
    }
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Resolve Conflicts</DialogTitle>
      <CircularProgress className={clsx(classes.fade, classes.circluarProgress, !saveRunning && classes.invisible)} />
      <DialogContent className={clsx(classes.fade, saveRunning && classes.invisible)}>
        <Collapse in={!!error}>
          <Typography variant='body1' className={classes.error}>{error}{'\u00a0'}</Typography>
        </Collapse>
        <DialogContentText>
          Please select the server from which you would like to transfer the data to all other servers
        </DialogContentText>
      </DialogContent >
      <Table className={clsx(classes.fade, saveRunning && classes.invisible)}>
        <TableBody>
          {conflictedData?.idMapping && Object.keys(conflictedData?.idMapping).map((server, i) => <TableRow hover key={'Company_' + server}>
            <TableCell style={{ width: '3em' }}><IconButton size="small" onClick={() => { transferToOtherLicenseServers(server); }} ><ArrowForward /></IconButton></TableCell>
            <TableCell align='left'>{server}</TableCell>
          </TableRow>
          )}
        </TableBody>
      </Table>
    </Dialog >
  );
};

export default ResolveCompanyConflictDialog;