import { Collapse, createStyles, Grid, InputBase, Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';

import ProgressButton from '../../components/ProgressButton';
import { LOGOUT, SETUP_LICENSE_SERVERS } from '../../config/Config';
import IBackendResponse from '../../interfaces/IBackendResponse';
import IConfigJson from '../../interfaces/IConfigJson';

interface ILicenseServerConfiguration {
  [id: string]: {
    name: string,
    url: string;
  };
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  saveButton: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1)
  },
  error: {
    color: red[500]
  }
}));

export const SettingsPageLicenseServers: React.FC = (props) => {
  const classes = useStyles();

  const [saveRunning, setSaveRunning] = useState(false);
  const [error, setError] = useState<string>();


  /* Config.json */
  const [configuredLicenseServers, setConfiguredLicenseServers] = useState<ILicenseServerConfiguration>({ '0': { name: '', url: '' } });

  let handleConfigJsonUpdate = useCallback(async function () {
    /* Set the state to display the circular process */
    setSaveRunning(true);

    let setupResponse = await fetch(SETUP_LICENSE_SERVERS, {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      body: 'configuredLicenseServers=' + encodeURIComponent(JSON.stringify(configuredLicenseServers))
    });

    let setupResponseJson = (await setupResponse.json()) as { success: boolean, message?: string; };

    if (setupResponseJson.success) {
      await fetch(LOGOUT, { method: 'POST', credentials: 'same-origin' });
      window.location.reload();
    } else {
      setSaveRunning(false);
      if (!setupResponseJson.message) {
        setError('Creation of the configuration file failed');
      } else {
        setError(setupResponseJson.message);
      }
    }
  }, [configuredLicenseServers]);

  useEffect(() => {
    /* Fetch current config.json */
    fetch(SETUP_LICENSE_SERVERS, { method: 'GET', credentials: 'same-origin' })
      .then(response => {
        return response.json();
      }).then(data => {
        let backendResponse = data as IBackendResponse<IConfigJson>;
        if (backendResponse.success) {
          if (backendResponse.payload) {
            let configJson = backendResponse.payload;

            let tmp: ILicenseServerConfiguration = {};
            configJson.licenseServers.forEach((licenseServer, index) => {
              tmp[index + ''] = {
                name: licenseServer.name,
                url: licenseServer.url
              };
            });

            tmp[configJson.licenseServers.length] = {
              name: '',
              url: ''
            };

            setConfiguredLicenseServers(tmp);
          }
        } else {
          if (backendResponse.message) {
            setError(backendResponse.message);
          } else {
            setError('Could not get the current config.json.');
          }
        }
      }).catch(error => {
        setError(error.message);
      });
  }, []);


  function updateName(tmp: string, id: string) {
    setConfiguredLicenseServers(prev => { return { ...prev, [id]: { name: tmp, url: prev[id].url } }; });

    if (configuredLicenseServers[id].name && configuredLicenseServers[id].url) {
      addRow(id);
    }
  }

  function updateUrl(tmp: string, id: string) {
    setConfiguredLicenseServers(prev => { return { ...prev, [id]: { name: prev[id].name, url: tmp } }; });
    if (configuredLicenseServers[id].name && configuredLicenseServers[id].url) {
      addRow(id);
    }
  }

  function addRow(currentId: string) {
    if (!configuredLicenseServers[parseInt(currentId) + 1]) {
      setConfiguredLicenseServers(prev => { return { ...prev, [parseInt(currentId) + 1]: { name: '', url: '' } }; });
    }
  }

  const enterCallback = (event: any) => { if (event.charCode === 13) { handleConfigJsonUpdate(); } };

  return <Grid container direction='column' style={{ flexGrow: 1 }}>
    <Collapse in={!!error} className={classes.error}>{error}{"\u00a0"}</Collapse>
    <Table padding='none' size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>URL</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {configuredLicenseServers && Object.keys(configuredLicenseServers).map(serverConfigurationId =>
          <TableRow key={serverConfigurationId}>
            <TableCell style={{ paddingRight: '1em' }}>
              <InputBase
                fullWidth
                placeholder='Name'
                value={configuredLicenseServers[serverConfigurationId].name}
                onChange={event => { updateName(event.target.value, serverConfigurationId); }}
                onKeyPress={enterCallback}
              />
            </TableCell>
            <TableCell>
              <InputBase
                fullWidth
                placeholder='URL'
                value={configuredLicenseServers[serverConfigurationId].url}
                onChange={event => { updateUrl(event.target.value, serverConfigurationId); }}
                onKeyPress={enterCallback}
              />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
    <Grid item style={{ flexGrow: 1 }} />
    <ProgressButton variant='outlined' color='primary' onClick={handleConfigJsonUpdate} showProgress={saveRunning} className={clsx(classes.saveButton)}>
      Save
    </ProgressButton>
  </Grid>;
};
export default SettingsPageLicenseServers;