import React, { PropsWithChildren } from 'react';

import { UserRole } from '../config/Config';
import useForbiddenState from '../hooks/ForbiddenStateHook';

interface IRestrictedElement {
  allowedRoles: UserRole[],
  requireExactRoleMatch?: boolean,
  allowedInSelfHosting?: boolean;
}

const RestrictedElement: React.FC<IRestrictedElement> = (props: PropsWithChildren<IRestrictedElement>) => {
  const allowed = useForbiddenState(props.allowedRoles, !!props.requireExactRoleMatch, !!props.allowedInSelfHosting);
  return <>{allowed && props.children}</>;
};

export default RestrictedElement;