import { Collapse, createStyles, Grid, makeStyles, TextField, Zoom } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';

import { SessionContext } from '../../App';
import ProgressButton from '../../components/ProgressButton';
import { USERS } from '../../config/Config';
import { ISessionContext } from '../../interfaces/ISessionContext';

const useStyles = makeStyles(theme => createStyles({
  saveButton: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1)
  },
  error: {
    color: red[500]
  },
  fade: { transition: 'opacity 300ms ease-in-out' },
  invisible: { opacity: 0 },
  doneIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-42px',
    marginLeft: '-42px',
    fontSize: '6em',
    color: green[500],
    backgroundColor: theme.palette.background.paper
  }
}));

export const MyAccountPageChangePassword: React.FC = (props) => {
  const classes = useStyles();

  const session = useContext<ISessionContext>(SessionContext);

  /* Password change */
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [saveRunning, setSaveRunning] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setError(newPassword !== confirmPassword ? 'The new and the confirm password have to be the same.' : '');
  }, [newPassword, confirmPassword]);

  async function handlePasswordChange() {
    setError('');

    setSaveRunning(true);

    try {
      if (currentPassword === '') {
        setError('Please fill in the current password.');
        return;
      }

      if (newPassword === '' || confirmPassword === '') {
        setError('Please fill in the new password and its confirmation.');
        return;
      }

      if (newPassword !== confirmPassword) {
        setError('The new and the confirm password have to be the same.');
        return;
      }

      try {
        let passwordUpdateResponse = await fetch(USERS, {
          method: 'PATCH',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded'
          }),
          body: 'userIds=' + JSON.stringify(session.userIds) + '&oldPassword=' + encodeURIComponent(currentPassword) + '&password=' + encodeURIComponent(newPassword)
        });

        if (passwordUpdateResponse.status < 200 || passwordUpdateResponse.status >= 300) {
          throw new Error("Could not update password");
        }

        let passwordUpdateResponseJson = await passwordUpdateResponse.json();
        if (!passwordUpdateResponseJson.success) {
          throw new Error(passwordUpdateResponseJson.message ? passwordUpdateResponseJson.message : '(' + passwordUpdateResponse.status + '): ' + passwordUpdateResponse.statusText);
        }
      } catch (error) {
        setError(error.message);
        return;
      }
    } finally {
      setSaveRunning(false);
    }

    setSuccess(true);
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSaveRunning(false);
        setError(undefined);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      }, 500);

      setTimeout(() => setSuccess(false), 2000);
    }
  }, [success]);

  const enterCallback = (event: any) => { if (event.charCode === 13) { handlePasswordChange(); } };

  return <><Zoom in={success}><CheckCircleIcon className={classes.doneIcon} /></Zoom>
    <Grid container direction='column' justify='flex-start' style={{ flexGrow: 1 }} className={clsx(classes.fade, success && classes.invisible)}>
      <Collapse in={!!error} className={classes.error}>{error}{"\u00a0"}</Collapse>
      <TextField
        autoFocus
        fullWidth
        id="current-password-input"
        label="Current Password"
        type="password"
        autoComplete="current-password"
        margin="dense"
        disabled={saveRunning}
        value={currentPassword}
        onChange={event => setCurrentPassword(event.target.value)}
        onKeyPress={enterCallback}
      />
      <TextField
        fullWidth
        id="new-password-input"
        label="New Password"
        type="password"
        autoComplete="new-password"
        margin="dense"
        disabled={saveRunning}
        error={newPassword !== confirmPassword}
        value={newPassword}
        onChange={event => setNewPassword(event.target.value)}
        onKeyPress={enterCallback}
      />
      <TextField
        fullWidth
        id="confirm-new-password-input"
        label="Confirm New Password"
        type="password"
        autoComplete="new-password"
        margin="dense"
        disabled={saveRunning}
        error={newPassword !== confirmPassword}
        value={confirmPassword}
        onChange={event => setConfirmPassword(event.target.value)}
        onKeyPress={enterCallback}
      />
      <Grid item style={{ flexGrow: 1 }} />
      <ProgressButton variant='outlined' color='primary' onClick={handlePasswordChange} showProgress={saveRunning} className={clsx(classes.saveButton)}>
        Save
  </ProgressButton>
    </Grid>
  </>;
};

export default MyAccountPageChangePassword;
