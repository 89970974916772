import { Grid, Hidden } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import clsx from 'clsx';
import { Route } from 'react-router';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import React, { useEffect, useGlobal } from 'reactn';

import AppBar from './components/AppBar';
import Drawer from './components/Drawer';
import { ADMINPANEL_DRAWER_WIDTH, IS_READY, UserRole } from './config/Config';
import LoginDialog from './dialogs/LoginDialog';
import SetupWizard from './dialogs/SetupWizard';
import ICompany from './interfaces/ICompany';
import ICompanyLicense from './interfaces/ICompanyLicense';
import { ISessionContext } from './interfaces/ISessionContext';
import { IUserIds } from './interfaces/IUser';
import Administration from './pages/Administration';
import CompanyLicenses from './pages/CompanyLicenses';
import Download from './pages/Download';
import General from './pages/General';
import Licenses from './pages/Licenses';
import NotFound from './pages/NotFound';
import { theme } from './theme/Theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootApp: {
      display: 'flex',
      flex: 1
    },
    content: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'stretch',
      marginTop: theme.spacing(10),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    leftMargin: {
      marginLeft: ADMINPANEL_DRAWER_WIDTH + 'px'
    },
    hidden: {
      display: 'none'
    }
  })
);

/* Create context */
export const SessionContext = React.createContext<ISessionContext>({
  isLoggedIn: false, setLoggedIn: () => { },
  userIds: undefined, setUserIds: () => { },
  username: '', setUsername: () => { },
  firstname: '', setFirstname: () => { },
  lastname: '', setLastname: () => { },
  email: '', setEmail: () => { },
  company: undefined, setCompany: () => { },
  companyLicense: undefined, setCompanyLicense: () => { },
  roles: [UserRole.USER], setRoles: () => { },
  drawerOpen: false, setDrawerOpen: () => { },
  selfHosted: false, setSelfHosted: () => { }
});

const App: React.FC = () => {
  /* CSS Style */
  const classes = useStyles();

  /* Local state */
  const [isReady, setReady] = React.useState(true);
  const [, setProductName] = useGlobal('productName');

  /* Context states */
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [userIds, setUserIds] = React.useState<IUserIds>();
  const [username, setUsername] = React.useState('');
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [company, setCompany] = React.useState<ICompany>();
  const [companyLicense, setCompanyLicense] = React.useState<ICompanyLicense>();
  const [roles, setRoles] = React.useState<UserRole[]>();
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const [selfHosted, setSelfHosted] = React.useState(false);

  function handleDrawerToggle() {
    setMobileDrawerOpen(!mobileDrawerOpen);
  }

  useEffect(() => {
    fetch(IS_READY, {
      method: 'GET',
      credentials: 'same-origin'
    }).then(result => {
      if (result.ok) {
        return result.json();
      } else {
        throw new Error('Response from server not okay. Maybe connection issue?');
      }
    }).then(data => {
      setReady(data.ready);
      setProductName(data.productName);
    }).catch(error => {
      console.log('Exception occured: ' + error.message);
    });
  }, [setProductName]);

  /* Equivalent to render() in classes */
  return (
    <div className={classes.rootApp}>
      <CssBaseline>
        <ThemeProvider theme={theme}>
          <SessionContext.Provider value={{
            isLoggedIn: loggedIn, setLoggedIn: setLoggedIn,
            userIds: userIds, setUserIds: setUserIds,
            username: username, setUsername: setUsername,
            firstname: firstname, setFirstname: setFirstname,
            lastname: lastname, setLastname: setLastname,
            email: email, setEmail: setEmail,
            company: company, setCompany: setCompany,
            companyLicense: companyLicense, setCompanyLicense: setCompanyLicense,
            roles: roles, setRoles: setRoles,
            drawerOpen: mobileDrawerOpen, setDrawerOpen: setMobileDrawerOpen,
            selfHosted: selfHosted, setSelfHosted: setSelfHosted
          }}>
            <Router basename='/'>
              <Grid container direction='column'>
                <Grid item>
                  <AppBar handleDrawerToggle={handleDrawerToggle} setLoggedIn={setLoggedIn} />
                </Grid>
                <Grid container direction='row' wrap='nowrap'>
                  <Drawer handleDrawerToggle={handleDrawerToggle} />
                  <Hidden xsDown>
                    <Grid item className={classes.leftMargin} />
                  </Hidden>
                  {loggedIn && <Grid item className={clsx(classes.content, !loggedIn && classes.hidden)}>
                    <Switch>
                      <Route exact path='/' component={General} />
                      <Route path='/companyLicenses' component={CompanyLicenses} />
                      <Route path='/licenses' component={Licenses} />
                      <Route path='/download' component={Download} />
                      <Route path='/admin' component={Administration} />
                      <Route component={NotFound} />
                    </Switch>
                  </Grid>}
                  {isReady
                    ? <LoginDialog open={!loggedIn} />
                    : <SetupWizard onDone={() => setReady(true)} />
                  }
                </Grid>
              </Grid>
            </Router>
          </SessionContext.Provider>
        </ThemeProvider>
      </CssBaseline>
    </div >
  );
};

export default App;
