import _ from 'lodash';

import { UserRole } from '../config/Config';
import ICompanyLicense, { ICompanyLicenseShort } from '../interfaces/ICompanyLicense';
import ILicense, { ILicenseType } from '../interfaces/ILicense';

export class Utils {
  /* Taken from https://emailregex.com */
  static EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  /* Version Range */
  static VERSION_RANGE_REGEX = /^(?:(?:\[|\()(?:\d+\.\d+\.\d+)?,(?:\d+\.\d+\.\d+)?(?:\]|\)))|(?:\d+\.\d+\.\d+)$/;

  static async messageFromResponse(response: Response) {
    let message = "Status: " + response.status + " " + response.statusText;
    try { message = (await response.json()).message ?? message; } catch { };
    return message;
  }

  static rolesToRole(roles: string[]): string {
    let role = '';
    if (_.includes(roles, UserRole.USER)) {
      role = 'user';
    }

    if (_.includes(roles, UserRole.COMPANY_ADMIN)) {
      role = 'companyAdmin';
    }

    if (_.includes(roles, UserRole.ADMIN)) {
      role = 'admin';
    }
    return role;
  }

  static isOK(status?: number): boolean {
    return status !== undefined && status >= 200 && status < 300;
  };

  static isNodeLockedCompanyLicense(companyLicense: ICompanyLicense | ICompanyLicenseShort): boolean {
    return companyLicense.type === "node-locked";
  }

  static isFloatedLicense(license: ILicense) {
    return license.licenseType === ILicenseType.FLOAT;
  }

  static isBorrowedLicense(license: ILicense) {
    return license.licenseType === ILicenseType.BORROW;
  }

  static isNodeLockedLicense(license: ILicense) {
    return license.licenseType === ILicenseType.NODELOCKED;
  }
}