import { Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import _ from 'lodash';
import React, { ChangeEvent, useEffect } from 'react';
import { useMemo, useState } from 'reactn';

import ProgressButton from '../components/ProgressButton';
import { LICENSE_CONFIGURATIONS, UPGRADE_COMPANY_LICENSE } from '../config/Config';
import useLicenseServers from '../hooks/LicenseServersHook';
import useLoginDialogRedirect from '../hooks/LoginDialogHook';
import { ICompanyLicenseShort } from '../interfaces/ICompanyLicense';
import ILicenseConfiguration from '../interfaces/ILicenseConfiguration';
import { Utils } from '../utils/Utils';

interface UpgradeCompanyLicenseDialogProps extends DialogProps {
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'done'): void;
  }['bivarianceHack'],
  companyLicense: ICompanyLicenseShort;
}

export const UpgradeLicenseDialog: React.FC<UpgradeCompanyLicenseDialogProps> = (props) => {
  const { companyLicense, ...dialogProps } = props;
  const { open, onClose } = props;

  const [licenseConfigurations, setLicenseConfigurations] = useState<ILicenseConfiguration[]>();
  const [currentLicenseConfiguration, setCurrentLicenseConfiguration] = useState<ILicenseConfiguration>();
  const [amount, setAmount] = useState(companyLicense.maxAmount);

  let firstAmount = companyLicense.maxAmount;

  const [firstLicenseConfiguration, setFirstLicenseConfiguration] = useState<ILicenseConfiguration>();

  const [saveRunning, setSaveRunning] = useState(false);
  const [error, setError] = useState('');

  const loginDialogRedirect = useLoginDialogRedirect();
  const [licenseServers,] = useLicenseServers();

  useEffect(() => {
    if (open) {
      setError('');

      setAmount(companyLicense.maxAmount);

      fetch(LICENSE_CONFIGURATIONS, {
        'method': 'GET',
        'credentials': 'same-origin'
      })
        .then(loginDialogRedirect)
        .then(async response => {
          if (!response.ok) {
            throw Error('Error retrieving license configuration data. ' + (await Utils.messageFromResponse(response)));
          } else {
            return response.json();
          }
        }).then((configs: ILicenseConfiguration[]) => {
          /* Set all available configurations */
          setLicenseConfigurations(configs);

          /* Identify the current assigned configuration */
          let conf = configs.filter(conf => conf.id === companyLicense?.licenseConfiguration?.id)[0];
          setCurrentLicenseConfiguration(conf);

          setFirstLicenseConfiguration(conf);
        });
    }
  }, [setFirstLicenseConfiguration, companyLicense, open, loginDialogRedirect]);

  let dataUnchanged = useMemo(() => _.isEqual(currentLicenseConfiguration?.id, firstLicenseConfiguration?.id) && _.isEqual(Number(amount), Number(firstAmount)),
    [firstLicenseConfiguration, currentLicenseConfiguration, firstAmount, amount]);

  let isUpgrading = useMemo(() => {
    let currentFeatureCount = (currentLicenseConfiguration?.features.length ?? 0);
    let firstFeatureCount = (firstLicenseConfiguration?.features.length ?? 0);

    return (
      currentFeatureCount >= firstFeatureCount
      && (currentFeatureCount !== firstFeatureCount || amount >= firstAmount)
    );
  }, [firstLicenseConfiguration, currentLicenseConfiguration, firstAmount, amount]);

  const handleChange = (event: ChangeEvent<{ value: unknown; }>) => {
    let filteredConfigs = licenseConfigurations?.filter(licenseConfig => licenseConfig.id === event.target.value);
    if (filteredConfigs?.length === 1) {
      setCurrentLicenseConfiguration(filteredConfigs[0]);
    }
  };

  const handleSave = () => {
    if (currentLicenseConfiguration && companyLicense) {
      setError('');
      setSaveRunning(true);

      fetch(UPGRADE_COMPANY_LICENSE, {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: 'serverUrl=' + encodeURIComponent(licenseServers.filter(server => server.available)[0].url)
          + '&companyLicenseId=' + encodeURIComponent(companyLicense.id)
          + '&licenseConfigurationId=' + encodeURIComponent(currentLicenseConfiguration.id)
          + '&amount=' + encodeURIComponent(amount)
      })
        .then(loginDialogRedirect)
        .then(async response => {
          if (!response.ok) {
            throw Error((await Utils.messageFromResponse(response)));
          } else {
            return response.json();
          }
        }).then(_ => {
          if (!error) {
            onClose?.({}, 'done');
          }
        }).catch(e => {
          setError(e.message);
        }).finally(() => {
          setSaveRunning(false);
        });
    }
  };

  return (
    <Dialog scroll={'paper'} fullWidth maxWidth='sm' {...dialogProps}>
      <DialogTitle>Upgrade Company License</DialogTitle>
      <DialogContent style={{ paddingBottom: '1em' }}>
        <DialogContentText>
          Please choose the Arttest version you want to upgrade or downgrade to. Keep in mind that changes done here will apply instantly. For billing we use the information you provided when first buying Arttest.
        </DialogContentText>
        <Collapse in={!!error} style={{ 'marginBottom': '10px' }}>
          <Typography variant='body1' color='error'>{error}{'\u00a0'}</Typography>
        </Collapse>

        <Grid container style={{ marginTop: '1em' }} alignItems='baseline' spacing={3}>
          <Grid item xs={12} sm={7}>
            <FormControl fullWidth>
              <InputLabel>Previous License Configuration</InputLabel>
              <Select value={'firstLicenseConfiguration'} disabled>
                <MenuItem value={'firstLicenseConfiguration'}>{firstLicenseConfiguration?.name}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <TextField label='Previous Amount' type='number' value={firstAmount} disabled />
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: '1em' }} alignItems='baseline' spacing={3}>
          <Grid item xs={12} sm={7}>
            <FormControl fullWidth>
              <InputLabel>License Configuration</InputLabel>
              <Select
                value={currentLicenseConfiguration?.id || ''}
                onChange={handleChange}
              >
                {licenseConfigurations?.map(licenseConfig =>
                  <MenuItem key={licenseConfig.id} value={licenseConfig.id}>{licenseConfig.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <TextField
              label='Amount'
              type='number'
              value={amount}
              onChange={(event: any) => { setAmount(event.target.value); }}
              inputProps={{ max: currentLicenseConfiguration?.numberOfLicenses, min: 1 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ProgressButton showProgress={saveRunning} variant='outlined' color='primary' disabled={dataUnchanged} onClick={() => { handleSave(); }}>{isUpgrading ? 'Upgrade' : 'Downgrade'}</ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeLicenseDialog;