import { Collapse, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Input, InputLabel, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import React, { useCallback, useEffect, useGlobal, useRef, useState } from 'reactn';

import DownloadProgressBar from '../components/DownloadProgressBar';
import ProgressButton from '../components/ProgressButton';
import { DOWNLOAD_ARTTEST, UPLOAD_ARTTEST } from '../config/Config';
import useDownloadState from '../hooks/DownloadStateHook';

interface DownloadArttestDialogTitleProps extends DialogProps {
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'done') => void;
}

type AddArttestReleaseMethod = 'Download' | 'Upload';

export const DownloadArttestDialog: React.FC<DownloadArttestDialogTitleProps> = (props) => {
  const [error, setError] = useState('');
  const [productName,] = useGlobal('productName');
  const [downloadLink, setDownloadLink] = useState('');
  const [isDownloading, setDownloading] = useState(false);
  const [downloadState, dispatchDownloadState] = useDownloadState('/backend/downloadProgress', props.open);
  const fileUpload = useRef<HTMLInputElement>();
  const [uploadRequest, setUploadRequest] = useState<XMLHttpRequest>();

  const [addMethod, setAddMethod] = useState<AddArttestReleaseMethod>('Download');

  const { onClose, ...dialogProps } = props;

  let close = useCallback((reason: 'backdropClick' | 'escapeKeyDown' | 'done') => {
    if (onClose) { onClose({}, reason); }
  }, [onClose]);

  useEffect(() => {
    setDownloading(downloadState.downloadRunning);
    if (downloadState.error) { setError(downloadState.error); }
    if (downloadState.done) { close('done'); }
  }, [downloadState, close]);

  async function cancelArttestDownload() {
    if (uploadRequest) {
      uploadRequest.abort();
      setUploadRequest(undefined);
    }
    await fetch(DOWNLOAD_ARTTEST, { method: 'DELETE', credentials: 'same-origin' });
    setDownloading(false);
    dispatchDownloadState({ type: 'abort' });
  }

  function addArttestRelease() {
    if (addMethod === 'Download') { startDownload(); }
    else { startUpload(); }
  }

  function startUpload() {
    setDownloading(true);
    setError('');

    let files = fileUpload.current?.files || [];
    if (!files.length) {
      dispatchDownloadState({ type: 'error', error: 'Please select a file' });
    } else {
      dispatchDownloadState({ type: 'start' });
      new Promise((res, rej) => {
        let formData = new FormData();
        formData.append("file", files[0]);

        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
          try { if (xhr.readyState === XMLHttpRequest.DONE && xhr.status !== 0) { res(JSON.parse(xhr.responseText)); } } catch (err) { rej(err); }
        };
        xhr.onerror = rej;
        xhr.upload.onprogress = uploadProgress;
        xhr.open('POST', UPLOAD_ARTTEST, true);
        xhr.send(formData);
        setUploadRequest(xhr);
      })
        .then((data: any) => {
          if (!data.success) {
            throw new Error(data.message ? data.message : 'Could not start the download process.');
          }
        })
        .catch(error => {
          dispatchDownloadState({ type: 'error', error: error.message });
        });
    }
  }

  function uploadProgress(event: any) {
    console.log(event);
    if (event.lengthComputable) {
      dispatchDownloadState({ type: 'progress', percent: event.loaded / event.total, size: { total: event.total, transferred: event.loaded } });
    } else {
      dispatchDownloadState({ type: 'progress' });
    }
    // event.loaded / event.total * 100 ; //event.lengthComputable
  }

  function startDownload() {
    setDownloading(true);
    setError('');

    fetch(DOWNLOAD_ARTTEST, {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
      body: 'url=' + encodeURIComponent(downloadLink)
    })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          throw new Error(data.message ? data.message : 'Could not start the download process.');
        }
      })
      .catch(error => {
        dispatchDownloadState({ type: 'error', error: error.message });
      });
  }
  return <Dialog fullWidth onClose={(e, reason) => { if (!isDownloading) { close(reason); } }} {...dialogProps}>
    <DialogTitle>Add Release</DialogTitle>
    <DialogContent>
      <Collapse in={!!error}><Typography color='error'>{error}{'\u00a0'}</Typography></Collapse>

      <Typography variant='body1'>
        Please choose the method to add an arttest release and then fill in the required information.
      </Typography>

      <RadioGroup name="addMethod" value={addMethod} onChange={e => setAddMethod(e.target.value as AddArttestReleaseMethod)}>
        <FormControlLabel disabled={downloadState.downloadRunning} control={<Radio value="Download" color='primary' />} label="Download From URL" />
        <TextField
          fullWidth
          autoFocus
          placeholder={`${productName} Download Link`}
          disabled={downloadState.downloadRunning || addMethod !== 'Download'}
          value={downloadLink}
          onChange={(event) => { setDownloadLink(event.target.value); }}
          onKeyPress={(event) => { if (event.charCode === 13) { startDownload(); } }}
        />

        <Divider />

        <FormControlLabel style={{ marginTop: '1em' }} disabled={downloadState.downloadRunning} control={<Radio value="Upload" color='primary' />} label="Upload Archive" />
        <FormControl fullWidth>
          <InputLabel
            disableAnimation={true}
            shrink={true}
            htmlFor="archiveFile">{productName} Archive</InputLabel>
          <Input
            id="archiveFile"
            inputRef={fileUpload}
            type="file"
            autoFocus
            disabled={downloadState.downloadRunning || addMethod !== 'Upload'}
          />
        </FormControl>
      </RadioGroup>
      <DownloadProgressBar isUpload={addMethod === 'Upload'} downloadState={downloadState} onCancel={() => cancelArttestDownload()} />
    </DialogContent>
    <DialogActions>
      <ProgressButton showProgress={isDownloading} variant='outlined' color='primary' onClick={() => { addArttestRelease(); }}>{addMethod}</ProgressButton>
    </DialogActions>
  </Dialog>;
};

export default DownloadArttestDialog;
