export const MarkdownStyle = {
  //backgroundColor: 'red',
  '& h1': { fontSize: '1.2em' },
  '& h2': { fontSize: '1.1em' },
  '& h3': { fontSize: '1.0em' },
  '& h4': { fontSize: '0.9em' },
  '& h5': { fontSize: '0.8em' },
  '& h6': { fontSize: '0.7em' },
  '& h1, & h2, & h3, & h4, & h5, & h6': { fontWeight: 500, marginTop: '1em', marginBottom: '0.5em' },
  '& ul': { marginTop: '0.5em', marginBottom: '0.5em' },
  '& hr': { height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.12)', border: 'none' }
};

export default MarkdownStyle;