import { Collapse, createStyles, Grid, IconButton, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';

import { IDownloadState } from '../hooks/DownloadStateHook';

interface IDownloadProgressBarProps {
  downloadState: IDownloadState,
  isUpload?: boolean,
  onCancel?: () => void;
}

let useStyles = makeStyles(theme => createStyles({
  progress: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
}));

function toReadableTime(seconds: number): string {
  seconds = Math.round(seconds);
  let string = '';
  if (seconds > 60 * 60) {
    let hours = Math.floor(seconds / (60 * 60));
    seconds -= 60 * 60 * hours;
    string += hours + ':';
  }
  return (string + Math.floor(seconds / 60)).padStart(2, '0') + ':' + ("" + (seconds % 60)).padStart(2, '0');
}

function toReadableSize(bytes: number): string {
  if (bytes < 1024) return bytes.toFixed(2) + " B"; bytes /= 1024;
  if (bytes < 1024) return bytes.toFixed(2) + " KB"; bytes /= 1024;
  if (bytes < 1024) return bytes.toFixed(2) + " MB"; bytes /= 1024;
  if (bytes < 1024) return bytes.toFixed(2) + " GB"; bytes /= 1024;
  return bytes.toFixed(2) + " TB";
}

const DownloadProgressBar: React.FC<IDownloadProgressBarProps> = (props) => {
  const classes = useStyles();

  return (
    <Collapse in={props.downloadState.downloadRunning}>
      <Grid container direction='row' alignItems='flex-start'>
        <Grid item style={{ flexGrow: 1 }}>
          <Grid container direction='column' alignItems='flex-start' >
            <Grid item style={{ width: '100%', marginTop: '4px' }}><LinearProgress className={classes.progress} variant={props.downloadState.extracting !== undefined ? 'indeterminate' : 'determinate'} color='primary' value={(props.downloadState.percent || 0) * 100} /></Grid>
            <Typography variant='caption' style={{ marginTop: '-0.8em' }}>
              {props.downloadState.extracting !== undefined
                ? `Extracting ${props.downloadState.extracting}...`
                : <>
                  {props.downloadState.size && <>{props.isUpload ? 'Uploading' : 'Downloading'} {toReadableSize(props.downloadState.size.transferred || 0)} / {toReadableSize(props.downloadState.size.total || 0)} ({((props.downloadState.percent || 0) * 100).toFixed(0)}%)</>}
                  {props.downloadState.speed && <> at {toReadableSize(props.downloadState.speed || 0)}/s</>}
                  {props.downloadState.time && props.downloadState.time.remaining !== null && <> - {toReadableTime(props.downloadState.time.remaining || 0)} remaining</>}
                </>
              }
            </Typography>
          </Grid>
        </Grid>
        {props.onCancel && <Grid item style={{ marginLeft: '0.5em' }}><IconButton size='small' onClick={() => props.onCancel!()}><ClearIcon fontSize='small' /></IconButton></Grid>}
      </Grid>
    </Collapse>
  );
};

export default DownloadProgressBar;