import { createStyles, Grid, Theme } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/WarningRounded';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import HTMLTooltip from './HTMLTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warning: {
      color: orange[500],
      marginRight: '0.1em'
    },
    tooltipWidth: {
      maxWidth: 500
    }
  })
);

export interface IMergeFieldDiff {
  [server: string]: string;
}

interface IMergeFieldProps {
  data: string,
  diff: IMergeFieldDiff;
}

const MergeField: React.FC<IMergeFieldProps> = (props) => {
  const classes = useStyles();

  function createTooltipMessage(diff: IMergeFieldDiff) {
    return <>
      {Object.keys(diff).map(server =>
        <div key={'merge_' + server}>
          {'On server [' + server + '] the field has the value: ' + (diff[server] ? diff[server] : '{empty}')}
        </div>
      )}
    </>;
  }

  return (
    <Grid container direction='row' alignItems='center'>
      <Grid item>{Object.keys(props.diff).length !== 0 ? <HTMLTooltip title={createTooltipMessage(props.diff)} classes={{ tooltip: classes.tooltipWidth }}><WarningIcon className={classes.warning} /></HTMLTooltip> : <> </>}</Grid>
      <Grid item>{props.data}</Grid>
    </Grid>
  );
};

export default MergeField;