import { Button, ButtonGroup, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';

import { SessionContext } from '../App';
import { PALETTE } from '../config/Config';
import useLoginDialogRedirect from '../hooks/LoginDialogHook';
import { ISessionContext } from '../interfaces/ISessionContext';
import { Utils } from '../utils/Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      padding: theme.spacing(2)
    },
    plot: {
      position: 'relative',
      width: '99%',
      padding: theme.spacing(3),
      textAlign: 'center'
    },
    error: {
      marginTop: theme.spacing(2),
      color: 'red'
    },
    hidden: {
      display: 'none'
    }
  })
);

interface IPlotData {
  [key: string]: number;
}

interface GraphViewDefinition {
  id: string,
  displayName: string;
}

interface IGraphProps {
  title: string,
  description?: string,
  views?: GraphViewDefinition[],
  chartJSType: 'line' | 'doughnut';
  fetchUrl: string | ((viewId: string) => string),
  transformToData: (data: any) => number[],
  transformToLabels: (data: any) => string[];
}

const Graph: React.FC<IGraphProps> = (props: IGraphProps) => {
  if (!props.views?.length && (typeof props.fetchUrl !== "string")) { throw new Error("Invalid graph specification"); }

  /* Styling */
  const classes = useStyles({});

  /* Use the provided context to read the access token */
  const session = useContext<ISessionContext>(SessionContext);

  const [selectedView, setSelectedView] = React.useState(props.views?.[0]?.id);

  /* Statistics */
  const [plotVisible, setPlotVisible] = React.useState(false);
  const [plotLabels, setPlotLabels] = React.useState<string[]>([]);
  const [plotData, setPlotData] = React.useState<number[]>([]);

  /* Error */
  const [error, setError] = React.useState('');

  /* Redirection if not logged in */
  const loginDialogRedirect = useLoginDialogRedirect();

  /* Extract props to avoid endless reloading */
  const { fetchUrl, transformToLabels, transformToData } = props;

  const plotDataObject = {
    labels: plotLabels,
    datasets: [
      {
        data: plotData,
        backgroundColor: props.chartJSType === 'line' ? 'rgba(0, 7, 34, 0.2)' : PALETTE,
        borderColor: props.chartJSType === 'line' ? 'rgba(0, 7, 34, 1)' : undefined,
        borderWidth: 1
      }
    ]
  };

  const plotDataOptions = {
    legend: {
      display: props.chartJSType === 'doughnut',
      align: 'center',
      position: 'right'
    },
    scales:
      props.chartJSType === 'line'
        ? { yAxes: [{ ticks: { precision: 0 } }] }
        : {}
  };

  /* Fetch the data */
  useEffect(() => {
    fetch((typeof fetchUrl === "string") ? fetchUrl : fetchUrl(selectedView!), {
      method: 'GET',
      credentials: 'same-origin'
    })
      .then(loginDialogRedirect)
      .then(async response => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error('Error retrieving statistics. ' + (await Utils.messageFromResponse(response)));
        }
      }).then(data => {
        setPlotLabels(transformToLabels(data));
        setPlotData(transformToData(data));
        setPlotVisible(true);
      }).catch(exception => {
        setError('Could not fetch statistics from the license server(s). Status: ' + exception.status);
        setPlotVisible(false);
      });
  }, [session.isLoggedIn, loginDialogRedirect, fetchUrl, selectedView, transformToData, transformToLabels]);

  return (
    <Grid container component={Paper} direction='column' justify='space-between' style={{ height: '100%' }}>
      <Grid item className={classes.text}>
        <Typography variant='h4'>
          {props.title}
        </Typography>
        <Typography variant='body1'>
          {props.description}
        </Typography>
        <Typography variant='body1'>
          {error}
        </Typography>
      </Grid>
      <Grid item className={classes.plot}>
        {props.views?.length && <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
          {props.views?.map(view => {
            return <Button key={view.id} variant={selectedView === view.id ? "contained" : "outlined"} color="primary" onClick={() => setSelectedView(view.id)}>{view.displayName}</Button>;
          })}
        </ButtonGroup>}
        {plotVisible && plotData &&
          props.chartJSType === 'line' ? <Line data={plotDataObject} options={plotDataOptions} /> : (
            props.chartJSType === 'doughnut' ? <Doughnut data={plotDataObject} options={plotDataOptions} /> : <></>)
        }
      </Grid>
    </Grid>
  );
};

export default Graph;