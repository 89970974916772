import { Button, createStyles, Grid, IconButton, makeStyles, Paper, Switch, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Theme, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { amber, orange } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import BuildIcon from '@material-ui/icons/Build';
import BusinessIcon from '@material-ui/icons/Business';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/WarningRounded';
import clsx from 'clsx';
import _ from 'lodash';
import React, { Fragment, useContext, useEffect, useGlobal, useState } from 'reactn';

import { SessionContext } from '../App';
import HTMLTooltip from '../components/HTMLTooltip';
import RestrictedElement from '../components/RestrictedElement';
import RestrictedPage from '../components/RestrictedPage';
import { COMPANIES, COMPANYLICENSES, FEATURE_SETS, LSCONFIG, UserRole } from '../config/Config';
import ActivationCodesDialog from '../dialogs/ActivationCodesDialog';
import AddCompanyLicenseDialog from '../dialogs/AddCompanyLicenseDialog';
import AddOrEditCompanyDialog from '../dialogs/AddOrEditCompanyDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import EditCompanyLicenseDialog from '../dialogs/EditCompanyLicenseDialog';
import RemoveCompanyDialog from '../dialogs/RemoveCompanyDialog';
import ResolveCompanyConflictDialog from '../dialogs/ResolveCompanyConflictDialog';
import UpgradeLicenseDialog from '../dialogs/UpgradeLicenseDialog';
import useForceUpdate from '../hooks/ForceUpdateHook';
import useLicenseServers from '../hooks/LicenseServersHook';
import useLicenseStatus from '../hooks/LicenseStatusHook';
import useLoginDialogRedirect from '../hooks/LoginDialogHook';
import useRestrictedMode from '../hooks/RestrictedModeHook';
import { IMergedCompany } from '../interfaces/ICompany';
import { ICompanyLicenseShort } from '../interfaces/ICompanyLicense';
import IFeatureSet from '../interfaces/IFeatureSet';
import ILicenseServerConfiguration from '../interfaces/ILicenseServerConfiguration';
import IMultiServerResponse from '../interfaces/IMultiServerResponse';
import { ISessionContext } from '../interfaces/ISessionContext';
import { Utils } from '../utils/Utils';

const SHOW_ENABLED_SWITCH = false;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootPaper: {
      display: 'flex',
      flex: 1
    },
    topTextGrid: {
      padding: theme.spacing(2),
    },
    buttonGrid: {
      alignSelf: 'flex-end',
      padding: theme.spacing(2),
      paddingRight: theme.spacing(4)
    },
    progress: {
      alignSelf: 'center',
      margin: theme.spacing(2)
    },
    withoutBorderBottom: {
      borderBottom: '0px'
    },
    hidden: { display: 'none' },
    notVisible: { visibility: 'hidden' },
    error: {
      marginTop: theme.spacing(2),
      color: 'red'
    },
    tableRow: {
      '& td': {
        borderBottom: '0px'
      }
    },
    buttonRow: {
      '& td': {
        borderBottom: '0px'
      }
    },
    tooltipWidth: {
      maxWidth: 500
    },
    indentCell: {
      paddingLeft: '4em'
    },
    conflict: {
      background: amber[100] + "!important",
      '&:hover': {
        background: amber[200] + "!important"
      }
    },
    companyDisabled: {
      '& td:not(.actions), & td:not(.actions) *': { color: theme.palette.text.disabled + '!important' }
    }
  })
);

function dateText(dateString: string) {
  if (!dateString) { return 'Never'; }
  try {
    if (dateString < new Date().toISOString().substr(0, 10)) {
      return <Typography color='error' variant="body2">{dateString} (expired)</Typography>;
    }
  } catch { }
  return dateString;
}

const CompanyLicenses: React.FC = () => {
  const classes = useStyles();

  const session = useContext<ISessionContext>(SessionContext);
  const [productName,] = useGlobal('productName');

  const [featureSets, setFeatureSets] = useState<IMultiServerResponse<IFeatureSet>>();
  const [companies, setCompanies] = useState<IMergedCompany[]>();
  const [error, setError] = useState('');
  const [licenseServers,] = useLicenseServers();
  const [licenseStatus, triggerLicenseStatusUpdate] = useLicenseStatus();
  const restrictedMode = useRestrictedMode();

  const [update, forceUpdate] = useForceUpdate();

  const [addOrEditCompanyDialogOpen, setAddOrEditCompanyDialogOpen] = useState(false);
  const [lsConfigs, setLsConfigs] = useState<IMultiServerResponse<ILicenseServerConfiguration>>();

  const [selectedCompany, setSelectedCompany] = useState<IMergedCompany | undefined>();
  const [removeCompanyDialogOpen, setRemoveCompanyDialogOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const loginDialogRedirect = useLoginDialogRedirect();
  const [selectedServer, setSelectedServer] = useState('');

  const [addCompanyLicenseDialogOpen, setAddCompanyLicenseDialogOpen] = useState(false);
  const [conflictResolveDialogOpen, setConflictResolveDialogOpen] = useState(false);
  const [upgradeCompanyLicenseDialogOpen, setUpdateCompanyLicenseDialogOpen] = useState(false);

  const [selectedCompanyLicense, setSelectedCompanyLicense] = useState<ICompanyLicenseShort>();
  const [editCompanyLicenseDialogOpen, setEditCompanyLicenseDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const [showActivationCodesDialogOpen, setShowActivationCodesDialogOpen] = useState(false);
  const [generatedCompanyLicense, setGeneratedCompanyLicense] = useState<{ serverUrl: string, companyLicenseId: number; } | undefined>();

  const canEditCompanyLicenses = session.roles?.includes(UserRole.ADMIN);

  const visible = companies;

  useEffect(() => {
    fetch(COMPANIES + '?merged=true', {
      method: 'GET',
      credentials: 'same-origin'
    })
      .then(loginDialogRedirect)
      .then(async response => {
        if (!response.ok) {
          throw Error('Error retrieving company data. ' + (await Utils.messageFromResponse(response)));
        } else {
          return response.json();
        }
      }).then(data => {
        setCompanies(data?.result);
      }).catch(error => {
        setError(error.message);
      });

    if (session.roles?.includes(UserRole.ADMIN) || session.roles?.includes(UserRole.COMPANY_ADMIN)) {
      fetch(LSCONFIG, {
        method: 'GET',
        credentials: 'same-origin'
      }).then(async response => {
        if (!response.ok) {
          throw Error('Error retrieving LSConfigs. ' + (await Utils.messageFromResponse(response)));
        } else {
          return response.json();
        }
      })
        .then(loginDialogRedirect)
        .then(data => setLsConfigs(data))
        .catch(error => setError(error.message));
    }

  }, [session.roles, session.isLoggedIn, update, loginDialogRedirect]);

  useEffect(() => {
    fetch(FEATURE_SETS, {
      method: 'GET',
      credentials: 'same-origin'
    }).then(async response => {
      if (!response.ok) {
        throw Error('Error retrieving feature set data. ' + (await Utils.messageFromResponse(response)));
      } else {
        return response.json();
      }
    }).then(data => {
      setFeatureSets(data);
    }).catch(error => {
      setError(error.message);
    });
  }, [session.isLoggedIn]);

  useEffect(() => triggerLicenseStatusUpdate(), [triggerLicenseStatusUpdate, update]);

  function deleteCompanyLicense() {
    if (selectedCompanyLicense) {
      return fetch(COMPANYLICENSES + '/' + (selectedCompanyLicense.id + '?serverUrl=' + encodeURIComponent(selectedServer)), { method: 'DELETE', credentials: 'same-origin' }).then(async (response) => {
        if (!response.ok) { return Promise.reject((await response.json()).message); }
        return response;
      });
    } else {
      return Promise.resolve();
    }
  }

  function addCompanyLicense(company: IMergedCompany) {
    setSelectedCompany(company);
    setAddCompanyLicenseDialogOpen(true);
  }
  function resolveConflict(company: IMergedCompany) {
    setSelectedCompany(company);
    setConflictResolveDialogOpen(true);
  }

  function removeCompany(company: IMergedCompany) {
    setSelectedCompany(company);
    setRemoveCompanyDialogOpen(true);
  }

  function addOrEditCompany(company?: IMergedCompany) {
    setSelectedCompany(company);
    setAddOrEditCompanyDialogOpen(true);
  }

  function getInconsistencyErrorMessage(company: IMergedCompany): string | undefined {
    let missingServers = _.difference(licenseServers?.filter(s => s.available && Utils.isOK(s.status))?.map(server => server.url), Object.keys(company.idMapping));
    if (missingServers.length > 0) {
      return 'Company does not exist on ' + missingServers.join(' and ');
    }

    /* Everything is fine */
    return undefined;
  }

  function getCompatibilityError(serverUrl: string, companyLicense: ICompanyLicenseShort) {
    let errors = licenseStatus?.[serverUrl]?.response?.errors?.filter(error => error.affectedCompanyLicenses?.includes(companyLicense.id));

    return !errors?.length ? undefined : <>{errors.map(error => <Fragment key={"error_" + serverUrl + "_" + companyLicense.id}>
      {error.message && <Typography>{error.message}</Typography>}
      {error.details || ""}
    </Fragment>)
    }</>;
  }

  function setCompanyEnabled(company: IMergedCompany, enabled: boolean) {
    fetch(COMPANIES, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body:
        'companyIds=' + encodeURIComponent(JSON.stringify(company.idMapping))
        + '&enabled=' + encodeURIComponent(enabled)
    })
      .then(() => forceUpdate())
      .catch(() => { });
  }

  const availableLicenseServers = licenseServers?.filter(s => s.available) ?? [];
  const showCompanies = session.roles?.includes(UserRole.ADMIN) && (!session.selfHosted || (companies?.length ?? 0) > 1);
  function getCompanyLicense(serverUrl: string, companyLicenseId: number) {
    if (!companies) { return undefined; }
    for (let company of companies) {
      let companyLicenses = company.companyLicenses[serverUrl]?.filter(cl => cl.id === companyLicenseId);
      if (companyLicenses.length > 0) {
        return companyLicenses[0];
      }
    }
    return undefined;
  }

  return (
    <RestrictedPage allowedRoles={[UserRole.ADMIN, UserRole.COMPANY_ADMIN]}>
      {selectedCompany && <ResolveCompanyConflictDialog open={conflictResolveDialogOpen} onClose={(_, reason) => { setConflictResolveDialogOpen(false); if (reason === 'done') { forceUpdate(); } }} conflictedData={selectedCompany} fullWidth maxWidth='md' />}
      <Paper className={classes.rootPaper}>
        <Grid container direction='column' justify='flex-start' alignItems='stretch'>
          <Grid item container direction='row' justify='space-between' alignItems='center' className={classes.topTextGrid}>
            <Typography variant='h3' style={{ flexGrow: 1 }}>
              Company Licenses
            </Typography>
            {(showCompanies && !session.selfHosted)
              ? <Grid item><Button variant='outlined' onClick={() => addOrEditCompany()} disabled={restrictedMode}><AddIcon /> Add Company</Button></Grid>
              : (((companies?.length ?? 0) === 1 && canEditCompanyLicenses) &&
                <Grid item><Button variant='outlined' onClick={() => addCompanyLicense(companies![0])} disabled={restrictedMode}><AddIcon /> Add Company License</Button></Grid>)
            }
          </Grid>
          <Typography variant='body1' className={clsx((error === '' || companies) && classes.hidden, classes.error)}>
            {error}
          </Typography>
          <Grid item className={clsx(classes.progress, (visible || error) && classes.hidden)}>
            <CircularProgress />
          </Grid>
          <Grid item>
            <Table size={showCompanies ? "small" : undefined} className={clsx(!visible && classes.hidden)}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  {!session.selfHosted && <TableCell>Type</TableCell>}
                  <TableCell>Allocated Licenses</TableCell>
                  {!session.selfHosted && <TableCell>Expiration Date</TableCell>}
                  {!session.selfHosted && <TableCell>End of Support</TableCell>}
                  <RestrictedElement allowedRoles={[UserRole.ADMIN]} allowedInSelfHosting>
                    <TableCell style={{ width: '10px' }} />
                  </RestrictedElement>
                  <RestrictedElement allowedRoles={[UserRole.USER, UserRole.COMPANY_ADMIN]} requireExactRoleMatch allowedInSelfHosting>
                    <TableCell style={{ width: '10px' }} />
                  </RestrictedElement>
                </TableRow>
              </TableHead>
              <TableBody>
                {companies?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((company, index) => {
                  let errorMessage = getInconsistencyErrorMessage(company);
                  let isMyCompany = company.name === session.company?.name;

                  return <Fragment key={'company_' + index}>
                    {showCompanies &&
                      <TableRow selected hover className={clsx(errorMessage && classes.conflict, !company.enabled && classes.companyDisabled)}>
                        <TableCell align='left' colSpan={session.selfHosted ? 2 : 5}>
                          <Grid container alignItems='center'>
                            {errorMessage && <Grid item><HTMLTooltip title={errorMessage} classes={{ tooltip: classes.tooltipWidth }}><WarningIcon style={{ color: orange[500], marginRight: '0.2em' }} /></HTMLTooltip></Grid>}
                            <Grid item><BusinessIcon style={{ marginRight: '0.2em' }} /></Grid>
                            <Grid item>
                              {company.name}
                              {company.buildIdentifier !== 'Default' ? <Typography variant="body2" display="inline" color="textSecondary">{` (Build Identifier: ${company.buildIdentifier})`}</Typography> : ''}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <RestrictedElement allowedRoles={[UserRole.ADMIN]} allowedInSelfHosting>
                          <TableCell align='center' size='small' className='actions' style={{ whiteSpace: 'nowrap' }}>
                            {SHOW_ENABLED_SWITCH && <Switch size='small' color='primary' checked={company.enabled} onChange={e => { setCompanyEnabled(company, e.target.checked); }} disabled={restrictedMode || isMyCompany} style={{ visibility: !!errorMessage ? 'hidden' : 'visible' }} />}
                            {canEditCompanyLicenses && <IconButton size='small' onClick={() => { addCompanyLicense(company); }} disabled={restrictedMode} style={{ visibility: !!errorMessage ? 'hidden' : 'visible' }}><AddIcon /></IconButton>}
                            {errorMessage
                              ? <IconButton size='small' disabled={restrictedMode} onClick={() => { resolveConflict(company); }}><BuildIcon /></IconButton>
                              : <IconButton size='small' disabled={restrictedMode} onClick={() => { addOrEditCompany(company); }}><EditIcon /></IconButton>}
                            <IconButton size='small' disabled={restrictedMode || isMyCompany} onClick={() => { removeCompany(company); }}><DeleteIcon /></IconButton>
                          </TableCell>
                        </RestrictedElement>
                      </TableRow>}
                    {availableLicenseServers.map(server => <Fragment key={server.url}>
                      {company.companyLicenses[server.url]?.map(companyLicense => {
                        let compatibilityError = getCompatibilityError(server.url, companyLicense);
                        return <TableRow key={'companylicense_' + companyLicense.id} hover className={clsx(!company.enabled && classes.companyDisabled)}>
                          <TableCell align='left' className={clsx(showCompanies && classes.indentCell)}>
                            <Grid container alignItems='center'>
                              {!companyLicense.licenseConfiguration && <Grid item><HTMLTooltip title={'License configuration not found or invalid'} classes={{ tooltip: classes.tooltipWidth }}><WarningIcon style={{ color: orange[500] }} /></HTMLTooltip></Grid>}
                              <Grid item>
                                {companyLicense.name || ''}
                                {companyLicense.licenseConfiguration?.name === companyLicense.name
                                  ? ''
                                  : (!companyLicense.name ? (companyLicense.licenseConfiguration?.name ?? '') : <Typography variant='body2' display='inline' color='textSecondary'>{` (${companyLicense.licenseConfiguration?.name ?? 'Not assigned correctly'})`}</Typography>)
                                }
                                {availableLicenseServers.length > 1 && <Typography variant='body2' color='textSecondary'>{server.url}</Typography>}
                              </Grid>
                            </Grid>
                          </TableCell>
                          {!session.selfHosted && <TableCell>
                            {companyLicense.type === 'dynamic' ? "Dynamic" : (companyLicense.type === "node-locked" ? "Node-Locked" : "Unknown")}
                          </TableCell>}
                          <TableCell align='left'>
                            <Grid container alignItems='center' direction='row'>
                              <Grid item>{!!compatibilityError && <HTMLTooltip title={compatibilityError} classes={{ tooltip: classes.tooltipWidth }}><WarningIcon style={{ color: orange[500], marginRight: '0.2em' }} /></HTMLTooltip>}</Grid>
                              {companyLicense.type === 'dynamic' ? <Grid item>{companyLicense.maxAmount}</Grid> : (companyLicense.type === "node-locked" ? <Grid item>{companyLicense.activationList.filter(e => e.activated).length}/{companyLicense.maxAmount} </Grid> : "Unknown")}
                              <Grid item>
                                {!session.selfHosted && Utils.isNodeLockedCompanyLicense(companyLicense) &&
                                  < IconButton size='small' onClick={() => { setGeneratedCompanyLicense({ serverUrl: server.url, companyLicenseId: companyLicense.id }); setShowActivationCodesDialogOpen(true); }}>
                                    <SearchIcon />
                                  </IconButton>
                                }
                              </Grid>
                            </Grid>
                          </TableCell>
                          {!session.selfHosted && <TableCell align='left'>{dateText(companyLicense.expiration)}</TableCell>}
                          {!session.selfHosted && <TableCell align='left'>{dateText(companyLicense.endOfSupport)}</TableCell>}
                          <RestrictedElement allowedRoles={[UserRole.ADMIN]} allowedInSelfHosting>
                            <TableCell align='center' size='small' className='actions' style={{ whiteSpace: 'nowrap' }}>
                              {SHOW_ENABLED_SWITCH && <Switch className={classes.notVisible} size='small' />}
                              {canEditCompanyLicenses && <IconButton className={classes.notVisible} size='small'><AddIcon /></IconButton>}
                              <IconButton size='small' disabled={restrictedMode} onClick={() => { setSelectedServer(server.url); setSelectedCompany(company); setSelectedCompanyLicense(companyLicense); setEditCompanyLicenseDialogOpen(true); }}><EditIcon /></IconButton>
                              <IconButton size='small' disabled={restrictedMode} onClick={() => { setSelectedServer(server.url); setSelectedCompanyLicense(companyLicense); setConfirmationDialogOpen(true); }}><DeleteIcon /></IconButton>
                            </TableCell>
                          </RestrictedElement>
                          <RestrictedElement allowedRoles={[UserRole.USER, UserRole.COMPANY_ADMIN]} requireExactRoleMatch allowedInSelfHosting>
                            <TableCell align='center' size='small' className='actions' style={{ whiteSpace: 'nowrap' }}>
                              {/* <IconButton size='small' disabled={restrictedMode} onClick={() => { setSelectedServer(server.url); setSelectedCompanyLicense(companyLicense); setUpdateCompanyLicenseDialogOpen(true); }}><ArrowUpwardIcon /></IconButton> */}
                              {canEditCompanyLicenses && <IconButton size='small' disabled={restrictedMode} onClick={() => { setSelectedServer(server.url); setSelectedCompanyLicense(companyLicense); setConfirmationDialogOpen(true); }}><DeleteIcon /></IconButton>}
                            </TableCell>
                          </RestrictedElement>
                        </TableRow>;
                      })}
                    </Fragment>
                    )}
                  </Fragment>;
                }
                )}
              </TableBody>
              {selectedCompany && removeCompanyDialogOpen && <RemoveCompanyDialog open={removeCompanyDialogOpen} company={selectedCompany} onClose={(_, reason) => { setRemoveCompanyDialogOpen(false); if (reason === 'done') { forceUpdate(); } }} />}
            </Table>
            {!session.selfHosted && session.roles?.includes(UserRole.ADMIN) &&
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component='div'
                count={companies?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(_, page) => { setPage(page); }}
                onChangeRowsPerPage={event => { setPage(Math.floor(page * rowsPerPage / parseInt(event.target.value))); setRowsPerPage(parseInt(event.target.value)); }}
              />}
          </Grid>
          <AddOrEditCompanyDialog open={addOrEditCompanyDialogOpen} company={selectedCompany} onClose={(_, reason) => { setAddOrEditCompanyDialogOpen(false); if (reason === 'done') { forceUpdate(); } }} />
          {lsConfigs && featureSets && selectedCompany && canEditCompanyLicenses && <AddCompanyLicenseDialog
            open={addCompanyLicenseDialogOpen}
            onClose={(_, reason) => { setAddCompanyLicenseDialogOpen(false); if (reason === 'done') { forceUpdate(); } if (generatedCompanyLicense) { setShowActivationCodesDialogOpen(true); } }}
            lsConfigs={lsConfigs}
            featureSets={featureSets}
            company={selectedCompany}
            onGeneratedCompanyLicense={(serverUrl, companyLicense) => setGeneratedCompanyLicense({ serverUrl, companyLicenseId: companyLicense.id })}
          />}
          {lsConfigs && selectedCompany && selectedCompanyLicense && <EditCompanyLicenseDialog
            open={editCompanyLicenseDialogOpen}
            onClose={(_, reason) => { setEditCompanyLicenseDialogOpen(false); if (reason === 'done') { forceUpdate(); } }}
            serverUrl={selectedServer}
            lsConfig={lsConfigs[selectedServer].response.results[0]}
            featureSets={featureSets?.[selectedServer].response.results ?? []}
            company={selectedCompany}
            companyLicense={selectedCompanyLicense}
          />}
          <ConfirmationDialog critical open={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)} triggerUpdate={forceUpdate} text={`Deleting a company license leads to an unusable ${productName} on the clients which are assigned to this license.`} okText='Delete' action={deleteCompanyLicense} />
          {generatedCompanyLicense && <ActivationCodesDialog open={showActivationCodesDialogOpen} maxWidth="md" onClose={(_, reason) => { setShowActivationCodesDialogOpen(false); }} serverUrl={generatedCompanyLicense.serverUrl} companyLicense={getCompanyLicense(generatedCompanyLicense.serverUrl, generatedCompanyLicense.companyLicenseId)} reloadActivationCodes={() => forceUpdate()} />}
          {selectedCompanyLicense && <UpgradeLicenseDialog open={upgradeCompanyLicenseDialogOpen} onClose={(_, reason) => { setUpdateCompanyLicenseDialogOpen(false); if (reason === 'done') { forceUpdate(); } }} companyLicense={selectedCompanyLicense} />}
        </Grid>
      </Paper>
    </RestrictedPage >
  );
};

export default CompanyLicenses;
