import { Button, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, makeStyles, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { DialogProps } from '@material-ui/core/Dialog';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';

import { SessionContext } from '../App';
import ProgressButton from '../components/ProgressButton';
import RestrictedElement from '../components/RestrictedElement';
import { COMPANYLICENSES, UserRole, USERS } from '../config/Config';
import { useMergedCompanies } from '../hooks/FetchedDataHook';
import useLicenseServers from '../hooks/LicenseServersHook';
import { IMergedCompany } from '../interfaces/ICompany';
import ICompanyLicense from '../interfaces/ICompanyLicense';
import { IMergedObject } from '../interfaces/IMergedMultiServerResponse';
import IMultiServerResponse from '../interfaces/IMultiServerResponse';
import { ISessionContext } from '../interfaces/ISessionContext';
import IUser from '../interfaces/IUser';
import { Utils } from '../utils/Utils';

const useStyles = makeStyles(() =>
  createStyles({
    error: {
      color: 'red'
    },
    hidden: {
      display: 'none'
    }
  })
);

interface UserDialogProps extends DialogProps {
  user?: IMergedObject<IUser>,
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'done'): void;
  }['bivarianceHack'];
}

const AddUserDialog: React.FC<UserDialogProps> = (props) => {
  const classes = useStyles();

  const session = useContext<ISessionContext>(SessionContext);
  const [licenseServers,] = useLicenseServers();

  const [error, setError] = React.useState<string>();
  const [companyListError, setCompanyListError] = React.useState(false);
  // const [companyLicenseListError, setCompanyLicenseListError] = React.useState(false);

  const [companyList,] = useMergedCompanies(setError);

  const [selectedCompany, setSelectedCompany] = useState<IMergedCompany | undefined>();
  const [companyLicenseList, setCompanyLicenseList] = useState<IMultiServerResponse<ICompanyLicense>>();
  const [selectedLicenseIds, setSelectedLicenseIds] = useState<{ [serverUrl: string]: number; }>({});

  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const [userEnabled, setUserEnabled] = useState(true);
  const [userRole, setUserRole] = useState('user');

  const [saveRunning, setSaveRunning] = useState(false);

  const { user, ...dialogProps } = props;
  const { open, onClose } = props;

  useEffect(() => { if (open) { setError(''); } }, [open]);

  /* Select first company if there is exactly one */
  useEffect(() => { if (companyList?.length === 1) { setSelectedCompany(companyList[0]); } }, [companyList]);

  /* Fetch all company licenses once*/
  useEffect(() => {


    fetch(COMPANYLICENSES, {
      method: 'GET',
      credentials: 'same-origin'
    }).then(async response => {
      if (!response.ok) {
        throw Error('Error retrieving company data. ' + (await Utils.messageFromResponse(response)));
      } else {
        return response.json();
      }
    }).then(data => {
      /* Store all company licenses */
      let companyLicenseList = data as IMultiServerResponse<ICompanyLicense>;
      setCompanyLicenseList(companyLicenseList);
    }).catch(error => {
      setError(error.message);
    });
  }, []);

  /**
   * Selects the first company license for each server if a new company has been chosen
   */
  useEffect(() => {
    if (!selectedCompany) {
      return;
    }

    /* Construct an object that represents the selection of the first license for a certain company on each license server */
    let licenseIds: { [serverUrl: string]: number; } = {};

    /* Select the first entry */
    licenseServers
      .filter(server => server.available && Utils.isOK(server.status))
      .forEach(server => {
        let serverResult = selectedCompany?.companyLicenses[server.url];
        // console.log(serverResult);
        if (serverResult?.length > 0) {
          licenseIds[server.url] = serverResult[0].id;
        }
      });

    setSelectedLicenseIds(licenseIds);
  }, [licenseServers, companyLicenseList, selectedCompany]);

  function handleSave() {
    /* First reset error state */
    let hasErrors = false;
    setCompanyListError(false);
    setUsernameError(false);
    setPasswordError(false);
    setEmailError(false);

    if (!selectedCompany?.idMapping) {
      hasErrors = true;
      setCompanyListError(true);
    }

    if (username === '') {
      hasErrors = true;
      setUsernameError(true);
    }

    if (password === '') {
      hasErrors = true;
      setPasswordError(true);
    }

    if (!!email.trim() && !email.match(Utils.EMAIL_REGEX)) {
      hasErrors = true;
      setEmailError(true);
    }

    if (!userRole) {
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    /* Begin circular progress */
    setSaveRunning(true);

    /* Create new user in DB */
    fetch(USERS,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: 'username=' + encodeURIComponent(username)
          + '&password=' + encodeURIComponent(password)
          + '&firstName=' + encodeURIComponent(firstName)
          + '&lastName=' + encodeURIComponent(lastName)
          + '&email=' + encodeURIComponent(email)
          + '&enabled=' + encodeURIComponent(userEnabled)
          + '&role=' + encodeURIComponent(userRole)
          + '&licenseConfigurationIdMapping=' + JSON.stringify(selectedLicenseIds)
          + '&companyIdMapping=' + JSON.stringify(selectedCompany?.idMapping)
      }
    ).then(async response => {
      if (!response.ok) {
        throw new Error(await (Utils.messageFromResponse(response)));
      } else {
        setSaveRunning(false);

        setUsername('');
        setFirstName('');
        setLastName('');
        setPassword('');
        setEmail('');
        setUserRole('user');
        setUserEnabled(true);

        onClose?.({}, 'done');
      }
    }).catch(reason => {
      setError('Unable to create a user. ' + (reason ? (reason.message ? reason.message : reason) : ''));
      setSaveRunning(false);
    });
  }

  function handleKeyPressed(event: any) {
    if (event.charCode === 13) {
      handleSave();
    }
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        {error && <Typography variant='body1' className={clsx(classes.error)}>{error}</Typography>}
        {((!session.selfHosted && session.roles?.includes(UserRole.ADMIN)) || ((companyList?.length ?? 0) > 1)) &&
          <FormControl fullWidth>
            <InputLabel>Company</InputLabel>
            <Select
              error={companyListError}
              value={selectedCompany?.name}
              onChange={event => setSelectedCompany(companyList?.filter(company => company.name === event.target.value)[0])}
            >
              {companyList?.map((company, index) =>
                <MenuItem key={"company_" + index} value={company.name}>{company.name}</MenuItem>
              )}
            </Select>
          </FormControl>
        }
        <TextField
          margin='dense'
          id='name'
          label='Username'
          type='name'
          fullWidth
          autoFocus
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          onKeyPress={handleKeyPressed}
          error={usernameError}
        />
        <TextField
          margin='dense'
          id='password'
          label='Password'
          type='password'
          autoComplete="new-password"
          fullWidth
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          onKeyPress={handleKeyPressed}
          error={passwordError}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin='dense'
              id='name'
              label='First Name'
              type='firstname'
              fullWidth
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              onKeyPress={handleKeyPressed}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin='dense'
              id='name'
              label='Last Name'
              type='lastName'
              fullWidth
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              onKeyPress={handleKeyPressed}
            />
          </Grid>
        </Grid>
        <TextField
          margin='dense'
          id='name'
          label='E-Mail'
          type='email'
          fullWidth
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          onKeyPress={handleKeyPressed}
          error={emailError}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={userEnabled}
              color='primary'
              onChange={event => setUserEnabled(event.target.checked as boolean)}
            />
          }
          label={'User Enabled'}
        />
        <FormLabel component="legend" style={{ marginTop: '1em' }}>Role</FormLabel>
        <RadioGroup value={userRole} onChange={(e) => { setUserRole((e.target as HTMLInputElement).value); }}>
          <Grid container direction="row">
            <FormControlLabel
              control={<Radio value='user' color='primary' />}
              label={'User'}
            />
            <FormControlLabel
              control={<Radio value='companyAdmin' color='primary' />}
              label={session.selfHosted ? 'Supervisor' : 'Company Admin'}
            />
            <RestrictedElement allowedRoles={[UserRole.ADMIN]} allowedInSelfHosting>
              <FormControlLabel
                control={<Radio value='admin' color='primary' style={session.selfHosted ? {} : { color: red[500] }} />}
                style={session.selfHosted ? {} : { color: red[500] }}
                label={session.selfHosted ? 'Administrator' : 'Mindmotiv Admin'}
              />
            </RestrictedElement>
          </Grid>
        </RadioGroup>
        {licenseServers &&
          <>
            <Typography style={{ marginTop: '1em' }}>In the following you can assign dynamic licenses (if available). For node-locked licenses you don't need to select anything.</Typography>
            <FormLabel component="legend" style={{ marginTop: '1em' }}>License</FormLabel>
            <Table padding='none' size='small'>
              <TableBody>
                {licenseServers.map(server =>
                  <TableRow key={"license_" + server.url}>
                    <TableCell style={{ paddingRight: '1em' }}>{server.name}</TableCell>
                    <TableCell>
                      <Select
                        fullWidth
                        value={selectedLicenseIds[server.url] ?? "none"}
                        onChange={(event: any) => {
                          setSelectedLicenseIds(oldValue => { return { ...oldValue, [server.url]: event.target.value === "none" ? undefined : event.target.value }; });
                        }}
                      >
                        <MenuItem value={"none"}><Typography style={{ lineHeight: 'inherit' }} color='textSecondary'>- None -</Typography></MenuItem>
                        {companyList && selectedCompany?.companyLicenses[server.url]?.filter(companyLicense => companyLicense.type === "dynamic").map(companyLicense =>
                          <MenuItem key={companyLicense.id} value={companyLicense.id}>
                            <Typography component='div' display="inline" style={{ flexGrow: 1 }}>
                              {companyLicense?.name || ''}
                              {companyLicense.licenseConfiguration?.name === companyLicense.name
                                ? ''
                                : (!companyLicense.name ? (companyLicense.licenseConfiguration?.name ?? '') : <Typography display="inline" color="textSecondary">{` (${companyLicense.licenseConfiguration?.name ?? 'Not assigned correctly'})`}</Typography>)
                              }
                            </Typography>
                            {'\u00a0'}
                            <Typography component='div' display="inline" style={{ float: "right" }} color="textSecondary">{companyLicense.maxAmount + ' ' + (companyLicense.maxAmount === 1 ? 'license' : 'licenses')}</Typography>
                          </MenuItem>
                        )}
                      </Select>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.({}, 'backdropClick')} color='primary' disabled={saveRunning}>Cancel</Button>
        <ProgressButton showProgress={saveRunning} variant='outlined' color='primary' onClick={handleSave}>Save</ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;