import { CircularProgress, createStyles, FormControlLabel, FormGroup, Grid, makeStyles, Paper, Switch, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Theme, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import { SessionContext } from '../App';
import HTMLTooltip from '../components/HTMLTooltip';
import { LICENSES_CUSTOM } from '../config/Config';
import useLicenseServers from '../hooks/LicenseServersHook';
import useLoginDialogRedirect from '../hooks/LoginDialogHook';
import ILicense from '../interfaces/ILicense';
import IMultiServerResponse from '../interfaces/IMultiServerResponse';
import { ISessionContext } from '../interfaces/ISessionContext';
import { Utils } from '../utils/Utils';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textGrid: {
			padding: theme.spacing(2)
		},
		formControlGrid: {
			paddingTop: theme.spacing(2)
		},
		progress: {
			alignSelf: 'center',
			margin: theme.spacing(2)
		},
		invalid: {
			opacity: 0.3
		},
		divider: {
			marginTop: theme.spacing(5)
		},
		error: {
			marginTop: theme.spacing(2),
			color: 'red'
		},
		hidden: {
			display: 'none'
		}
	})
);

interface ITableSettings {
	[server: string]: {
		page: number,
		rowsPerPage: number,
		filter: {
			includeNodeLocked: boolean;
			includeInvalid: boolean;
		};
	};
}

const Licenses: React.FC = () => {
	const classes = useStyles();

	const session = useContext<ISessionContext>(SessionContext);
	const [licenseServers,] = useLicenseServers();

	const [data, setData] = useState<IMultiServerResponse<ILicense>>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const loginDialogRedirect = useLoginDialogRedirect();

	const [tableSettings, setTableSettings] = useState<ITableSettings>();

	const { isLoggedIn } = session;

	useEffect(() => {
		if (licenseServers) {
			let newSettings: ITableSettings = {};
			licenseServers.map(server =>
				newSettings[server.url] = {
					filter: {
						includeNodeLocked: false,
						includeInvalid: false
					},
					page: 0,
					rowsPerPage: 10
				}
			);
			setTableSettings(newSettings);
		}
	}, [licenseServers, isLoggedIn]);

	useEffect(() => {
		if (tableSettings) {
			fetch(LICENSES_CUSTOM + '?settings=' + encodeURIComponent(JSON.stringify(tableSettings)), {
				method: 'GET',
				credentials: 'same-origin'
			})
				.then(loginDialogRedirect)
				.then(async response => {
					if (response.ok) {
						return response.json();
					} else {
						throw Error('Error retrieving licenses. ' + (await Utils.messageFromResponse(response)));
					}
				})
				.then(data => setData(data))
				.catch(error => setError(error.message))
				.finally(() => setLoading(false));
		}
	}, [isLoggedIn, loginDialogRedirect, tableSettings]);

	function handleChecked(serverUrl: string, checked: boolean) {
		setTableSettings((oldSettings) => {
			let newSettings = Object.assign({}, oldSettings);
			if (newSettings[serverUrl]) {
				newSettings[serverUrl].filter.includeInvalid = checked;
			}
			return newSettings;
		});
	}

	function setPage(serverUrl: string, page: number) {
		setTableSettings((oldSettings) => {
			let newSettings = Object.assign({}, oldSettings);
			if (newSettings[serverUrl]) {
				newSettings[serverUrl].page = page;
			}
			return newSettings;
		});
	}

	function setRowsPerPage(serverUrl: string, rows: number) {
		setTableSettings((oldSettings) => {
			let newSettings = Object.assign({}, oldSettings);
			if (newSettings[serverUrl]) {
				newSettings[serverUrl].rowsPerPage = rows;
			}
			return newSettings;
		});
	}

	return (
		<Paper>
			<Grid container direction='column' justify='flex-start' alignItems='stretch'>
				<Grid item className={classes.textGrid}>
					<Typography variant='h3'>
						Active Licenses
					</Typography>
					<Typography variant='body1' className={clsx((error === '' || data) && classes.hidden, classes.error)}>
						{error}
					</Typography>
				</Grid>
				{licenseServers?.map((server, index) =>
					<div key={server.url}>
						<Grid container direction='row' justify='space-between' className={clsx(index !== 0 && classes.divider)}>
							<Grid item className={classes.textGrid}>
								<Grid container spacing={1} alignItems='baseline'>
									{(!server.available || !Utils.isOK(server.status)) && <Grid item style={{ alignSelf: 'center' }}><HTMLTooltip title={"Status cannot be determined"}><ErrorIcon style={{ color: red[500], marginRight: '0.2em' }} /></HTMLTooltip></Grid>}
									<Grid item><Typography variant='h6'>{server.name}</Typography></Grid>
									<Grid item><Typography variant='body1' color='textSecondary'>{server.url}</Typography></Grid>
								</Grid>
							</Grid>
							{server.available && Utils.isOK(server.status) && <Grid item className={classes.formControlGrid}>
								<FormGroup row>
									<FormControlLabel
										control={
											<Switch
												onChange={(event, checked) => { handleChecked(server.url, checked); }}
												value="includeInvalid"
												color="primary"
											/>
										}
										label="Show Expired"
									/>
								</FormGroup>
							</Grid>}
						</Grid>
						{server.available && Utils.isOK(server.status) && <>
							<Grid item className={clsx(classes.progress, !loading && classes.hidden)}>
								<CircularProgress />
							</Grid>
							<Grid item>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Company</TableCell>
											<TableCell>License</TableCell>
											<TableCell>Product Version</TableCell>
											<TableCell>Type</TableCell>
											<TableCell>Start</TableCell>
											<TableCell>End</TableCell>
											<TableCell>Duration</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data?.[server.url]?.response?.results?.map(row =>
											<TableRow key={row.id} className={clsx((row.isRevoked || row.expiration * 1000 < new Date().getTime()) && classes.invalid)}>
												<TableCell align='left'>
													<Typography variant="body2" display="inline">{row.company.name}</Typography>
													{!row.user.isAutoGenerated && <Typography variant="body2" color="textSecondary" display="inline"> ({row.user.username})</Typography>}
													<Typography variant="caption" display="block" color="textSecondary">Hardware ID: {row.hardwareId}</Typography>
												</TableCell>
												<TableCell align='left'>{row.companyLicense && <>
													{row.companyLicense.name || ''}
													{row.companyLicense.licenseConfiguration?.name === row.companyLicense.name
														? ''
														: (!row.companyLicense.name ? (row.companyLicense.licenseConfiguration?.name ?? '') : ` (${row.companyLicense.licenseConfiguration?.name ?? 'Not assigned correctly'})`)
													}
												</>}
												</TableCell>
												<TableCell>{row.productVersion ?? 'Unknown'}</TableCell>
												<TableCell align='left'>{Utils.isBorrowedLicense(row) ? 'Borrowed' : 'Floating'}</TableCell>
												<TableCell align='left'>{new Date((Utils.isBorrowedLicense(row) ? row.creation : (row.sessionStart ?? row.creation)) * 1000).toLocaleString()}</TableCell>
												<TableCell align='left'>{new Date(row.expiration * 1000).toLocaleString()}</TableCell>
												<TableCell align='left'>{moment.unix(row.expiration).from(moment.unix(Utils.isBorrowedLicense(row) ? row.creation : (row.sessionStart ?? row.creation)), true)}</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
								<TablePagination
									rowsPerPageOptions={[10, 20, 50, 100]}
									component='div'
									count={data?.[server.url]?.response.total ?? 0}
									rowsPerPage={tableSettings?.[server.url]?.rowsPerPage ?? 10}
									page={tableSettings?.[server.url]?.page ?? 0}
									onChangePage={(event, page) => { setPage(server.url, page); }}
									onChangeRowsPerPage={event => { setPage(server.url, Math.floor((tableSettings?.[server.url]?.page ?? 0) * (tableSettings?.[server.url]?.rowsPerPage ?? 10) / parseInt(event.target.value))); setRowsPerPage(server.url, parseInt(event.target.value)); }}
								/>
							</Grid>
						</>
						}
					</div>)}
			</Grid>
		</Paper>
	);
};

export default Licenses;