import { Dispatch, useEffect, useReducer } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';

export interface IDownloadState {
  downloadRunning: boolean,
  done: boolean,
  extracting?: string,
  error?: string,
  percent?: number,
  speed?: number,
  time?: { elapsed: number, remaining: number | null; },
  size?: {
    total: number,
    transferred: number;
  };
}

type DownloadReducerAction = (DownloadReducerActionStart | DownloadReducerActionProgress | DownloadReducerActionExtracting | DownloadReducerActionDone | DownloadReducerActionAbort | DownloadReducerActionError);

interface DownloadReducerActionStart { type: 'start'; }
interface DownloadReducerActionProgress { type: 'progress', percent?: number, speed?: number, time?: { elapsed: number, remaining: number | null; }, size?: { total: number, transferred: number; }; }
interface DownloadReducerActionExtracting { type: 'extracting', filename: string; }
interface DownloadReducerActionDone { type: 'done'; }
interface DownloadReducerActionAbort { type: 'abort'; }
interface DownloadReducerActionError { type: 'error', error: string; }

function reduceDownloadState(prevState: IDownloadState, action: DownloadReducerAction) {
  switch (action.type) {
    case 'start':
      return {
        ...prevState,
        downloadRunning: true,
        done: false,
        error: undefined,
        extracting: undefined,
        percent: 0,
        time: undefined,
        size: undefined
      };
    case 'progress':
      let { type, ...newState } = action;
      return {
        ...prevState,
        ...newState,
        extracting: undefined,
        downloadRunning: true,
        done: false,
        error: undefined
      };
    case 'extracting':
      return {
        ...prevState,
        extracting: action.filename,
        downloadRunning: true,
        done: false,
        error: undefined
      };
    case 'abort':
      return {
        percent: 0,
        downloadRunning: false,
        extracting: undefined,
        done: false,
        error: undefined
      };
    case 'done':
      return {
        percent: 1,
        downloadRunning: false,
        extracting: undefined,
        done: true,
        error: undefined
      };
    case 'error':
      return {
        ...prevState,
        downloadRunning: false,
        extracting: undefined,
        done: false,
        error: action.error
      };
    default:
      return prevState;
  }
}

export default function useDownloadState(apiEndpoint: string, connect: boolean = true): [IDownloadState, Dispatch<DownloadReducerAction>] {
  const [state, dispatchState] = useReducer(reduceDownloadState, { downloadRunning: false, done: false });

  useEffect(() => {
    if (connect) {
      const client = new ReconnectingWebSocket(window.location.protocol.toLowerCase().replace('http', 'ws') + '//' + window.location.host + apiEndpoint);
      client.onmessage = (message) => dispatchState(JSON.parse(message.data));
      client.onclose = () => dispatchState({ type: 'abort' });

      return () => { if (client && client.readyState === WebSocket.OPEN) { client.onclose = () => { }; client.close(); } };
    } else {
      dispatchState({ type: 'abort' });
    }
  }, [apiEndpoint, connect]);

  return [state, dispatchState];
}