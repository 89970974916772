import { Button, CircularProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

interface ProgressButtonProps extends ButtonProps {
  showProgress: boolean,
  dontDisableOnProgress?: boolean
}

const useStyles = makeStyles(theme => createStyles({
  buttonWrapper: { position: 'relative' },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export const ProgressButton: React.FC<ProgressButtonProps> = (props) => {
  const classes = useStyles();

  const { disabled, showProgress, dontDisableOnProgress, className, ...otherProps } = props;

  return <Button disabled={disabled || (showProgress && !dontDisableOnProgress)} className={clsx(classes.buttonWrapper, props.className || '')} {...otherProps}>
    {props.children}
    {showProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
  </Button>;
};

export default ProgressButton;