import { Button, CircularProgress, Collapse, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, Grid, Input, InputLabel, makeStyles, Theme, Typography, Zoom } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { DialogProps } from '@material-ui/core/Dialog';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useEffect } from 'react';

import { LSCONFIG_UPDATE } from '../config/Config';
import useLicenseServers from '../hooks/LicenseServersHook';
import useLicenseStatus from '../hooks/LicenseStatusHook';
import ILicenseServerConfiguration from '../interfaces/ILicenseServerConfiguration';
import { ClosableDialogTitle } from './ClosableDialog';

interface UpdateLSConfigDialogProps extends DialogProps {
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButton' | 'done'): void;
  }['bivarianceHack'];
}

interface LSConfigUpdateState {
  uuid: string,
  configurations: {
    [serverUrl: string]: ILicenseServerConfiguration;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circluarProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-20px',
      marginLeft: '-20px'
    },
    fade: { transition: 'opacity 300ms ease-in-out' },
    invisible: { opacity: 0 },
    button: {
      margin: theme.spacing(1)
    },
    doneIcon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-42px',
      marginLeft: '-42px',
      fontSize: '6em',
      color: green[500]
    },
    noPadding: { padding: 0 },
    padding: { padding: theme.spacing(3) },
    expansionPanelTitle: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    expansionPanelSubtitle: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
);

const UpdateLSConfigDialog: React.FC<UpdateLSConfigDialogProps> = (props) => {
  const classes = useStyles();

  const [licenseServers,] = useLicenseServers();

  const [, triggerLicenseServerUpdate] = useLicenseStatus();

  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const [lsConfigUpdateState, setLSConfigUpdateState] = React.useState<LSConfigUpdateState>();

  let open = props.open;

  useEffect(() => {
    if (open) {
      setLSConfigUpdateState(undefined);
      setError(undefined);
      setLoading(true);

      let CANCEL = '**CANCEL**';
      fetch(LSCONFIG_UPDATE, { method: 'GET', credentials: 'same-origin' })
        .then(response => {
          if (!response.ok) { throw new Error(CANCEL); }
          return response.json();
        })
        .then(json => {
          setLSConfigUpdateState(json);
          setLoading(false);
        })
        .catch(reason => {
          setLoading(false);
          if (reason.message === CANCEL) { return; }
          console.error(reason);
        });
    }
  }, [open]);

  /* Close 2s after setting done=true */
  let onClose = props.onClose;
  useEffect(() => {
    if (done) {
      setTimeout(() => {
        setDone(false);
        if (onClose) { onClose({}, "done"); }
      }, 2000);
    }
  }, [onClose, done]);

  async function deleteUploadedLSConfig() {
    setLoading(true);
    try {
      await fetch(LSCONFIG_UPDATE, { method: 'DELETE', credentials: 'same-origin' }).then(() => setLSConfigUpdateState(undefined));
    } finally {
      setLoading(false);
    }
  }

  async function commitLSConfigUpdate() {
    if (!lsConfigUpdateState) {
      return;
    }

    setLoading(true);
    setError(undefined);

    try {
      let response = await fetch(LSCONFIG_UPDATE, {
        method: 'POST',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: "commitUUID=" + encodeURIComponent(lsConfigUpdateState.uuid)
      });
      if (response.ok) {
        triggerLicenseServerUpdate();
        setDone(true);
      } else {
        try {
          let json = await response.json();
          setError(json.error ? json.error : ('Failed to commit License Configuration: ' + response.status + " " + response.statusText));
        } catch (error) {
          setError(response.status + " " + response.statusText);
        }
      }

    } catch (error) {
      setError(error.message ? error.message : error);
    } finally {
      setLoading(false);
    }
  }


  async function uploadLSConfig(fileInput: any) {
    setLoading(true);

    setError(undefined);

    try {
      if (!!fileInput.files.length) {
        let formData = new FormData();
        formData.append('lsconfig', fileInput.files[0]);

        let response = await fetch(LSCONFIG_UPDATE, {
          method: 'POST',
          credentials: 'same-origin',
          body: formData
        });

        if (response.ok) {
          let json = await response.json();
          setLSConfigUpdateState(json);
        } else {
          try {
            let json = await response.json();
            setError(json.error ? json.error : ('Failed to upload License Server Configuration: ' + response.status + " " + response.statusText));
          } catch (error) {
            setError(response.status + " " + response.statusText);
          }
        }
      }
    } catch (error) {
      setError('Failed to upload License Server Configuration');
    } finally {
      fileInput.value = '';
      setLoading(false);
    }
  }

  function getLicenseServerName(serverUrl: string) {
    return licenseServers?.filter(server => server.url === serverUrl)?.[0]?.name ?? serverUrl;
  }

  return (
    <Dialog {...props} maxWidth='md' fullWidth={true}>
      <ClosableDialogTitle className={clsx(classes.fade, done && classes.invisible)} onClose={props.onClose}>Update License Server Configuration</ClosableDialogTitle>
      <CircularProgress className={clsx(classes.fade, classes.circluarProgress, !loading && classes.invisible)} />
      <Zoom in={done}><CheckCircleIcon className={classes.doneIcon} /></Zoom>
      <DialogContent dividers className={clsx(classes.fade, classes.noPadding, (loading || done) && classes.invisible)}>
        <div className={classes.padding}>
          <Collapse in={!!error}><Typography color='error'>{error}{"\u00a0"}</Typography></Collapse>
          {!lsConfigUpdateState && <>
            <DialogContentText>To update your License Server Configuration, please upload the license configuration file provided by Mindmotiv.</DialogContentText>
            <FormControl style={{ marginTop: '1em' }} fullWidth>
              <InputLabel disableAnimation={true} shrink={true} htmlFor="lsconfigFile">License Configuration File</InputLabel>
              <Input id="lsconfigFile" type="file" onChange={(e) => uploadLSConfig(e.target)}></Input>
            </FormControl>
          </>}
          {!!lsConfigUpdateState && <DialogContentText>Please take a moment to review the license changes you uploaded:</DialogContentText>}
        </div>
        {!!lsConfigUpdateState &&
          <Grid container direction='column'>
            {Object.keys(lsConfigUpdateState.configurations).map(serverUrl =>
              <ExpansionPanel square elevation={0} key={"lsconfigupdate_" + serverUrl}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.expansionPanelTitle}>{getLicenseServerName(serverUrl)}</Typography>
                  <Typography className={classes.expansionPanelSubtitle}>{serverUrl}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <pre style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>{JSON.stringify(lsConfigUpdateState.configurations[serverUrl], null, 2)}</pre>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
          </Grid>
        }
      </DialogContent>
      {!!lsConfigUpdateState &&
        <DialogActions className={clsx(classes.fade, (done || loading) && classes.invisible)}>
          <Button variant='outlined' className={classes.button} startIcon={<DeleteIcon />} onClick={() => deleteUploadedLSConfig()}>Cancel</Button>
          <Button variant='outlined' className={classes.button} startIcon={<DoneIcon />} onClick={() => commitLSConfigUpdate()}>Commit License Update</Button>
        </DialogActions>}
    </Dialog>
  );
};

export default UpdateLSConfigDialog;