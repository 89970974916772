import { Button, CircularProgress, createStyles, FormControl, Grid, IconButton, InputLabel, Link, List, ListItem, makeStyles, MenuItem, Paper, Popover, Select, Switch, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Theme, Typography } from '@material-ui/core';
import { amber, orange, red } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import BuildIcon from '@material-ui/icons/Build';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/WarningRounded';
import clsx from 'clsx';
import _ from 'lodash';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { SessionContext } from '../App';
import HTMLTooltip from '../components/HTMLTooltip';
import MergeField, { IMergeFieldDiff } from '../components/MergeField';
import RestrictedElement from '../components/RestrictedElement';
import RestrictedPage from '../components/RestrictedPage';
import { LSCONFIG, UserRole, USERS } from '../config/Config';
import AddUserDialog from '../dialogs/AddUserDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import EditUserDialog from '../dialogs/EditUserDialog';
import ResolveUserConflictDialog from '../dialogs/ResolveUserConflictDialog';
import UpdateLSConfigDialog from '../dialogs/UpdateLSConfigDialog';
import { useMergedCompanies } from '../hooks/FetchedDataHook';
import useForbiddenState from '../hooks/ForbiddenStateHook';
import useForceUpdate from '../hooks/ForceUpdateHook';
import useLicenseServers from '../hooks/LicenseServersHook';
import useLicenseStatus from '../hooks/LicenseStatusHook';
import useLoginDialogRedirect from '../hooks/LoginDialogHook';
import useRestrictedMode from '../hooks/RestrictedModeHook';
import { IMergedCompany } from '../interfaces/ICompany';
import ILicenseConfiguration from '../interfaces/ILicenseConfiguration';
import ILicenseServerConfiguration from '../interfaces/ILicenseServerConfiguration';
import { IMergedMultiServerResponse, IMergedObject } from '../interfaces/IMergedMultiServerResponse';
import IMultiServerResponse from '../interfaces/IMultiServerResponse';
import IUser from '../interfaces/IUser';
import { Utils } from '../utils/Utils';
import { VersionRange } from '../utils/Version';

const SHOW_ENABLED_SWITCH = false;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginBottom: theme.spacing(2)
    },
    textGrid: {
      padding: theme.spacing(2)
    },
    buttonRow: {
      '& td': {
        borderBottom: '0px'
      }
    },
    progress: {
      alignSelf: 'center',
      margin: theme.spacing(2)
    },
    hidden: {
      display: 'none'
    },
    error: {
      marginTop: theme.spacing(2),
      color: 'red'
    },
    tooltipWidth: {
      maxWidth: 500
    },
    conflict: {
      background: amber[100],
      '&:hover': {
        background: amber[200] + "!important"
      }
    },
    errorColumn: {
      width: '1px',
      paddingLeft: 0,
      paddingRight: 0
    },
    userDisabled: {
      '& td:not(.actions), & td:not(.actions) *': { color: theme.palette.text.disabled + '!important' }
    }
  })
);

const FeatureListPopoverButton: React.FC<{ features?: string[]; }> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const { features } = props;
  return <>
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
    >
      <List>
        {(features ?? []).map(feature => <ListItem key={feature}>{feature}</ListItem>)}
      </List>
    </Popover>
    <Button onClick={(event) => { setAnchorEl(event.currentTarget); }} >
      <InfoIcon style={{ marginRight: '0.5em' }} /> {features?.length ?? 0} Feature{features?.length !== 1 ? 's' : ''}
    </Button>
  </>;
};

const Administration: React.FC = () => {
  const classes = useStyles();

  /* Use the provided context to read the access token */
  const session = useContext(SessionContext);
  const [licenseServers,] = useLicenseServers();

  const [userData, setUserData] = useState<IMergedMultiServerResponse<IUser>>();
  const [userError, setUserError] = useState<string>();
  const [userVisible, setUserVisible] = useState(false);

  const [licenseStatus,] = useLicenseStatus();

  const [companyList,] = useMergedCompanies(setUserError);
  const [selectedCompany, setSelectedCompany] = useState<IMergedCompany>();

  const [configData, setConfigData] = useState<IMultiServerResponse<ILicenseServerConfiguration>>();
  const [configError, setConfigError] = useState('');
  const [configVisible, setConfigVisible] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState<IMergedObject<IUser>>();
  const [selectedIds, setSelectedIds] = useState<IMergedObject<number>>();

  const [conflictResolveDialogOpen, setConflictResolveDialogOpen] = useState(false);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [removeUserDialogOpen, setRemoveUserDialogOpen] = useState(false);

  const [updateUsers, forceUpdateUsers] = useForceUpdate();
  const [updateLSConfig, forceUpdateLSConfig] = useForceUpdate();

  const [updateLSConfigDialogOpen, setUpdateLSConfigDialogOpen] = useState(false);
  const loginDialogRedirect = useLoginDialogRedirect();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const restrictedMode = useRestrictedMode();

  const { isLoggedIn } = session;

  const showCompanyColumn = useForbiddenState([UserRole.ADMIN], false, true);

  useEffect(() => {
    /* Users */
    fetch(USERS + '?merged=true'
      + (!selectedCompany ? '' : ('&companyIdMapping=' + encodeURIComponent(JSON.stringify(selectedCompany.idMapping))))
      + '&page=' + encodeURIComponent(page)
      + '&rowsPerPage=' + encodeURIComponent(rowsPerPage),
      {
        method: 'GET',
        credentials: 'same-origin',
      }).then(async response => {
        if (!response.ok) {
          throw Error('Error retrieving user data. ' + (await Utils.messageFromResponse(response)));
        } else {
          return response.json();
        }
      })
      .then(loginDialogRedirect)
      .then(data => {
        setUserVisible(true);
        setUserData(data);
      }).catch(error => {
        setUserVisible(false);
        setUserError(error.message);
      });
  }, [updateUsers, isLoggedIn, loginDialogRedirect, selectedCompany, rowsPerPage, page]);

  useEffect(() => {
    /* Configurations */
    fetch(LSCONFIG, {
      method: 'GET',
      credentials: 'same-origin'
    })
      .then(loginDialogRedirect)
      .then(async response => {
        if (!response.ok) {
          throw Error('Error retrieving license server configuration. ' + (await Utils.messageFromResponse(response)));
        } else {
          return response.json();
        }
      }).then(data => {
        setConfigVisible(true);
        setConfigData(data);
      }).catch(error => {
        setConfigVisible(false);
        setConfigError(error.msg);
      });
  }, [updateLSConfig, isLoggedIn, loginDialogRedirect]);

  function removeUser() {
    if (!!selectedUsers) {
      return fetch(USERS + '?userIds=' + encodeURIComponent(JSON.stringify(createDataArray(selectedUsers, u => u.id))), { method: 'DELETE', credentials: 'same-origin' });
    } else {
      return Promise.reject();
    }
  }

  function getLicenseError(serverUrl: string, config: ILicenseConfiguration) {
    if (licenseStatus?.[serverUrl]?.response?.licenseCompatibilityStatus === false
      && licenseStatus?.[serverUrl]?.response?.errors) {
      let serverErrors = licenseStatus[serverUrl].response.errors.filter(error => error.affectedLicenseConfiguration === config.id);
      return !serverErrors.length ? undefined : <>{serverErrors.map(error => <Fragment key={"licenseerror_" + serverUrl + "_" + config.id}>
        {error.message && <Typography>{error.message}</Typography>}
        {error.details || ""}{error.details && <br />}
        Please check the configuration on the 'Company Licenses' page.
          </Fragment>)
      }</>;
    }
    return undefined;
  }

  function getLicenseServerError(serverUrl: string) {
    if (licenseStatus?.[serverUrl]?.response?.licenseValid === false && licenseStatus?.[serverUrl]?.response?.errors) {
      return licenseStatus[serverUrl].response.errors
        .filter(error => (error.affectedLicenseConfiguration || error.affectedCompanyLicenses) === undefined)
        .map(error => <Fragment key={"licenseservererror_" + serverUrl}>
          {error.message && <Typography>{error.message}</Typography>}
          {error.details || ""}{error.details && <br />}
        </Fragment>);
    }
    return undefined;
  }

  function createMergedEntry(dataset: IMergedObject<IUser>, mapping: (user: IUser) => any): any {
    let field = mapping(dataset[Object.keys(dataset)[0]]);
    let diff: IMergeFieldDiff = {};

    Object.keys(dataset).forEach(server => {
      if (field !== mapping(dataset[server])) {
        diff[server] = mapping(dataset[server]);
      }
    });

    return <MergeField data={field} diff={diff} />;
  }

  function createLicenseEntry(dataset: IMergedObject<IUser>): any {
    return Object.keys(dataset).map(server => {
      let companyLicense = dataset[server].defaultCompanyLicense;
      if (!companyLicense) { return undefined; }
      return <div key={server}>
        {companyLicense.name || ((companyLicense.licenseConfiguration?.name !== companyLicense.name) && companyLicense.licenseConfiguration?.name)}
        <Typography variant='body2' display='inline' color='textSecondary'> (
          {(companyLicense.name && companyLicense.licenseConfiguration?.name !== companyLicense.name) && ((companyLicense.licenseConfiguration?.name ?? 'Not assigned correctly') + ': ')}
          {`${companyLicense.maxAmount} ${(companyLicense.maxAmount) === 1 ? 'license' : 'licenses'}`}
          )</Typography>
      </div>;
    });
  }

  function createDataArray<T>(dataset: IMergedObject<IUser>, mapping: (user: IUser) => T): IMergedObject<T> {
    let res: IMergedObject<T> = {};
    Object.keys(dataset).forEach(server => {
      res[server] = mapping(dataset[server]);
    });
    return res;
  }

  function getInconsistencyErrorMessage(dataset: IMergedObject<IUser>): string | undefined {
    let missingServers = _.difference(licenseServers?.filter(s => s.available && Utils.isOK(s.status))?.map(server => server.url), Object.keys(dataset));
    if (missingServers.length > 0) {
      return 'User does not exist on ' + missingServers.join(' and ');
    }

    let mappings: ((user: IUser) => any)[] = [
      u => u.username,
      u => u.company?.name,
      u => u.firstName,
      u => u.lastName,
      u => u.email
    ];

    for (let mapping of mappings) {
      let field = mapping(dataset[Object.keys(dataset)[0]]);
      for (let server of Object.keys(dataset)) {
        if (field !== mapping(dataset[server])) {
          return 'There is a conflict in a user property';
        }
      }
    }

    /* Everything is fine */
    return undefined;
  }

  function setUserEnabled(user: IMergedObject<IUser>, enabled: boolean) {
    fetch(USERS, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body:
        'userIds=' + encodeURIComponent(JSON.stringify(createDataArray(user, u => u.id)))
        + '&enabled=' + encodeURIComponent(enabled)
    })
      .then(() => forceUpdateUsers())
      .catch(() => { });
  }


  return (
    <RestrictedPage allowedRoles={[UserRole.ADMIN, UserRole.COMPANY_ADMIN]}>
      <Grid container direction='column' justify='flex-start' alignItems='stretch'>
        <ResolveUserConflictDialog open={conflictResolveDialogOpen} onClose={() => setConflictResolveDialogOpen(false)} conflictedData={selectedUsers} ids={selectedIds} triggerUpdate={() => forceUpdateUsers()} fullWidth maxWidth='md' />
        <AddUserDialog open={addUserDialogOpen} onClose={(_, reason) => { setAddUserDialogOpen(false); if (reason === 'done') { forceUpdateUsers(); } }} fullWidth maxWidth='sm' />
        {selectedUsers && <EditUserDialog open={editUserDialogOpen} onClose={() => setEditUserDialogOpen(false)} user={selectedUsers} triggerUpdate={() => forceUpdateUsers()} fullWidth maxWidth='sm' />}
        <ConfirmationDialog critical open={removeUserDialogOpen} onClose={() => setRemoveUserDialogOpen(false)} triggerUpdate={() => forceUpdateUsers()} text={`Do you want to delete the user '${selectedUsers?.[Object.keys(selectedUsers ?? { a: 0 })[0]]?.username}' from all license servers?`} okText={'Delete'} action={removeUser} />
        <Grid item component={Paper} style={{ marginBottom: '20px' }}>
          <Grid container direction='column' justify='flex-start' alignItems='stretch'>
            <Grid item className={classes.textGrid}>
              <Grid container direction='row' justify='space-between' alignItems='center'>
                <Typography variant='h3'>
                  User Management
							  </Typography>
                <Grid item><Button variant='outlined' color='primary' onClick={() => setAddUserDialogOpen(true)} disabled={restrictedMode}><AddIcon /> Add user</Button></Grid>
              </Grid>
              <Typography variant='body1' className={clsx((userError === '') && classes.hidden, classes.error)}>
                {userError}
              </Typography>
            </Grid>
            <Grid item className={clsx(classes.progress, (userVisible || userError) && classes.hidden)}>
              <CircularProgress />
            </Grid>
            <Grid item className={clsx(!userVisible && classes.hidden)}>
              {!session.selfHosted && session.roles?.includes(UserRole.ADMIN) && <Grid item className={classes.textGrid} style={{ paddingTop: 0 }}>
                <FormControl size="small" fullWidth>
                  <InputLabel>Filter Company</InputLabel>
                  <Select
                    value={selectedCompany?.name ?? 'all'}
                    onChange={event => setSelectedCompany(companyList.filter(company => company.name === event.target.value)[0])}
                  >
                    <MenuItem value='all'><Typography color="textSecondary">All</Typography></MenuItem>
                    {companyList?.map((company, index) =>
                      <MenuItem key={"company_" + index} value={company.name}>{company.name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>}
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.errorColumn}></TableCell>
                    {showCompanyColumn && <TableCell>Company</TableCell>}
                    <TableCell>Username</TableCell>
                    <TableCell>License</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>E-Mail</TableCell>
                    <TableCell style={{ width: '10px' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData?.result?.map((user, i) => {
                    let firstUser: IUser | undefined = user[Object.keys(user)[0]];
                    let username = firstUser?.username ?? '';
                    let canEditUser = session.roles?.includes(UserRole.ADMIN) || !(firstUser?.roles ?? []).includes(UserRole.ADMIN);
                    let errorMessage = getInconsistencyErrorMessage(user);
                    let isMe = username === session.username;
                    return <TableRow hover className={clsx(errorMessage && classes.conflict, !firstUser?.enabled && classes.userDisabled)} key={'User_' + i}>
                      <TableCell align='left' className={classes.errorColumn}>{errorMessage && <HTMLTooltip title={errorMessage} classes={{ tooltip: classes.tooltipWidth }}><WarningIcon style={{ color: orange[500], marginLeft: '0.5em' }} /></HTMLTooltip>}</TableCell>
                      {showCompanyColumn && <TableCell align='left'>{createMergedEntry(user, u => u.company?.name)}</TableCell>}
                      <TableCell align='left'>{createMergedEntry(user, u => u.username)}</TableCell>
                      <TableCell align='left'>{createLicenseEntry(user)}</TableCell>
                      <TableCell align='left'>{createMergedEntry(user, u => u.firstName)}</TableCell>
                      <TableCell align='left'>{createMergedEntry(user, u => u.lastName)}</TableCell>
                      <TableCell align='left'><Link href={"mailto:" + firstUser?.email}>{createMergedEntry(user, u => u.email)}</Link></TableCell>
                      <TableCell align='center' size='small' className='actions' style={{ whiteSpace: 'nowrap' }}>
                        <RestrictedElement allowedRoles={[UserRole.COMPANY_ADMIN, UserRole.ADMIN]} allowedInSelfHosting>
                          {SHOW_ENABLED_SWITCH && <Switch size='small' color='primary' checked={firstUser?.enabled} onChange={e => { setUserEnabled(user, e.target.checked); }} disabled={restrictedMode || isMe} style={{ visibility: !!errorMessage ? 'hidden' : 'visible' }} />}
                          {errorMessage
                            ? <IconButton disabled={restrictedMode || !canEditUser} size='small' onClick={() => { setSelectedUsers(createDataArray(user, u => u)); setSelectedIds(createDataArray(user, u => u.id)); setConflictResolveDialogOpen(true); }}> <BuildIcon /> </IconButton>
                            : <IconButton disabled={restrictedMode || !canEditUser} size='small' onClick={() => { setSelectedUsers(createDataArray(user, u => u)); setEditUserDialogOpen(true); }}> <EditIcon /> </IconButton>}
                          <IconButton disabled={restrictedMode || !canEditUser || isMe} size='small' onClick={() => { setSelectedUsers(createDataArray(user, u => u)); setRemoveUserDialogOpen(true); }}><DeleteIcon /></IconButton>
                        </RestrictedElement>
                      </TableCell>
                    </TableRow>;
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component='div'
                count={userData ? userData.total : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(event, page) => { setPage(page); }}
                onChangeRowsPerPage={event => { setPage(Math.floor(page * rowsPerPage / parseInt(event.target.value))); setRowsPerPage(parseInt(event.target.value)); }}
              />
            </Grid>
          </Grid>
        </Grid>
        <RestrictedElement allowedRoles={[UserRole.ADMIN]} allowedInSelfHosting>
          <UpdateLSConfigDialog open={updateLSConfigDialogOpen} onClose={(e, reason) => { setUpdateLSConfigDialogOpen(false); if (reason === 'done') { forceUpdateLSConfig(); } }} />
          <Grid item component={Paper}>
            <Grid container direction='column' justify='flex-start' alignItems='stretch'>
              <Grid container direction='row' justify='space-between' alignItems='center' className={classes.textGrid}>
                <Typography variant='h3'>License Server Configuration</Typography>
                <Grid item><Button variant='outlined' color='primary' onClick={() => setUpdateLSConfigDialogOpen(true)} disabled={restrictedMode}>Install new license</Button></Grid>
              </Grid>
              {licenseServers?.map(server => {
                let licenseServerError = getLicenseServerError(server.url);
                return <div key={server.url}>
                  <Grid item className={classes.textGrid}>
                    <Grid container spacing={1} alignItems='baseline'>
                      {licenseServerError && <Grid item style={{ alignSelf: 'center' }}><HTMLTooltip title={licenseServerError} classes={{ tooltip: classes.tooltipWidth }}><WarningIcon style={{ color: orange[500], marginRight: '0.2em' }} /></HTMLTooltip></Grid>}
                      {(!server.available || !Utils.isOK(server.status)) && <Grid item style={{ alignSelf: 'center' }}><HTMLTooltip title={"Status cannot be determined"}><ErrorIcon style={{ color: red[500], marginRight: '0.2em' }} /></HTMLTooltip></Grid>}
                      <Grid item><Typography variant='h6'>{server.name}</Typography></Grid>
                      <Grid item><Typography variant='body1' color='textSecondary'>{server.url}</Typography></Grid>
                    </Grid>
                    <Typography variant='body1' className={clsx((configError === '') && classes.hidden, classes.error)}>
                      {configError}
                    </Typography>
                  </Grid>
                  <Grid item className={clsx(classes.progress)}>
                    {!(configVisible || configError) && <CircularProgress />}
                  </Grid>
                  {Utils.isOK(configData?.[server.url]?.status) &&
                    <Grid item>
                      <Table size="small" className={clsx(!configVisible && classes.hidden, classes.table)}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell style={{ width: '10%', whiteSpace: 'nowrap' }}>Number of Licenses</TableCell>
                            <TableCell style={{ width: '15%' }}>End of Support</TableCell>
                            <TableCell style={{ width: '20%' }}>Compatible Versions</TableCell>
                            <TableCell style={{ width: '20%' }}>Features</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {configData?.[server.url].response.results.flatMap(lsConfig => lsConfig.licenseConfigurations).map((config, i) => {
                            let licenseError = getLicenseError(server.url, config);
                            return <TableRow hover key={"lsconfig_" + server.url + "_" + config.id + "_" + i}>
                              <TableCell align='left'>
                                <Grid container alignItems='center'>
                                  <Grid item>{!!licenseError && <HTMLTooltip title={licenseError} classes={{ tooltip: classes.tooltipWidth }}><WarningIcon style={{ color: orange[500], marginRight: '0.1em' }} /></HTMLTooltip>}</Grid>
                                  <Grid item>{config.name}</Grid>
                                </Grid>
                              </TableCell>
                              <TableCell align='left'>{config.numberOfLicenses ?? 'Infinite'}</TableCell>
                              <TableCell align='left'>{config.endOfSupport ?? ''}</TableCell>
                              <TableCell align='left'>{config.validVersions.map(version => {
                                let versionRange = VersionRange.parse(version);
                                return versionRange ? versionRange.toReadableString() : version;
                              }).join(', ')}
                                {config.latestReleaseDate && ` (released before ${config.latestReleaseDate})`}
                              </TableCell>
                              <TableCell align='left'>
                                <FeatureListPopoverButton features={config.features} />
                              </TableCell>
                            </TableRow>;
                          })
                          }
                        </TableBody>
                      </Table>
                    </Grid>
                  }
                </div>;
              })}
            </Grid>
          </Grid>
        </RestrictedElement>
      </Grid>
    </RestrictedPage>
  );
};

export default Administration;