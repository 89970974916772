import { createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(2)
    }
  })
);

const NotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container direction='row' alignItems='stretch' spacing={2}>
      <Grid container component={Paper} direction='column' justify='flex-start' alignItems='stretch' style={{ height: '100%' }}>
        <Grid item className={classes.grid}>
          <Typography variant='h3'>
            Not Found
          </Typography>
        </Grid>
        <Grid item className={classes.grid}>
          <Typography variant='body1'>
            Sorry! The page you requested doesn't exist.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NotFound;