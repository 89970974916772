import { Button, Checkbox, ClickAwayListener, Collapse, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Fade, FormControl, FormControlLabel, Grid, Grow, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, MenuList, Paper, Popover, Popper, Select, TextField, Theme, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { SessionContext } from '../App';
import ProgressButton from '../components/ProgressButton';
import { COMPANYLICENSES } from '../config/Config';
import useLicenseServers from '../hooks/LicenseServersHook';
import { IMergedCompany } from '../interfaces/ICompany';
import { ICompanyLicenseShort } from '../interfaces/ICompanyLicense';
import { IFeatureSetShort } from '../interfaces/IFeatureSet';
import ILicenseConfiguration from '../interfaces/ILicenseConfiguration';
import ILicenseServerConfiguration from '../interfaces/ILicenseServerConfiguration';
import { ISessionContext } from '../interfaces/ISessionContext';
import { Utils } from '../utils/Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: 'none'
    }
  })
);

interface EditCompanyLicenseDialogProps extends DialogProps {
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'done'): void;
  }['bivarianceHack'],
  lsConfig: ILicenseServerConfiguration,
  featureSets: IFeatureSetShort[],
  serverUrl: string,
  company: IMergedCompany,
  companyLicense: ICompanyLicenseShort;
}

const daysToDate = (days: number) => {
  let d = new Date();
  d.setDate(d.getDate() + days);
  return d.toISOString().substr(0, 10);
};
const days = [7, 30, 90, 365, 2 * 365];

const EditCompanyLicenseDialog: React.FC<EditCompanyLicenseDialogProps> = (props) => {
  const classes = useStyles();

  const session = useContext<ISessionContext>(SessionContext);

  const [licenseServers,] = useLicenseServers();

  const [error, setError] = useState('');

  const [updateRunning, setUpdateRunning] = useState(false);

  const [name, setName] = useState('');
  const [selectedLicenseConfiguration, setSelectedLicenseConfiguration] = useState<ILicenseConfiguration>();
  const [numLicenses, setNumLicenses] = useState(1);
  const [additionalFeatureSets, setAdditionalFeatureSets] = useState<IFeatureSetShort[]>([]);
  const [additionalFeatureSetsAnchorEl, setAdditionalFeatureSetsAnchorEl] = React.useState<any>(null);

  const [restrictVersionChecked, setRestrictVersionChecked] = useState(true);
  const [versionRange, setVersionRange] = useState('[,]');

  const [expires, setExpires] = useState(false);
  const anchorRef = useRef<any>(null);

  const [expiration, setExpiration] = useState(daysToDate(days[0]));
  const [expirationPopperOpen, setExpirationPopperOpen] = useState(false);

  const [endOfSupportChecked, setEndOfSupportChecked] = useState(true);
  const [endOfSupport, setEndOfSupport] = useState(daysToDate(days[0]));
  const [endOfSupportPopperOpen, setEndOfSupportPopperOpen] = useState(false);

  const { lsConfig, companyLicense, featureSets: serverFeatureSets, serverUrl, company, ...dialogProps } = props;
  const { open, onClose } = props;

  /* Reload data on open */
  useEffect(() => {
    if (!open) { return; }

    /* Reset error */
    setError('');

    /* Load name */
    setName(companyLicense.name || '');

    if (lsConfig && companyLicense) {
      let configurations = (lsConfig.licenseConfigurations && companyLicense.licenseConfiguration)
        ? lsConfig.licenseConfigurations.filter(lic => lic.id === companyLicense.licenseConfiguration!.id)
        : [];

      setSelectedLicenseConfiguration(configurations.length ? configurations[0] : undefined);
      setNumLicenses(companyLicense.maxAmount);

      setAdditionalFeatureSets(companyLicense.additionalFeatureSets ?? []);

      setRestrictVersionChecked(companyLicense.validVersions !== '[,]');
      setVersionRange(companyLicense.validVersions);

      setEndOfSupportChecked(!!companyLicense.endOfSupport);
      setEndOfSupport(companyLicense.endOfSupport || daysToDate(days[0]));

      setExpires(!!companyLicense.expiration);
      setExpiration(companyLicense.expiration || daysToDate(days[0]));
    }
  }, [open, lsConfig, companyLicense]);

  async function handleCompanyLicenseUpdate() {
    /* Begin progress circle */
    setUpdateRunning(true);
    try {

      if (!selectedLicenseConfiguration) {
        return;
      }

      if (restrictVersionChecked && versionRange?.match(Utils.VERSION_RANGE_REGEX)?.[0] !== versionRange) {
        return;
      }

      if (expires && (!expiration?.match(/^\d\d\d\d-\d\d-\d\d$/) || !new Date(expiration).getTime())) {
        return;
      }

      /* Trigger company license generation */
      let response = await fetch(COMPANYLICENSES, {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: 'serverUrl=' + encodeURIComponent(serverUrl)
          + '&companyLicenseId=' + encodeURIComponent(companyLicense.id)
          + '&name=' + encodeURIComponent(name)
          + (session.selfHosted ? '' : ('&expiration=' + (expires ? encodeURIComponent(expiration ?? '') : '')))
          + '&licenseConfigurationId=' + selectedLicenseConfiguration.id
          + '&maxAmount=' + encodeURIComponent(numLicenses)
          + (session.selfHosted ? '' : ('&additionalFeatureSets=' + (encodeURIComponent(additionalFeatureSets.map(fs => fs.id).join(",")))))
          + (session.selfHosted ? '' : ('&endOfSupport=' + (endOfSupportChecked ? encodeURIComponent(endOfSupport ?? '') : '')))
          + (session.selfHosted ? '' : ('&validVersions=' + encodeURIComponent(restrictVersionChecked ? (versionRange ?? '[,]') : '[,]')))
      });

      if (!response.ok) {
        setError('Update failed. ' + await (Utils.messageFromResponse(response)));
        return;
      }

      /* When finished close the dialog and refresh the company licenses */
      onClose?.({}, 'done');
    } finally {
      setUpdateRunning(false);
    }
  }

  function handleKeyPressed(event: any) {
    if (event.charCode === 13) {
      handleCompanyLicenseUpdate();
    }
  }

  function addOrRemoveFeatureSet(featureSet: IFeatureSetShort, add: boolean) {
    let newFeatureSets = [...additionalFeatureSets];
    let index = newFeatureSets.findIndex(fs => fs.id === featureSet.id);
    if (index !== -1) {
      newFeatureSets.splice(index, 1);
    }
    if (add) {
      newFeatureSets.push(featureSet);
    }
    setAdditionalFeatureSets(newFeatureSets);
  }

  const availableLicenseServers = licenseServers?.filter(s => s.available && Utils.isOK(s.status)) ?? [];
  const additionalFeatureSetsPopoverOpen = Boolean(additionalFeatureSetsAnchorEl);

  return (
    <Dialog scroll={'paper'} fullWidth {...dialogProps}>
      <DialogTitle>Edit Company License</DialogTitle>
      <DialogContent style={{ paddingBottom: '1em' }}> {/*I don't know why exactly, but this fixes the ugly scroll bar in the dialog */}
        <DialogContentText>
          Update an existing company license.
        </DialogContentText>
        <Collapse in={!!error}>
          <Typography variant='body1' color='error'>{error}{'\u00a0'}</Typography>
        </Collapse>
        {(!session.selfHosted) &&
          <FormControl fullWidth>
            <InputLabel>Company</InputLabel>
            <Select value={'company'} disabled>
              <MenuItem value={'company'}>{company.name}</MenuItem>
            </Select>
          </FormControl>
        }
        {availableLicenseServers.length > 1 &&
          <FormControl style={{ marginTop: '1em' }} fullWidth>
            <InputLabel>Server</InputLabel>
            <Select
              value={'server'}
              disabled
            >
              {availableLicenseServers.map(server =>
                <MenuItem key={'server'} value={'server'}>{server.name} ({server.url})
                </MenuItem>
              )}
            </Select>
          </FormControl>
        }
        <TextField
          style={{ marginTop: '1em' }}
          label='Custom Name'
          fullWidth
          value={name}
          onChange={(event) => { setName(event.target.value as string); }}
          onKeyPress={(event) => { handleKeyPressed(event); }}
          type="text"
        />
        <Grid container style={{ marginTop: '1em' }} alignItems='baseline' spacing={3}>
          <Grid item xs={12} sm={7}>
            <FormControl fullWidth error={!selectedLicenseConfiguration}>
              <InputLabel>License Configuration</InputLabel>
              <Select
                // disabled={Utils.isNodeLockedCompanyLicense(companyLicense)}
                value={selectedLicenseConfiguration?.id ?? ''}
                onChange={(event: any) => { setSelectedLicenseConfiguration(lsConfig.licenseConfigurations.filter(lic => lic.id === event.target.value)[0]); }}
                onKeyPress={(event) => { handleKeyPressed(event); }}
              >
                {lsConfig?.licenseConfigurations?.map(licenseConfiguration =>
                  <MenuItem key={licenseConfiguration.id} value={licenseConfiguration.id}>
                    <Typography component='div' display="inline" style={{ flexGrow: 1, lineHeight: 1 }}>{licenseConfiguration.name}</Typography>
                    <Typography component='div' display="inline" variant="body2" style={{ float: "right" }} color="textSecondary">{licenseConfiguration.numberOfLicenses ?? 'Infinite'} licenses</Typography>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              disabled={Utils.isNodeLockedCompanyLicense(companyLicense)}
              id="standard-number"
              label={'Allocated Licenses' + (selectedLicenseConfiguration?.numberOfLicenses !== undefined ? ` (max. ${selectedLicenseConfiguration.numberOfLicenses})` : '')}
              value={numLicenses || 0}
              fullWidth
              onChange={(event) => { setNumLicenses(parseInt(event.target.value)); }}
              onKeyPress={(event) => { handleKeyPressed(event); }}
              type="number"
              inputProps={{ min: 0, max: selectedLicenseConfiguration?.numberOfLicenses }}
            />
          </Grid>
        </Grid>
        {!session.selfHosted &&
          <>
            {(additionalFeatureSets.length > 0 || serverFeatureSets.length > 0) &&
              <Grid container style={{ marginTop: '1em' }} alignItems='flex-start' spacing={3}>
                <Grid item xs={12} sm={9}>
                  <Typography variant='body1'>Additional Feature Sets</Typography>
                  <Typography variant='body1' color='textSecondary'>{additionalFeatureSets.map(fs => fs.name).join(", ") || 'None'}</Typography>
                </Grid>
                <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                  {serverFeatureSets.length > 0 && <>
                    <Button variant='outlined' onClick={e => setAdditionalFeatureSetsAnchorEl(e.currentTarget)}>Edit</Button>
                    <Popover
                      id={additionalFeatureSetsPopoverOpen ? 'feature-sets-popover' : undefined}
                      open={additionalFeatureSetsPopoverOpen}
                      anchorEl={additionalFeatureSetsAnchorEl}
                      onClose={() => setAdditionalFeatureSetsAnchorEl(null)}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <Grid container direction='column' style={{ padding: "10px 20px" }}>
                        {serverFeatureSets.map(fs => <Grid item>
                          <FormControlLabel
                            key={'fs_' + fs.id}
                            control={
                              <Checkbox
                                checked={additionalFeatureSets.find(x => x.id === fs.id) !== undefined}
                                color='primary'
                                onChange={event => { addOrRemoveFeatureSet(fs, event.target.checked as boolean); }}
                              />
                            }
                            label={fs.name}
                          /></Grid>
                        )}
                      </Grid>
                    </Popover>
                  </>
                  }
                </Grid>
              </Grid>
            }
            <Grid container style={{ marginTop: '1em' }} alignItems='center' spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={restrictVersionChecked}
                      color='primary'
                      onChange={event => setRestrictVersionChecked(event.target.checked as boolean)}
                    />
                  }
                  label={'Restrict Version'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Fade in={restrictVersionChecked}>
                  <TextField
                    variant='outlined'
                    error={restrictVersionChecked && versionRange?.match(Utils.VERSION_RANGE_REGEX)?.[0] !== versionRange}
                    value={versionRange ?? ''}
                    onChange={e => { setVersionRange(e.target.value as string); }}
                    label="Version Range"
                    onKeyPress={(event) => { handleKeyPressed(event); }}
                    fullWidth
                  />
                </Fade>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '1em' }} alignItems='center' spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      // disabled={Utils.isNodeLockedCompanyLicense(companyLicense)}
                      checked={endOfSupportChecked}
                      color='primary'
                      onChange={event => setEndOfSupportChecked(event.target.checked as boolean)}
                    />
                  }
                  label={'End of Support'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Fade in={endOfSupportChecked}>
                  <TextField
                    disabled={!endOfSupportChecked}
                    variant='outlined'
                    error={!endOfSupport && (!endOfSupport?.match(/^\d\d\d\d-\d\d-\d\d$/) || !new Date(endOfSupport).getTime())}
                    value={endOfSupport ?? ''}
                    onChange={e => { setEndOfSupport(e.target.value as string); }}
                    label="End of Support"
                    onKeyPress={(event) => { handleKeyPressed(event); }}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          size="small"
                          ref={anchorRef}
                          onClick={() => setEndOfSupportPopperOpen(prevOpen => !prevOpen)}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                </Fade>
                <Popper open={endOfSupportPopperOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', }}>
                      <Paper>
                        <ClickAwayListener onClickAway={() => setEndOfSupportPopperOpen(false)}>
                          <MenuList id="split-button-menu">
                            {days.map(days => {
                              let date = daysToDate(days);
                              return <MenuItem
                                key={date}
                                onClick={e => { setEndOfSupport(date); setEndOfSupportPopperOpen(false); }}
                              >
                                {days} Days
                              </MenuItem>;
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '1em' }} alignItems='center' spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      // disabled={Utils.isNodeLockedCompanyLicense(companyLicense)}
                      checked={expires}
                      color='primary'
                      onChange={event => setExpires(event.target.checked as boolean)}
                    />
                  }
                  label={'Expires'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Fade in={expires}>
                  <TextField
                    disabled={!expires}
                    variant='outlined'
                    error={expires && (!expiration?.match(/^\d\d\d\d-\d\d-\d\d$/) || !new Date(expiration).getTime())}
                    value={expiration ?? ''}
                    onChange={e => { setExpiration(e.target.value as string); }}
                    label="Expiration Date"
                    onKeyPress={(event) => { handleKeyPressed(event); }}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          size="small"
                          ref={anchorRef}
                          onClick={() => setExpirationPopperOpen(prevOpen => !prevOpen)}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                </Fade>
                <Popper open={expirationPopperOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', }}>
                      <Paper>
                        <ClickAwayListener onClickAway={() => setExpirationPopperOpen(false)}>
                          <MenuList id="split-button-menu">
                            {days.map(days => {
                              let date = daysToDate(days);
                              return <MenuItem
                                key={date}
                                onClick={e => { setExpiration(date); setExpirationPopperOpen(false); }}
                              >
                                {days} Days
                              </MenuItem>;
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
              <Grid item className={clsx(session.selfHosted && classes.hidden)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Utils.isNodeLockedCompanyLicense(companyLicense)}
                      disabled={true}
                      color='primary'
                    />
                  }
                  label={'Node-Locked'}
                />
              </Grid>
              {/* <Grid item className={clsx(session.selfHosted && classes.hidden)}>
                <Typography>
                  Please keep in mind that you can't switch between node-locked and dynamic licenses. Since node-locked licenses are never expiring, this would lead to inconsistencies.
                {Utils.isNodeLockedCompanyLicense(companyLicense) ? " Delete the old node-locked license and generate a new dynamic one instead." : " Delete the old dynamic license and generate a new node-locked one instead."}
                </Typography>
                </Grid> */}
            </Grid>
          </>
        }
      </DialogContent>
      <DialogActions>
        <ProgressButton showProgress={updateRunning} variant='outlined' onClick={() => handleCompanyLicenseUpdate()} color='primary'>
          Save
        </ProgressButton>
      </DialogActions>
    </Dialog >
  );
};

export default EditCompanyLicenseDialog;