import { Collapse, Dialog, DialogContent, DialogContentText, DialogProps, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { green, orange } from '@material-ui/core/colors';
import GetAppIcon from '@material-ui/icons/GetApp';
import download from 'downloadjs';
import React, { useState } from 'react';
import { useEffect } from 'reactn';

import { DOWNLOAD_ACTIVATION } from '../config/Config';
import { IActivationShort } from '../interfaces/IActivation';
import ICompanyLicense, { ICompanyLicenseShort } from '../interfaces/ICompanyLicense';
import { Utils } from '../utils/Utils';

interface IShowActivationCodesDialog extends DialogProps {
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'done'): void;
  }['bivarianceHack'],
  serverUrl: string,
  companyLicense?: ICompanyLicense | ICompanyLicenseShort,
  reloadActivationCodes?: () => void;
}

const ActivationCodesDialog: React.FC<IShowActivationCodesDialog> = (props) => {
  let { companyLicense, serverUrl, reloadActivationCodes, ...dialogProps } = props;

  let open = props.open;

  const [error, setError] = useState<string>();

  useEffect(() => {
    if (open) {
      setError(undefined);
    }
  }, [open]);

  function downloadActivation(activationCode: string, hardwareId: string) {
    setError(undefined);
    fetch(DOWNLOAD_ACTIVATION
      + '?serverUrl=' + encodeURIComponent(serverUrl ?? '')
      + '&activationCode=' + encodeURIComponent(activationCode)
      + '&hardwareId=' + encodeURIComponent(hardwareId))
      .then(async r => {
        if (!r.ok) {
          throw new Error(await (Utils.messageFromResponse(r)));
        }
        return r.blob();
      })
      .then(blob => {
        download(blob, 'license.zip');
        reloadActivationCodes?.();
      })
      .catch(e => setError(e.message));
  }

  function activateNewLicense(activation: IActivationShort) {
    let hardwareId = prompt("Please enter the hardware id to activate");
    if (hardwareId) {
      downloadActivation(activation.activationCode, hardwareId);
    }
  }

  return (
    <Dialog scroll={'paper'} fullWidth {...dialogProps}>
      <DialogTitle>Node-Lock Activation Codes</DialogTitle>
      <DialogContent style={{ flexShrink: 0 }}>
        <DialogContentText>
          In the following you find the activation codes for the node-locked licenses. Please provide those codes to our customer. With these codes Arttest can be node-locked to this company license.
        </DialogContentText>
        <Collapse in={!!error}>
          <Typography color="error">{error}{'\u00a0'}</Typography>
        </Collapse>
      </DialogContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Activation Code
            </TableCell>
            <TableCell>
              Status
            </TableCell>
            <TableCell>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companyLicense?.activationList?.map(activation =>
            <TableRow key={"activation_row_" + activation.id}>
              <TableCell>{activation.activationCode}</TableCell>
              <TableCell>
                <Typography style={{ color: activation.canUpgrade ? orange[800] : (activation.activated ? green[800] : 'inherit') }}>
                  {activation.activated ? ("Activated by " + activation.hardwareId) : "Not Activated"}
                </Typography>
              </TableCell>
              <TableCell>
                {(activation.activated && <IconButton size='small' onClick={() => downloadActivation(activation.activationCode, activation.hardwareId)}>
                  <GetAppIcon />
                </IconButton>) || <IconButton size='small' onClick={() => activateNewLicense(activation)}>
                    <GetAppIcon />
                  </IconButton>}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Dialog>
  );
};

export default ActivationCodesDialog;