import { Collapse, createStyles, Link, makeStyles, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { saveAs } from 'file-saver';
import React, { useCallback, useContext, useState } from 'react';

import { SessionContext } from '../../App';
import ProgressButton from '../../components/ProgressButton';
import { INFO, LSCONFIG } from '../../config/Config';
import { ISessionContext } from '../../interfaces/ISessionContext';


const useStyles = makeStyles(theme => createStyles({
  button: {
    marginTop: theme.spacing(1)
  },
  error: {
    color: red[500]
  }
}));

async function getServerInfo() {
  let serverInfos = await fetch(INFO, { credentials: 'same-origin' }).then(r => r.json());
  let lsConfigs = await fetch(LSCONFIG, { credentials: 'same-origin' }).then(r => r.json());

  for (let serverUrl of Object.keys(serverInfos)) {
    serverInfos[serverUrl] = { ...serverInfos[serverUrl].response, lsConfig: lsConfigs[serverUrl].response.results[0] };
    let serverInfo = serverInfos[serverUrl];
    if (!serverInfo.lsConfig) {
      throw new Error("Could not get license information for '" + serverUrl + "'");
    }
  }
  return serverInfos;
}

export const SettingsPageRequestLicense: React.FC = (props) => {
  const classes = useStyles();

  const session = useContext<ISessionContext>(SessionContext);
  const [error, setError] = useState<string>();
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);

  let { company } = session;
  let openEmailTemplate = useCallback(async () => {
    setLoadingTemplate(true);
    setError(undefined);
    let serverInfos: any;
    try {
      serverInfos = await getServerInfo();
    } catch (error) {
      setError(error.message);
      return;
    } finally {
      setLoadingTemplate(false);
    }

    let subject = '[MMLS] Request Server Configuration';
    let body = 'Dear Mindmotiv,\n\nplease send a server configuration file for the following configuration:\n\n';

    body += '--- adapt [XXX] and remove the lines for Arttes Versions you did not purchase ---\n\n';
    for (let serverUrl of Object.keys(serverInfos)) {
      let serverInfo = serverInfos[serverUrl];

      body += serverUrl + ' (hardwareId=' + serverInfo.hardwareId + '):\n';

      for (let config of (serverInfo.lsConfig.licenseConfigurations || [])) {
        body += '- ' + (config.numberOfLicenses ?? 'Infinite ') + 'x ' + config.name + ' (id=' + config.id + ')\n';
      }
      body += '- Requesting [XXX] new licenses for Arttest Basic\n';
      body += '- Requesting [XXX] new licenses for Arttest Enterprise\n';
      body += '- Requesting [XXX] new licenses for Arttest Server\n\n';
    }

    body += '\n\nBest Regards,\n' + (company ? company.name : '') + '\n\n\n\n';

    body += "******* Please don't remove the following information *******\n\n\n";
    body += "Server Info: \n" + JSON.stringify(serverInfos, null, 2);

    window.location.href = 'mailto:license@mindmotiv.com?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
  }, [company]);

  let downloadServerInfo = useCallback(async () => {
    setLoadingInfo(true);
    setError(undefined);
    let serverInfos: any;

    try {
      serverInfos = await getServerInfo();
    } catch (error) {
      setError(error.message);
      return;
    } finally {
      setLoadingInfo(false);
    }

    let blob = new Blob([JSON.stringify(serverInfos, null, 2)], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, "serverinfo.txt");
  }, []);

  return <>
    <Typography component='div' variant='body1' color='textSecondary'>
      <Collapse in={!!error}><Typography color="error">{error}{'\u00a0'}</Typography></Collapse>
      To request a new license, please contact <Link href='mailto:license@mindmotiv.com'>license@mindmotiv.com</Link> and tell us how the licenses should be distributed among your license servers.<br />
      Click the following button to open an email template to Mindmotiv.<br />
      In order to speed up the process please don't remove the technical information inside.
    </Typography>

    <ProgressButton className={classes.button} variant='outlined' showProgress={loadingTemplate} onClick={() => openEmailTemplate()}>Open E-Mail Template</ProgressButton>

    <Typography component='div' variant='body1' color='textSecondary' style={{ marginTop: '20px' }}>
      Alternatively you can download your server information manually and provide them to us in a way you like. Please keep in mind that we still need the information how many licenses you would like to purchase in this case.
    </Typography>

    <ProgressButton className={classes.button} variant='outlined' showProgress={loadingInfo} onClick={() => { downloadServerInfo(); }}>Download</ProgressButton>
  </>;
};

export default SettingsPageRequestLicense;
