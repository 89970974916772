import { useContext, useEffect } from 'react';
import { useGlobal } from 'reactn';

import { SessionContext } from '../App';
import { LICENSE_STATUS, UserRole } from '../config/Config';
import useForceUpdate from './ForceUpdateHook';
import useLoginDialogRedirect from './LoginDialogHook';

export interface ILicenseStatus {
  [serverUrl: string]: {
    status: number,
    response: {
      licenseValid: boolean,
      licenseCompatibilityStatus: boolean,
      errors: ILicenseError[];
    };
  };
}

export interface ILicenseError {
  affectedCompanyLicenses?: number[],
  affectedLicenseConfiguration?: string,
  message: string,
  details?: string;
}

export default function useLicenseStatus(): [ILicenseStatus | undefined, () => void] {
  const session = useContext(SessionContext);

  const [licenseStatus, setLicenseStatus] = useGlobal('licenseServerStatus');
  const [update, forceUpdate] = useForceUpdate();
  const loginDialogRedirect = useLoginDialogRedirect();

  const { isLoggedIn } = session;
  useEffect(() => {
    if (!isLoggedIn || !session.roles?.includes(UserRole.ADMIN)) {
      setLicenseStatus(undefined);
      return;
    }

    fetch(LICENSE_STATUS, { credentials: 'same-origin' })
      //.then(loginDialogRedirect)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      })
      .then(json => setLicenseStatus(json))
      .catch(err => setLicenseStatus(undefined));
  }, [session.roles, setLicenseStatus, forceUpdate, update, isLoggedIn, loginDialogRedirect]);

  return [licenseStatus, forceUpdate];
}