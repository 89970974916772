import { Divider, Grid, Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useContext, useEffect, useGlobal, useMemo, useState } from 'reactn';

import { SessionContext } from '../App';
import { LOGOUT, UserRole } from '../config/Config';
import MyAccountDialog from '../dialogs/account/MyAccountDialog';
import SettingsDialog from '../dialogs/settings/SettingsDialog';
import useForbiddenState from '../hooks/ForbiddenStateHook';
import { ISessionContext } from '../interfaces/ISessionContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      marginLeft: 240,
      [theme.breakpoints.up('sm')]: {
        width: 'calc(100% - 240px)'
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      }
    },
    userinfo: {
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    },
    title: {
      flexGrow: 1
    }
  })
);

export interface IAdminPanelAppBarProps {
  handleDrawerToggle: () => void;
  setLoggedIn: (loggedIn: boolean) => void;
}

const AdminPanelAppBar: React.FC<IAdminPanelAppBarProps> = (props) => {
  const classes = useStyles();

  const session = useContext<ISessionContext>(SessionContext);
  const [productName,] = useGlobal('productName');

  const isAdmin = useForbiddenState([UserRole.ADMIN], false, true);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [myAccountDialogOpen, setMyAccountDialogOpen] = useState(false);

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleMyAccount() {
    handleMenuClose();
    setMyAccountDialogOpen(true);
  }

  function handleSettings() {
    handleMenuClose();
    setSettingsDialogOpen(true);
  }

  function handleLogout() {
    fetch(LOGOUT, { method: 'POST', credentials: 'same-origin' }).then(response => {
      if (response.ok) {
        /* Reset session */
        session.isLoggedIn = false;
        session.roles = [];
        session.setRoles([]);

        /* Reload */
        window.location.reload();
      }
    });

    handleMenuClose();
  }

  const title = useMemo(() => `Mindmotiv - ${productName} Customer Panel ${session.selfHosted ? ' - Self-Hosting' : ''}`, [productName, session.selfHosted]);
  useEffect(() => { document.title = title; }, [title]);

  return (
    <div>
      <AppBar
        position='fixed'
        className={classes.appBar}
      >
        <Toolbar>
          <IconButton
            aria-label='Open drawer'
            edge='start'
            className={classes.menuButton}
            color='inherit'
            onClick={props.handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' className={classes.title} noWrap>{title}</Typography>
          <Grid container direction='column' alignItems='flex-end' className={classes.userinfo}>
            <Typography>
              {(session.firstname === "" && session.lastname === "") ? session.username : (session.firstname + " " + session.lastname)}<br />
            </Typography>
            <Typography variant='caption'>{session.company && session.company.name}</Typography>
          </Grid>
          <IconButton
            aria-label='Account of current user'
            aria-controls={'account-menu'}
            aria-haspopup='true'
            onClick={handleMenuOpen}
            color='inherit'
          >
            <AccountIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={'account-menu'}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMyAccount}>My Account</MenuItem>
        {isAdmin && <MenuItem onClick={handleSettings}>Settings</MenuItem>}
        <Divider />
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <MyAccountDialog open={myAccountDialogOpen} onClose={() => setMyAccountDialogOpen(false)} />
      <SettingsDialog open={settingsDialogOpen} onClose={() => setSettingsDialogOpen(false)} />
    </div>
  );
};

export default AdminPanelAppBar;
