import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import React from 'react';

import ProgressButton from '../components/ProgressButton';
import { COMPANIES, ERROR_COLOR } from '../config/Config';
import { IMergedCompany } from '../interfaces/ICompany';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    delete: {
      color: ERROR_COLOR,
      borderColor: ERROR_COLOR,
      '&:hover': {
        borderColor: ERROR_COLOR
      }
    },
    hidden: {
      display: 'none'
    }
  })
);

interface IRemoveCompanyProps extends DialogProps {
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'done'): void;
  }['bivarianceHack'],
  company: IMergedCompany;
}

const RemoveCompanyDialog: React.FC<IRemoveCompanyProps> = (props) => {
  let { ...dialogProps } = props;
  const classes = useStyles();

  const [companyName, setCompanyName] = React.useState('');
  const [error, setError] = React.useState(false);
  const [deleteRunning, setDeleteRunning] = React.useState(false);

  const companyNameCorrectlyTyped = companyName.trim() === props.company.name.trim();

  function handleDelete() {
    /* Form validation */
    if (!companyNameCorrectlyTyped) {
      return;
    }

    setError(false);

    /* Begin circular progress */
    setDeleteRunning(true);

    fetch(COMPANIES + '?companyIds=' + encodeURIComponent(JSON.stringify(props.company.idMapping)), {
      method: 'DELETE',
      credentials: 'same-origin',
    }).then(response => {
      if (!response.ok) {
        setError(true);
      } else {
        props.onClose?.({}, 'done');
      }
    }).finally(() => setDeleteRunning(false));
  }

  return (
    <Dialog {...dialogProps} fullWidth>
      <DialogTitle>Remove {props.company.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          WARNING! You are about to remove a company!<br />
          To confirm, type in the full company name below:
        </DialogContentText>
        {error && <Typography color='error'>Error Deleting Company</Typography>}
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Company Name'
          type='name'
          fullWidth
          onChange={e => setCompanyName(e.target.value)}
        // error={!companyNameCorrectlyTyped}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose?.({}, 'escapeKeyDown')} color='primary' disabled={deleteRunning}>Cancel</Button>
        <ProgressButton showProgress={deleteRunning} disabled={!companyNameCorrectlyTyped} variant='outlined' onClick={handleDelete} color='primary' className={classes.delete}>Delete</ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveCompanyDialog;