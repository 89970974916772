import { Collapse, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';

import { SessionContext } from '../../App';
import { LICENSE_CONFIGURATIONS, UserRole } from '../../config/Config';
import useForbiddenState from '../../hooks/ForbiddenStateHook';
import useForceUpdate from '../../hooks/ForceUpdateHook';
import useLoginDialogRedirect from '../../hooks/LoginDialogHook';
import useRestrictedMode from '../../hooks/RestrictedModeHook';
import ILicenseConfiguration from '../../interfaces/ILicenseConfiguration';
import { ISessionContext } from '../../interfaces/ISessionContext';
import { Utils } from '../../utils/Utils';
import MyAccountPageChangePassword from './MyAccountPageChangePassword';
import MyAccountPageChangeProfile from './MyAccountPageChangeProfile';
import MyAccountPageHistory from './MyAccountPageHistory';
import MyAccountPageLicense from './MyAccountPageLicense';

const useStyles = makeStyles(theme => createStyles({
  tabsWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '450px',
    padding: 0
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '200px'
  },
  tabContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  tabPanel: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    padding: theme.spacing(2)
  },
  hidden: {
    display: 'none'
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const classes = useStyles();

  const { children, value, index } = props;

  return (
    <Grid container direction='column' justify='flex-start' alignItems='flex-start' className={clsx(classes.tabPanel, value !== index && classes.hidden)} >
      {children}
    </Grid>
  );
}

const MyAccountDialog: React.FC<DialogProps> = (props) => {
  const session = useContext<ISessionContext>(SessionContext);
  const classes = useStyles();

  const restrictedMode = useRestrictedMode();

  const isAdmin = useForbiddenState([UserRole.ADMIN, UserRole.COMPANY_ADMIN], false, true);
  const [error, setError] = useState();

  const [selectedTab, setSelectedTab] = useState(restrictedMode && isAdmin ? 2 : (restrictedMode ? -1 : 0));

  /* Custom Hooks */
  const [update] = useForceUpdate();
  const loginDialogRedirect = useLoginDialogRedirect();

  /* State */
  const [currentLicenseConfiguration, setCurrentLicenseConfiguration] = useState<ILicenseConfiguration>();
  const [licenseConfigurations, setLicenseConfigurations] = useState<ILicenseConfiguration[]>();

  useEffect(() => {
    if (props.open) {
      setSelectedTab(restrictedMode && isAdmin ? 2 : (restrictedMode ? -1 : 0));
    }
  }, [props.open, restrictedMode, isAdmin]);

  useEffect(() => {
    if (props.open) {
      fetch(LICENSE_CONFIGURATIONS, {
        'method': 'GET',
        'credentials': 'same-origin'
      })
        .then(loginDialogRedirect)
        .then(async response => {
          if (!response.ok) {
            throw Error('Error retrieving company data. ' + await (Utils.messageFromResponse(response)));
          } else {
            return response.json();
          }
        }).then(data => {
          let configs = data as ILicenseConfiguration[];
          /* Set all available configurations */
          setLicenseConfigurations(configs);

          /* Identify the current assigned configuration */
          setCurrentLicenseConfiguration(configs.filter(conf => conf.id === session.companyLicense?.licenseConfiguration?.id)[0]);
        })
        .catch(error => setError(error.message));
    }
  }, [props.open, session.roles, session.isLoggedIn, session.companyLicense, update, loginDialogRedirect]);

  return (
    <Dialog {...props} fullWidth maxWidth='sm'>
      <DialogTitle>My Account</DialogTitle>
      <DialogContent className={classes.tabsWrapper}>
        <Collapse in={!!error} style={{ 'marginBottom': '10px' }}>
          <Typography variant='body1' color='error'>{error}{'\u00a0'}</Typography>
        </Collapse>
        <Tabs
          orientation="vertical"
          value={selectedTab}
          onChange={(e, tab) => setSelectedTab(tab)}
          className={classes.tabs}
        >
          <Tab style={{ display: 'none' }} label="Restricted" value={-1}></Tab>
          <Tab label="Profile" value={0} disabled={restrictedMode} />
          <Tab label="Password" value={1} disabled={restrictedMode} />
          <Tab label="License" value={2} disabled={restrictedMode} />
          {isAdmin && <Tab label="Order History" value={3} disabled={restrictedMode} />}
        </Tabs>
        <div className={classes.tabContent}>
          <TabPanel value={selectedTab} index={-1}><Typography color="error">Connection to one or more servers is unavailable.</Typography></TabPanel>
          <TabPanel value={selectedTab} index={0}><MyAccountPageChangeProfile /></TabPanel>
          <TabPanel value={selectedTab} index={1}><MyAccountPageChangePassword /></TabPanel>
          {currentLicenseConfiguration && <TabPanel value={selectedTab} index={2}><MyAccountPageLicense currentConfiguration={currentLicenseConfiguration} /></TabPanel>}
          {licenseConfigurations && isAdmin && <TabPanel value={selectedTab} index={3}><MyAccountPageHistory licenseConfigurations={licenseConfigurations} /></TabPanel>}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MyAccountDialog;